/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable prettier/prettier */
import { useRequest } from 'ahooks';
import { Col, Row } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import SkeletonChats from 'components/skeleton/SkeletonChats';
import useSelectConversations from 'hooks/useSelectConversations';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiDoctorConversationsManager from 'services/api/ApiDoctorConversationsManager';
import { ConversationDTO } from 'services/api/models/ConversationDTO';
import { selectWebsocketConnected } from 'store/conversations/selectors';
import { ConversationMetadata } from 'store/conversations/types';
import ChatsList from '../components/chats/ChatsList';
import ConversationTopBar from '../components/chats/ConversationTopBar';

const useStyles = createUseStyles({
  container: {
    height: '100%',
  },
  chatsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRightColor: 'rgba(0, 0, 0, 0.1)',
    borderRightStyle: 'solid',
    borderRightWidth: '0.555556px',
  },
});

export default function PatientConversationsHistory() {
  // Hooks
  const styles = useStyles();
  const [api, context] = useNotification();
  const { t } = useTranslation();
  const isWebsocketConnected = useSelector(selectWebsocketConnected);
  const { conversationMetadata } = useSelectConversations();
  const navigate = useNavigate();

  // not putting this in the store for now as it's meant to be looked up temporarily
  // (note: I'm not a react pro, maybe this isn't a good idea!)
  const [history, setHistory] = useState<ConversationDTO[]>([]);

  const { run, refresh, loading } = useRequest(
    () => {
      return ApiDoctorConversationsManager.getPatientConversationHistory(
        (conversationMetadata as ConversationMetadata).conversation.patient.id,
      );
    },
    {
      manual: true,
      onSuccess: result => {
        setHistory(result);
      },
      onError: () => {
        api.error({
          message: t('chats.list.errors.fetch'),
        });
      },
    },
  );

  useEffect(() => {
    if (isWebsocketConnected) {
      // reload the history on websocket connection
      refresh();
    }
  }, [refresh, isWebsocketConnected]);

  useEffect(() => {
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row className={styles.container}>
      {context}
      <Col className={styles.chatsContainer} xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 6 }}>
        <ConversationTopBar
          showBackButton
          avatar={null}
          name={t('chats.subtitle.history')}
          caregiverId={undefined}
          onBackButtonClick={() => navigate(-1)}
        />
        {loading ? (
          <SkeletonChats numberOfItems={3} />
        ) : (
          <ChatsList conversations={history} title={null} readOnlyConvs />
        )}
      </Col>
    </Row>
  );
}
