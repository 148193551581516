/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-floating-promises */
import * as amplitude from '@amplitude/analytics-browser';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { useRequest } from 'ahooks';
import { Layout } from 'antd';
import PageLoader from 'components/loader/PageLoader';
import useAuthGuard from 'hooks/useAuthGuard';
import ActionTypes from 'notifications';
import posthog from 'posthog-js';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import WebSocketService, {
  WebSocketDataChatMessage,
  WebSocketDataConversationAssigned,
  WebSocketDataConversationRequestToGroup,
  WebSocketDataConversationStatusUpdated,
  WebSocketDataReadNotification,
} from 'services/WebSocketService';
import ApiUsersManager from 'services/api/ApiUsersManager';
import { conversationActions } from 'store/conversations';
import { selectCurrentUserId } from 'store/users/selectors';
import BottomNavBar from '../../components/navigation/BottomNavBar';
import AppColors from '../../config/AppColors';
import { routes } from '../../services/RouteService';

const { Content } = Layout;

export default function Shell() {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { canCallApi, error, accessToken } = useAuthGuard();

  // Selector
  const currentUserId = useSelector(selectCurrentUserId);

  const appStateResumed = useRef<boolean>(true);
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  App.addListener('appStateChange', ({ isActive }) => {
    appStateResumed.current = isActive;
  });
  if (Capacitor.isPluginAvailable('Keyboard')) {
    Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardVisible(true);
    });
    Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardVisible(false);
    });
  }

  useEffect(() => {
    if (error) {
      navigate(routes.unauthorized.route);
    }

    if (!canCallApi) {
      navigate(routes.login.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, canCallApi]);

  const { run: registerFCMToken } = useRequest(
    (fcmToken: string) => {
      return ApiUsersManager.registerFCMToken({ token: fcmToken });
    },
    {
      manual: true,
      onSuccess: () => {},
      onError: () => {
        // TODO better error?
      },
    },
  );

  useEffect(() => {
    if (canCallApi && accessToken && currentUserId) {
      if (Capacitor.isPluginAvailable('LocalNotifications')) {
        LocalNotifications.addListener('localNotificationActionPerformed', notificationAction => {
          if (notificationAction.notification.actionTypeId === ActionTypes.SYMPTOM_TRACKING_REMINDER) {
            amplitude.track('tracking.reminder_notification_clicked');
            posthog.capture('tracking.reminder_notification_clicked');
            navigate(routes.symptomTracking.route);
          }
        });
      }
      if (Capacitor.isPluginAvailable('PushNotifications')) {
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', (token: Token) => {
          registerFCMToken(token.value);
          PushNotifications.removeAllDeliveredNotifications();
        });

        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, canCallApi, currentUserId]);

  useEffect(() => {
    if (canCallApi && accessToken && currentUserId) {
      WebSocketService.getNewInstance({
        accessToken,
        onOpen: () => dispatch(conversationActions.setWebsocketConnectionStatus({ connected: true })),
        onClose: () => dispatch(conversationActions.setWebsocketConnectionStatus({ connected: false })),
        onMessage: data => {
          switch (data.type) {
            // A message sent in a conversation in which the current user is directly involved (== either the patient or the current_owner)
            case 'chat_message':
              // Update the current conversations and conversations waiting for redirection
              const payload = data.payload as WebSocketDataChatMessage;
              dispatch(
                conversationActions.addMessageToConversation({
                  conversationId: payload.conversation,
                  sender: payload.sender,
                  messageText: payload.message_text,
                  incrementUnreadMessages: true,
                }),
              );
              break;

            // a message has been read
            case 'conversation.read_notification':
              const payloadReadNotification = data.payload as WebSocketDataReadNotification;
              dispatch(
                conversationActions.updateConversationReadStatus({
                  conversationId: payloadReadNotification.conversation,
                  reader: payloadReadNotification.reader,
                  lastReadDatetime: payloadReadNotification.last_read_datetime,
                }),
              );
              break;

            // a conversation's status has been updated
            case 'conversation.status.updated':
              const payloadStatusUpdate = data.payload as WebSocketDataConversationStatusUpdated;
              dispatch(
                conversationActions.setConversationStatusValue({
                  conversationId: payloadStatusUpdate.id,
                  statusValue: payloadStatusUpdate.new_status,
                  currentUserId,
                }),
              );
              break;

            // A conversation has been requested to be transferred to a group
            case 'conversation.assignment.asked_group':
              // Update waiting for assignment conversations
              const payloadWaitingForAssignmentMessage = data.payload as WebSocketDataConversationRequestToGroup;
              if (payloadWaitingForAssignmentMessage.current_owner.id !== currentUserId) {
                dispatch(
                  conversationActions.insertConversationOrAddMessage({
                    webSocketPayload: payloadWaitingForAssignmentMessage,
                    conversationType: 'unassigned',
                  }),
                );
              } else {
                // TODO move conversation from my conversations to "waitingForReassignment"
                dispatch(conversationActions.removeConversation(payloadWaitingForAssignmentMessage.id));
                dispatch(
                  conversationActions.insertConversationOrAddMessage({
                    webSocketPayload: payloadWaitingForAssignmentMessage,
                    conversationType: 'waitingForReassignment',
                  }),
                );
              }
              break;

            // A message sent in a conversation in which the current_owner is null (otherwise, caregivers would have to refresh to receive new conversations initiated by patients
            case 'unassigned_message':
              // Update unassigned conversations
              const payloadUnassignedMessage = data.payload as WebSocketDataChatMessage;
              if (payloadUnassignedMessage.sender.id !== currentUserId) {
                dispatch(conversationActions.handleNewMessageFromUnassignedConversation(payloadUnassignedMessage));
              }
              break;

            // Caregiver has assigned a conversation to himself (to give the information to other caregivers who could also have assigned themselves
            // -> for them the conversation that was in /unassigned will disappear)
            case 'conversation_assigned':
              // Either remove the conversation from the unassigned conversations, or from the list of conversations awaiting redirection
              const payloadConversationAssigned = data.payload as WebSocketDataConversationAssigned;
              if (payloadConversationAssigned.new_owner !== currentUserId) {
                dispatch(conversationActions.removeConversation(payloadConversationAssigned.id));
              } else {
                dispatch(
                  conversationActions.setConversationStatusValue({
                    conversationId: payloadConversationAssigned.id,
                    statusValue: 'ASSIGNED_TO_CAREGIVER',
                    currentUserId,
                  }),
                );
              }
              break;

            default:
              break;
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, canCallApi, currentUserId, appStateResumed]);

  if (!canCallApi) {
    return <PageLoader />;
  }

  return (
    <Layout
      style={{
        height: 'calc(100dvh - env(safe-area-inset-top))',
        backgroundColor: AppColors.background,
      }}
    >
      <Content
        style={{
          padding: 0,
          margin: 0,
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <Outlet />
      </Content>

      {!keyboardVisible && <BottomNavBar />}
    </Layout>
  );
}
