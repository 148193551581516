/* eslint-disable @typescript-eslint/no-floating-promises */
import { useRequest } from 'ahooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiDoctorConversationsManager from 'services/api/ApiDoctorConversationsManager';
import ApiPatientConversationsManager from 'services/api/ApiPatientConversationsManager';
import { conversationActions } from 'store/conversations';
import { selectLoadedConversationsOnce, selectWebsocketConnected } from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';
import useIsSmallScreen from './useIsSmallScreen';
import useSelectConversations from './useSelectConversations';

export default function useLoadConversations() {
  const dispatch = useDispatch();
  const isWebsocketConnected = useSelector(selectWebsocketConnected);
  const loadedConversationsOnce = useSelector(selectLoadedConversationsOnce);
  const { conversationId } = useSelectConversations();
  const currentUser = useSelector(selectCurrentUser);
  const { isSmallScreen } = useIsSmallScreen();

  function markConversationAsRead(conversationIdToMark: string) {
    // Mark the messages as read
    dispatch(conversationActions.resetUnreadMessages({ conversationId: conversationIdToMark }));
    if (currentUser?.is_caregiver) {
      ApiDoctorConversationsManager.markConversationAsRead(conversationIdToMark);
    } else {
      ApiPatientConversationsManager.markConversationAsRead(conversationIdToMark);
    }
  }

  const { run, refresh } = useRequest(
    () => {
      if (currentUser?.is_caregiver) {
        return ApiDoctorConversationsManager.getDoctorConversationsList();
      }
      return ApiPatientConversationsManager.getPatientConversationsList();
    },
    {
      onBefore: () => {
        dispatch(conversationActions.setConversationLoadingStatus(loadedConversationsOnce ? 'REFRESHING' : 'LOADING'));
      },
      onSuccess: result => {
        dispatch(conversationActions.setConversationLoadingStatus('SUCCESS'));

        dispatch(conversationActions.setConversations({ conversations: result, currentUserId: currentUser!.id }));

        if (!isSmallScreen && result.length > 0 && !currentUser?.is_caregiver) {
          if (!conversationId) {
            const resultConversationId = result[0].id;
            markConversationAsRead(resultConversationId);
          }
        }
      },
      onError: () => {
        dispatch(conversationActions.setConversationLoadingStatus('ERROR'));
      },
    },
  );

  useEffect(() => {
    if (isWebsocketConnected) {
      // reload the history on websocket connection
      refresh();
    }
  }, [refresh, isWebsocketConnected]);

  return {
    run,
    refresh,
    markConversationAsRead,
  };
}
