/* eslint-disable no-nested-ternary */

import { useRequest } from 'ahooks';
import useNotification from 'antd/es/notification/useNotification';
import NotificationSuggester from 'components/cards/NotificationSuggester';
import MessageInput from 'components/inputs/MessageInput';
import SkeletonMessages from 'components/skeleton/SkeletonMessages';
import AppColors from 'config/AppColors';
import useCheckNotificationsPermissions from 'hooks/useCheckNotificationsPermissions';
import useLoadConversations from 'hooks/useLoadConversations';
import useSelectConversations from 'hooks/useSelectConversations';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiDoctorConversationsManager, {
  ApiDoctorConversationsPostMessageToDoctorConversationResult,
} from 'services/api/ApiDoctorConversationsManager';
import ApiPatientConversationsManager, {
  ApiPatientConversationsPostMessageToPatientConversationsResult,
} from 'services/api/ApiPatientConversationsManager';
import { conversationActions } from 'store/conversations';
import { selectWebsocketConnected } from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';
import defaultAvatarUrl from '../assets/experts-man-dark.png';
import ConversationButtons from '../components/chats/ConversationButtons';
import ConversationTopBar from '../components/chats/ConversationTopBar';
import MessagesList from '../components/chats/MessagesList';

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    backgroundColor: AppColors.backgroundGrey,
  },
  container: {
    height: '100%',
    overflowY: 'scroll',
    maxWidth: 600,
    width: '100vw',
  },
  bottomContainer: {
    maxWidth: 600,
    width: '100vw',
    backgroundColor: 'white',
  },
  noNotificationWarningContainer: {
    padding: 10,
    backgroundColor: AppColors.backgroundGrey,
    textDecoration: 'underline',
  },
});

export default function ConversationPanel() {
  // Hooks
  const dispatch = useDispatch();
  const [api, context] = useNotification();
  const { t } = useTranslation();
  const isWebsocketConnected = useSelector(selectWebsocketConnected);
  const navigate = useNavigate();
  const { doesUserHaveNotifications } = useCheckNotificationsPermissions();
  const styles = useStyles();
  const { markConversationAsRead } = useLoadConversations();

  // Selectors
  const currentUser = useSelector(selectCurrentUser);

  const { conversationId, conversationMetadata, conversationMessages } = useSelectConversations();

  // TODO this is very hard to read
  const isCurrentUserIsOwner =
    conversationMessages &&
    conversationMetadata?.conversation &&
    conversationMetadata?.conversation.current_owner &&
    conversationMetadata.conversation.current_owner.id === currentUser?.id;

  // Show the self assign button if:
  // - there is no owner yet
  // - or there is an owner, but this is not the current user/caregiver
  const displaySelfAssign =
    currentUser?.is_caregiver &&
    (!conversationMetadata?.conversation.current_owner ||
      !isCurrentUserIsOwner ||
      conversationMetadata.type === 'unassigned');

  const { run, loading } = useRequest(
    () => {
      if (currentUser?.is_caregiver) {
        return ApiDoctorConversationsManager.getDoctorConversationMessageList({ conversationId });
      }
      return ApiPatientConversationsManager.getPatientConversationsMessageList({ conversationId });
    },
    {
      manual: true,
      onSuccess: result => {
        dispatch(conversationActions.setConversationMessages(result));
        markConversationAsRead(conversationId);
      },
      onError: () => {
        api.error({
          message: t('chats.list.errors.fetch'),
        });
      },
    },
  );

  useEffect(() => {
    // On mount get conversation for a patient or a caregiver
    if (conversationId) {
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, isWebsocketConnected]);

  function onMessageSent(
    result:
      | ApiPatientConversationsPostMessageToPatientConversationsResult
      | ApiDoctorConversationsPostMessageToDoctorConversationResult,
  ) {
    if (currentUser) {
      dispatch(
        conversationActions.addMessageToConversation({
          conversationId,
          messageText: result.messageText,
          sender: currentUser,
        }),
      );
    }
  }

  function onBackButtonClick() {
    navigate(-1);
  }

  // Add an extra message (saying the request will be handled) if
  // the user is not a caregiver
  // and the conversation has not been answered by a caregiver yet
  const extraMessage =
    !currentUser?.is_caregiver &&
    conversationMessages &&
    conversationMessages.length > 0 &&
    !conversationMessages.find(ms => ms.sender.is_caregiver)
      ? {
          message_text: t('chats.noCaregiver.extraConversationMessage.forPatient'),
          sender: {
            id: t('chats.new.introMessageSenderId'),
            display_name: t('chats.new.introMessageSenderName'),
            first_name: t('chats.new.introMessageSenderName'),
            is_caregiver: false,
            photo_url: defaultAvatarUrl,
            number_of_conversations_as_patient: 0,
          },
          sent_at: new Date().toISOString(),
        }
      : [];

  const messagesForConversations = conversationMessages || [];

  const firstMessage = messagesForConversations.length > 0 ? [messagesForConversations[0]] : [];
  const restOfMessages = messagesForConversations.length > 1 ? messagesForConversations.slice(1) : [];
  const messages = firstMessage.concat(extraMessage).concat(restOfMessages);

  // If the current user is a caregiver, display the patient avatar or a failover avatar. Otherwise, display the caregiver's avatar
  const recipientAvatar = currentUser?.is_caregiver
    ? conversationMetadata?.conversation.patient.photo_url ||
      conversationMetadata?.conversation.patient.display_name[0] ||
      t('chats.noCaregiver.avatar')
    : conversationMetadata?.conversation.current_owner?.photo_url ||
      conversationMetadata?.conversation.current_owner?.display_name[0] ||
      t('chats.noCaregiver.avatar');

  // If the current user is a caregiver, display the patient name or a failover name. Otherwise, display the caregiver's name
  const recipientName = currentUser?.is_caregiver
    ? conversationMetadata?.conversation.patient.display_name || t('chats.noCaregiver.name.forCaregiver')
    : conversationMetadata?.conversation.current_owner?.display_name || t('chats.noCaregiver.name.forPatient');

  const caregiverId = currentUser?.is_caregiver ? undefined : conversationMetadata?.conversation.current_owner?.id;

  const patientId = currentUser?.is_caregiver ? conversationMetadata?.conversation.patient.id : undefined;

  // Loading the conversation for the very first time
  const firstTimeLoading = loading && conversationMessages.length === 0;

  // mark conversation as read when unmounting it to account for messages that are received while the conversation is open
  useEffect(() => () => markConversationAsRead(conversationId), []);

  return (
    <div className={styles.pageContainer}>
      <ConversationTopBar
        showBackButton
        avatar={conversationId ? recipientAvatar : null}
        name={conversationId ? recipientName : t('chats.list.noChatsTitle')}
        caregiverId={caregiverId}
        patientId={patientId}
        onBackButtonClick={() => onBackButtonClick()}
        showSummaryButton
        showHistoryButton
        showActionsButton={!displaySelfAssign}
      />
      <div className={styles.container}>
        {context}
        {firstTimeLoading ? <SkeletonMessages numberOfTupleMessages={3} /> : <MessagesList messages={messages} />}
      </div>
      <div className={styles.bottomContainer}>
        {displaySelfAssign && <ConversationButtons />}

        {!doesUserHaveNotifications && (
          <div className={styles.noNotificationWarningContainer}>
            <NotificationSuggester text={t('chats.noNotificationsCTA')} />
          </div>
        )}
        {!displaySelfAssign && (
          <MessageInput disabled={!conversationId} onMessageSent={result => onMessageSent(result)} />
        )}
      </div>
    </div>
  );
}
