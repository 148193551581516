import * as amplitude from '@amplitude/analytics-browser';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import GGButton from 'components/buttons/GGButton';
import MessageInput from 'components/inputs/MessageInput';
import CaregiverAvatarList from 'components/lists/CaregiverAvatarList';
import AppColors from 'config/AppColors';
import useIsKeyboardVisible from 'hooks/useIsKeyboardVisible';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/users/selectors';
import ScrollUtil from 'utils/ScrollUtil';

const useStyles = createUseStyles({
  explainerContainer: {
    width: '100%',
    padding: 16,
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,

    '& h2': {
      textAlign: 'center',
      padding: '0px 16px',
    },
  },
  badPathContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  badPathImage: {
    height: 180,

    '& img': {
      height: '100%',
    },
  },
  badPathText: {
    marginLeft: 16,
    height: 180,
    flexGrow: 1,
    maxWidth: 250,
    position: 'relative',
    '& p': {
      position: 'absolute',
      margin: 0,
      // Center the text vertically
      transform: 'translateY(-50%)',
    },
  },
  badPathText1: {
    top: '7%',
  },
  badPathText2: {
    top: '50%',
  },
  badPathText3: {
    top: '93%',
  },
  badPathBold: {
    fontWeight: 'bold',
    color: AppColors.colorMap.veryBad,
  },

  missionContainer: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h2': {
      marginTop: 8,
    },
  },

  goodPathContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 128, // we need to take into account the height of the last absolute-positioned paragraph
  },
  goodPathImage: {
    height: 542,

    '& img': {
      height: '100%',
    },
  },
  goodPathText: {
    marginLeft: 16,
    height: 542,
    flexGrow: 1,
    maxWidth: 250,
    position: 'relative',
    '& div': {
      position: 'absolute',
      margin: 0,
      // Center the text vertically
    },
  },
  goodPathText1: {
    top: '0%',
  },
  goodPathText2: {
    top: '27%',
  },
  goodPathText3: {
    top: '59%',
  },
  goodPathText4: {
    top: '90%',
  },
  goodPathMainText: {
    fontWeight: 'bold',
  },
  goodPathSecondaryText: {
    fontWeight: 'normal',
    color: AppColors.text.discrete,
  },

  noChatbotHereContainer: {
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 16,
    gap: 16,
    marginBottom: 16,
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.05)',
    width: '100%',

    '& h3': {
      margin: 0,
      marginBottom: 8,
    },
    '& p': {
      margin: 0,
    },
  },
  caregiversListContainer: {
    marginTop: 12,
  },

  messageInputContainer: {
    width: '100%',
  },

  inCaseOfEmergency: {
    marginTop: 32,
    textAlign: 'center',
    color: AppColors.colorMap.veryBad,
    '& svg': {
      marginRight: 8,
    },
  },
  firstMessageModal: {
    '& li': {
      marginBottom: 8,
    },

    '& q': {
      fontStyle: 'italic',
      borderLeft: `4px solid ${AppColors.main}`,
      paddingLeft: 8,
      display: 'inline-block',
      lineHeight: '1.8em',
    },
  },
});

export type ExplainerProps = {
  scrollableElement: React.RefObject<HTMLDivElement>;
  isOnTransientPage?: boolean;
  disableNewConversationButton?: boolean;
};

export default function Explainer({
  scrollableElement,
  isOnTransientPage = false,
  disableNewConversationButton = false,
}: ExplainerProps) {
  // Hooks
  const { t } = useTranslation();
  const styles = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const { isKeyboardVisible } = useIsKeyboardVisible();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const exampleMessages = t<string, { returnObjects: true }, string[]>('chats.new.exampleMessages', {
    returnObjects: true,
  });

  useEffect(() => {
    if (isKeyboardVisible) {
      ScrollUtil.scrollToBottom(scrollableElement.current);
    }
  }, [isKeyboardVisible]);

  return (
    <div className={styles.explainerContainer}>
      <h2>{t('chats.explainer.youWantMedicalAdviceBut')}</h2>
      <div className={styles.badPathContainer}>
        <div className={styles.badPathImage}>
          <img src={require('assets/messaging-bad-path.png')} />
        </div>
        <div className={styles.badPathText}>
          <p className={styles.badPathText1}>
            <Trans
              i18nKey="chats.explainer.gynecologistIsNotAvailable"
              components={{ bold: <span className={styles.badPathBold} /> }}
            />
          </p>
          <p className={styles.badPathText2}>
            <Trans
              i18nKey="chats.explainer.youAreNotSureItsWorthIt"
              components={{ bold: <span className={styles.badPathBold} /> }}
            />
          </p>
          <p className={styles.badPathText3}>
            <Trans
              i18nKey="chats.explainer.youDontWantToBeIgnored"
              components={{ bold: <span className={styles.badPathBold} /> }}
            />
          </p>
        </div>
      </div>

      <div className={styles.missionContainer}>
        <img src={require('assets/logo.png')} width={80} />
        <h2>{t('chats.explainer.gyngerTransformsYourAccess')}</h2>
      </div>

      <div className={styles.goodPathContainer}>
        <div className={styles.goodPathImage}>
          <img src={require('assets/messaging-good-path.png')} />
        </div>
        <div className={styles.goodPathText}>
          <div className={styles.goodPathText1}>
            <p className={styles.goodPathMainText}>{t('chats.explainer.goodPathMainText1')}</p>
            <p className={styles.goodPathSecondaryText}>{t('chats.explainer.goodPathSecondaryText1')}</p>
          </div>
          <div className={styles.goodPathText2}>
            <p className={styles.goodPathMainText}>{t('chats.explainer.goodPathMainText2')}</p>
            <p className={styles.goodPathSecondaryText}>{t('chats.explainer.goodPathSecondaryText2')}</p>
          </div>
          <div className={styles.goodPathText3}>
            <p className={styles.goodPathMainText}>{t('chats.explainer.goodPathMainText3')}</p>
            <p className={styles.goodPathSecondaryText}>{t('chats.explainer.goodPathSecondaryText3')}</p>
          </div>
          <div className={styles.goodPathText4}>
            <p className={styles.goodPathMainText}>{t('chats.explainer.goodPathMainText4')}</p>
            <p className={styles.goodPathSecondaryText}>{t('chats.explainer.goodPathSecondaryText4')}</p>
          </div>
        </div>
      </div>

      <div className={styles.noChatbotHereContainer}>
        <h3>{t('chats.explainer.noChatbotHereTitle')}</h3>
        <p>{t('chats.explainer.noChatbotHereText')}</p>
        <div className={styles.caregiversListContainer}>
          <CaregiverAvatarList direction="horizontal" />
        </div>
      </div>

      {!disableNewConversationButton && (
        <div className={styles.messageInputContainer}>
          <h2>{t('chats.explainer.howCanWeHelpYou', { firstName: currentUser?.first_name })}</h2>
          <MessageInput animatePlaceholder minRows={3} isOnTransientPage={isOnTransientPage} />
          <GGButton
            block
            type="link"
            onClick={() => {
              setIsModalOpen(true);
              amplitude.track('chats.messageExplainerModalOpened');
              posthog.capture('chats.messageExplainerModalOpened');
            }}
          >
            {t('chats.explainer.noInspiration')}
          </GGButton>
        </div>
      )}

      <h4 className={styles.inCaseOfEmergency}>
        <InfoCircleOutlined />
        {t('chats.explainer.inCaseOfEmergency')}
      </h4>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => setIsModalOpen(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        className={styles.firstMessageModal}
      >
        <h3>Que dire dans mon premier message ?</h3>
        <Trans
          i18nKey="chats.explainer.firstMessageModal"
          components={{ strong: <strong />, p: <p />, ul: <ul />, li: <li />, quote: <q /> }}
        />
        <ul>
          {exampleMessages.map(exampleMessage => (
            <li key={exampleMessage}>{exampleMessage}</li>
          ))}
        </ul>
      </Modal>
    </div>
  );
}
