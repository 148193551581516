import { Keyboard } from '@capacitor/keyboard';
import { useRequest } from 'ahooks';
import { Input, Row, Skeleton, Tag } from 'antd';
import ColorHash from 'color-hash-ts';
import AppColors from 'config/AppColors';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import ApiContentManager from 'services/api/ApiContentManager';
import { contentActions } from 'store/content';
import { selectSuggestedPosts } from 'store/content/selectors';
import onArticleSelected from 'utils/ContentUtils';
import Platform from 'utils/Platform';
import PostsList from './PostsList';

const colorHash = new ColorHash({
  lightness: [0.9, 0.95],
  saturation: [0.5, 0.5],
});
const colorHashSelected = new ColorHash({
  lightness: [0.3, 0.4],
  saturation: [0.5, 0.5],
});

const { Search } = Input;

const useStyles = createUseStyles({
  container: {
    paddingBottom: 16,
  },
  list: {
    width: '100%',
  },
  card: {
    overflow: 'hidden',
  },
  title: {
    '& .ant-card-meta-title': {
      whiteSpace: 'pre-wrap',
    },
  },
  search: {
    marginTop: 8,
    marginBottom: 12,
    '& .ant-input-clear-icon svg': {
      height: 17,
      width: 17,
      marginTop: 1,
    },
  },
  recentlyReadTitle: {
    marginBottom: 12,

    color: AppColors.text.veryLightGray,
  },
  recentlyReadPostsContainer: {
    display: 'flex',
    paddingBottom: 8,
    width: '100%',
    overflowX: 'scroll',
    flexFlow: 'row',
    gap: 16,
    marginBottom: 16,
    maskImage: 'linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0))',
    WebkitMaskImage: 'linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0))',
    paddingRight: 32,
    scrollbarWidth: 'none',
  },
  recentlyReadPost: {
    width: 120,
    height: 105,
    flexShrink: 0,
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 12,
    overflow: 'hidden',
    transform: 'translateX(-100%)',
    opacity: 0,
    boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.05)',
    animation: '$slideIn 0.5s cubic-bezier(0.16, 1, 0.3, 1) forwards',
    '& h4': {
      margin: 0,
      height: '102%',
      fontWeight: 'normal',
      maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 60%, rgba(0,0,0,0))',
      WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 60%, rgba(0,0,0,0))',
    },
  },
  '@keyframes slideIn': {
    from: {
      transform: 'translateX(-100%)',
      opacity: 0,
    },
    to: {
      transform: 'translateX(0)',
      opacity: 1,
    },
  },
  tagsContainer: {
    display: 'flex',
    flexFlow: 'row',
    gap: 12,
    marginBottom: 16,
    width: '100%',
    overflowX: 'scroll',
    maskImage: 'linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0))',
    WebkitMaskImage: 'linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0))',
    scrollbarWidth: 'none',
    paddingRight: 48,
  },
  tag: {
    padding: '8px 12px',
    margin: 0,
    border: 'none',
  },
  postsSkeleton: {
    width: '100%',
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
});

export default function ArticlesList() {
  const styles = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const suggestedPosts = useSelector(selectSuggestedPosts);

  const { data: tags, loading: isLoadingTags } = useRequest(() => ApiContentManager.getTags(), {
    cacheKey: 'content.tags',
    cacheTime: 1000 * 60 * 10, // 10 minutes
  });

  const { data: recentlyReadPosts, loading: isLoadingRecentlyReadPosts } = useRequest(
    () => ApiContentManager.getRecentlyReadPosts(),
    {
      cacheKey: 'content.recentlyReadPosts',
      cacheTime: 1000 * 60 * 10, // 10 minutes
      debounceWait: 500,
      debounceLeading: true,
    },
  );

  const {
    run: searchPosts,
    data: searchResults,
    loading: isLoadingSearchResults,
  } = useRequest(
    () => ApiContentManager.searchPosts(searchParams.get('search') || undefined, searchParams.get('tags') || undefined),
    {
      manual: true,
      cacheKey: `content.searchResults:${searchParams.get('search') || ''}:${searchParams.get('tags') || ''}`,
      cacheTime: 1000 * 60 * 10, // 10 minutes
      onSuccess: data => {
        if (!searchParams.get('search') && !searchParams.get('tags') && !!suggestedPosts) {
          // storing suggested posts in the store to avoid fetching them again
          // as they are shuffled on the backend. This way, we can keep
          // the same order for a given session of a user.
          // This makes it feel more natural to the user, and also helps
          // when coming back to a list of articles from a specific article
          // as it keeps the same position in the list of suggested articles.
          dispatch(contentActions.setSuggestedPosts(data));
        }
        if (searchParams.get('search') || searchParams.get('tags')) {
          const searchedTag = searchParams.get('tags')?.length > 0 ? searchParams.get('tags') : undefined;
          const usedTag = tags?.find(tag => tag.id.toString() === searchedTag);
          posthog.capture('info.search_performed', {
            search_query: searchParams.get('search')?.length > 0 ? searchParams.get('search') : undefined,
            search_tag_id: usedTag?.id.toString(),
            search_tag_slug: usedTag?.slug,
            tag_rank: searchParams.get('tags') ? tags?.findIndex(tag => tag.id === usedTag?.id) : undefined,
            search_results_count: data?.length,
          });
        }
      },
    },
  );

  const displayedPosts = searchParams.get('search') || searchParams.get('tags') ? searchResults : suggestedPosts;

  const triggerSearch = (search: string) => {
    setSearchParams(prev => {
      const params = createSearchParams(prev);
      params.set('search', search);
      return params;
    });
    if (Platform.isNative()) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Keyboard.hide();
    }
  };

  const isTagSelected = (tag: string) => {
    return searchParams.get('tags') === tag;
  };

  const toggleTag = (tag: string) => {
    if (isTagSelected(tag)) {
      setSearchParams(prev => {
        const params = createSearchParams(prev);
        params.delete('tags');
        return params;
      });
    } else {
      setSearchParams(prev => {
        const params = createSearchParams(prev);
        params.set('tags', tag);
        return params;
      });
    }
  };

  useEffect(() => {
    if (searchParams.get('search') || searchParams.get('tags') || !suggestedPosts) {
      searchPosts();
    }
  }, [searchParams, suggestedPosts, searchPosts]);

  useEffect(() => {
    // scroll tag into view
    if (searchParams.get('tags')) {
      const tagElement = document.querySelector(`#tag-${searchParams.get('tags')}`);
      if (tagElement) {
        tagElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }
  }, [searchParams, tags, searchPosts]);

  return (
    <div className={styles.container}>
      {isLoadingRecentlyReadPosts && recentlyReadPosts === undefined ? (
        <Skeleton active />
      ) : (
        recentlyReadPosts?.length > 0 && (
          <>
            <h3 className={styles.recentlyReadTitle}>{t('infoPage.recentlyRead')}</h3>
            <Row className={styles.recentlyReadPostsContainer}>
              {recentlyReadPosts?.map((post, index) => (
                <div
                  className={styles.recentlyReadPost}
                  key={post.id}
                  data-testid={`recentpost-${index}`}
                  onClick={() => onArticleSelected(post, index, navigate, { opened_on_page: 'info-recents' })}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onArticleSelected(post, index, navigate, { opened_on_page: 'info-recents' });
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <h4>{post.title}</h4>
                </div>
              ))}
            </Row>
          </>
        )
      )}
      <Row />
      <Row>
        <Search
          className={styles.search}
          variant="filled"
          placeholder={t('infoPage.search.placeholder')}
          onSearch={triggerSearch}
          defaultValue={searchParams.get('search') || ''}
          size="large"
          allowClear
          autoCorrect="on"
          enterKeyHint="search"
          type="search"
        />
      </Row>
      <div className={styles.tagsContainer}>
        {isLoadingTags ? (
          <Skeleton.Input block active style={{ height: 36 }} />
        ) : (
          tags?.map((tag, index) => (
            <Tag
              key={tag.id}
              id={`tag-${tag.id}`}
              className={styles.tag}
              data-testid={`tag-${index}`}
              style={{
                // make tag darker if selected
                backgroundColor: isTagSelected(tag.id.toString())
                  ? colorHashSelected.hex(tag.id.toString())
                  : colorHash.hex(tag.id.toString()),
                ...(isTagSelected(tag.id.toString()) && {
                  color: 'white',
                }),
              }}
              onClick={() => toggleTag(tag.id.toString())}
            >
              #{tag.display_name}
            </Tag>
          ))
        )}
      </div>
      <Row>
        {isLoadingSearchResults ? (
          <div className={styles.postsSkeleton}>
            <Skeleton.Input block active style={{ height: 64 }} />
            <Skeleton.Input block active style={{ height: 64 }} />
            <Skeleton.Input block active style={{ height: 64 }} />
          </div>
        ) : (
          <PostsList
            posts={displayedPosts ?? []}
            posthogOnArticleOpenExtras={{
              opened_on_page: 'info',
              ...(searchParams.get('search')?.length > 0 && {
                found_using_search: searchParams.get('search') as string,
              }),
              ...(searchParams.get('tags')?.length > 0 && {
                found_using_tag_id: searchParams.get('tags') as string,
                found_using_tag_slug: tags?.find(tag => tag.id.toString() === searchParams.get('tags'))?.slug,
              }),
            }}
          />
        )}
      </Row>
    </div>
  );
}
