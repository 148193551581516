/* eslint-disable global-require */
import useNotification from 'antd/es/notification/useNotification';
import GGButton from 'components/buttons/GGButton';
import useAuthGuard from 'hooks/useAuthGuard';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';
import AuthContainer from '../components/containers/AuthContainer';

export default function RegisterAskIfCode() {
  // Hooks
  const { error } = useAuthGuard();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [api, context] = useNotification();

  useEffect(() => {
    if (error) {
      api.error({
        message: t('loginRegister.errors.login'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <AuthContainer
      title={t('loginRegister.title.register')}
      description={t('loginRegister.description.registerAskIfCode')}
      mode="login"
      bottomLinkTo="login"
    >
      {context}

      <GGButton
        block
        type="primary"
        htmlType="submit"
        disabled={false}
        loading={false}
        onClick={() => {
          navigate(routes.register.route);
        }}
      >
        {t('loginRegister.actions.iHaveACode')}
      </GGButton>
      <GGButton
        block
        type="default"
        color="discrete"
        htmlType="submit"
        disabled={false}
        loading={false}
        style={{ marginTop: '15px' }}
        onClick={() => {
          navigate(routes.registerNoCode.route);
        }}
      >
        {t('loginRegister.actions.iDontHaveACode')}
      </GGButton>
    </AuthContainer>
  );
}
