/* eslint-disable @typescript-eslint/no-floating-promises */
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store/configureStore';
import {
  selectConversationInputText,
  selectConversationMessages,
  selectConversationMetadata,
  selectConversationSummary,
  selectConversationSummaryDraft,
} from 'store/conversations/selectors';

export default function useSelectConversations() {
  const conversationId = useParams().conversationId as string;
  const historicConversationId = useParams().historicConversation as string;
  const conversationMetadata = useSelector(
    (state: RootState) => selectConversationMetadata(state, conversationId),
    shallowEqual,
  );
  const conversationSummary = useSelector(
    (state: RootState) => selectConversationSummary(state, conversationId),
    shallowEqual,
  );
  const conversationSummaryDraft = useSelector(
    (state: RootState) => selectConversationSummaryDraft(state, conversationId),
    shallowEqual,
  );
  const conversationMessages = useSelector(
    (state: RootState) => selectConversationMessages(state, conversationId),
    shallowEqual,
  );
  const inputText = useSelector((state: RootState) => selectConversationInputText(state, conversationId));

  const historicConversationSummary = useSelector(
    (state: RootState) => selectConversationSummary(state, historicConversationId),
    shallowEqual,
  );

  const historicConversationSummaryDraft = useSelector(
    (state: RootState) => selectConversationSummaryDraft(state, historicConversationId),
    shallowEqual,
  );

  return {
    conversationId,
    conversationMetadata,
    conversationSummary,
    conversationSummaryDraft,
    conversationMessages,
    inputText,
    historicConversationSummary,
    historicConversationSummaryDraft,
  };
}
