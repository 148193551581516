import * as amplitude from '@amplitude/analytics-browser';
import ArticlePage from 'pages/Article';
import CaregiversDirectoryPage from 'pages/CaregiversDirectory';
import ConversationPage from 'pages/ConversationPage';
import ConversationSummary from 'pages/ConversationSummary';
import FaqPage from 'pages/FaqPage';
import Home from 'pages/Home';
import InfoPage from 'pages/Info';
import MessagingHowto from 'pages/MessagingHowto';
import NewConversation from 'pages/NewConversation';
import OnboardingPage from 'pages/Onboarding';
import OnboardingQuestionnaire from 'pages/OnboardingQuestionnaire';
import PasswordResetPage from 'pages/PasswordReset';
import PasswordResetSendEmailPage from 'pages/PasswordResetSendEmail';
import PatientConversationsHistory from 'pages/PatientConversationsHistory';
import PatientHistoricConversation from 'pages/PatientHistoricConversation';
import RegisterPage from 'pages/Register';
import SettingsPage from 'pages/SettingsPage';
import SymptomTracking from 'pages/SymptomTracking';
import SymptomTrackingSurvey from 'pages/SymptomTrackingSurvey';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import 'react-chat-elements/dist/main.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/Login';
import MessagingHome from './pages/MessagingHome';
import RegisterAskIfCode from './pages/RegisterAskIfCode';
import RegisterNoCode from './pages/RegisterNoCode';
import NotFoundPage from './pages/shared/NotFoundPage';
import Shell from './pages/shared/Shell';
import UnauthorizedPage from './pages/shared/UnauthorizedPage';
import { routes } from './services/RouteService';

function App() {
  const location = useLocation();

  useEffect(() => {
    amplitude.track('Page viewed', {
      location: location.pathname.replace(
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
        '<uuid>',
      ),
    });
    posthog.capture('$pageview', {
      $current_url: window.location.href.replace(
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
        '<uuid>',
      ),
    });
  }, [location]);

  return (
    <Routes>
      <Route element={<Shell />}>
        <Route path={routes.home.route} element={<Home />} />
        <Route path={routes.messagingHome.route} element={<MessagingHome />} />
        <Route path={routes.messagingHowto.route} element={<MessagingHowto />} />
        <Route path={routes.conversation.route} element={<ConversationPage />} />
        <Route path={routes.conversationSummary.route} element={<ConversationSummary />} />
        <Route path={routes.historicConversationSummary.route} element={<ConversationSummary historic />} />
        <Route path={routes.patientConversationsHistory.route} element={<PatientConversationsHistory />} />
        <Route path={routes.patientHistoricConversation.route} element={<PatientHistoricConversation />} />
        <Route path={routes.newChat.route} element={<NewConversation />} />
        <Route path={routes.info.route} element={<InfoPage />} />
        <Route path={routes.article.route} element={<ArticlePage />} />
        <Route path={routes.caregiversDirectory.route} element={<CaregiversDirectoryPage />} />
        <Route path={routes.faq.route} element={<FaqPage />} />
        <Route path={routes.symptomTracking.route} element={<SymptomTracking />} />
        <Route path={routes.symptomTrackingSurvey.route} element={<SymptomTrackingSurvey />} />
        <Route path={routes.settings.route} element={<SettingsPage />} />
      </Route>

      <Route path={routes.onboarding.route} element={<OnboardingPage />} />
      <Route path={routes.onboardingQuestionnaire.route} element={<OnboardingQuestionnaire />} />
      <Route path={routes.passwordResetSendEmail.route} element={<PasswordResetSendEmailPage />} />
      <Route path={routes.passwordReset.route} element={<PasswordResetPage />} />
      <Route path={routes.login.route} element={<LoginPage />} />
      <Route path={routes.register.route} element={<RegisterPage />} />
      <Route path={routes.registerAskIfCode.route} element={<RegisterAskIfCode />} />
      <Route path={routes.registerNoCode.route} element={<RegisterNoCode />} />
      <Route path={routes.notFound.route} element={<NotFoundPage />} />
      <Route path={routes.unauthorized.route} element={<UnauthorizedPage />} />
    </Routes>
  );
}

export default App;
