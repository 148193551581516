import { RootState } from 'types';
import { initialState } from '.';
import { ConversationType } from './types';

export const selectLoadedConversationsOnce = (state: RootState) => {
  return state?.conversations?.loadedConversationsOnce || initialState.loadedConversationsOnce;
};

export const selectConversationLoadingStatus = (state: RootState) => {
  return state?.conversations?.conversationLoadingStatus || initialState.conversationLoadingStatus;
};

export const selectConversationLoadingStatusIsLoading = (state: RootState) => {
  return (state?.conversations?.conversationLoadingStatus || initialState.conversationLoadingStatus) === 'LOADING';
};

export const selectWebsocketConnected = (state: RootState) => {
  return state?.conversations?.websocketConnected || initialState.websocketConnected;
};

const selectConversationsByType = (state: RootState, type: ConversationType, isCaregiver?: boolean) => {
  if (isCaregiver) {
    return (state?.conversations?.conversationsMetadata || initialState.conversationsMetadata)
      .filter(conversation => conversation.type === type)
      .flatMap(c => c.conversation);
  }

  return (state?.conversations?.conversationsMetadata || initialState.conversationsMetadata).flatMap(
    c => c.conversation,
  );
};

export const selectConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'assigned', isCaregiver);
};

export const selectUnassignedConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'unassigned', isCaregiver);
};

export const selectMyRequestsConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'myRequest', isCaregiver);
};

export const selectWaitingForReassignmentConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'waitingForReassignment', isCaregiver);
};

export const selectOnHoldConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'onHold', isCaregiver);
};

export const selectClosedConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'closed', isCaregiver);
};

export const selectConversationMessages = (state: RootState, conversationId: string) => {
  return (state?.conversations?.messagesPerConversation || initialState.messagesPerConversation)[conversationId] || [];
};

export const selectConversationMetadata = (state: RootState, conversationId: string) => {
  return (state?.conversations?.conversationsMetadata || initialState.conversationsMetadata).find(
    conversation => conversation.conversation.id === conversationId,
  );
};

export const selectConversationSummary = (state: RootState, conversationId: string) => {
  return (state?.conversations?.summaryPerConversation || initialState.summaryPerConversation)[conversationId] || '';
};

export const selectConversationSummaryDraft = (state: RootState, conversationId: string) => {
  return (
    (state?.conversations?.summaryDraftPerConversation || initialState.summaryDraftPerConversation)[conversationId] ||
    ''
  );
};

export const selectConversationInputText = (state: RootState, conversationId: string) => {
  return state?.conversations?.conversationInputTexts[conversationId];
};
