import { Badge, Tag } from 'antd';
import ColorHash from 'color-hash-ts';
import { useTranslation } from 'react-i18next';

const colorHash = new ColorHash();

const SymptomTag: React.FC<{ symptom: string }> = ({ symptom }) => {
  const { t } = useTranslation();
  return (
    <Tag key={symptom}>
      <Badge color={colorHash.hex(symptom)} text={t(`symptomTracking.survey.symptoms.${symptom}`)} />
    </Tag>
  );
};

export default SymptomTag;
