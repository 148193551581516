/* eslint-disable global-require */
import * as amplitude from '@amplitude/analytics-browser';
import { RightOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import posthog from 'posthog-js';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';
import Platform from 'utils/Platform';

export type NotificationSuggesterProps = {
  text: string;
};

const useStyles = createUseStyles({
  warningContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
  },
  arrowIcon: {
    fontSize: '0.7em',
  },
});

const NotificationSuggester: React.FC<NotificationSuggesterProps> = ({ text }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <Alert
      message={
        <div className={styles.warningContent}>
          <div>{text}</div>
          <div className={styles.arrowIcon}>
            <RightOutlined />
          </div>
        </div>
      }
      type="warning"
      onClick={() => {
        if (!Platform.isNative()) navigate(routes.settings.route);
        else {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          NativeSettings.open({
            optionAndroid: AndroidSettings.AppNotification,
            optionIOS: IOSSettings.App,
          });
        }
        amplitude.track('notification_suggester_clicked');
        posthog.capture('notification_suggester_clicked');
      }}
    />
  );
};
export default NotificationSuggester;
