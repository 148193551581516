import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useScrollRestoration(scrollableRef: React.RefObject<HTMLElement> | undefined) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollableElement = scrollableRef?.current;
    if (!scrollableElement) return undefined;

    // Restore scroll position if available
    if (location.state?.scrollValue !== undefined) {
      scrollableElement.scrollTop = location.state.scrollValue;

      if (location.state.scrollValue === 0) {
        // animate the scroll to the top
        scrollableElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }

    // Save scroll position when scrolling ends
    const handleScrollEnd = debounce(() => {
      navigate(location.pathname + location.search, {
        replace: true,
        state: {
          ...location.state,
          scrollValue: scrollableElement.scrollTop,
        },
      });
    }, 150);

    scrollableElement.addEventListener('scroll', handleScrollEnd);

    return () => {
      handleScrollEnd.cancel();
      scrollableElement.removeEventListener('scroll', handleScrollEnd);
    };
  }, [scrollableRef, location]);
}

export default useScrollRestoration;
