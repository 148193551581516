/* eslint-disable @typescript-eslint/no-floating-promises */
import * as amplitude from '@amplitude/analytics-browser';
import dayjs from 'dayjs';
import useSelectConversations from 'hooks/useSelectConversations';
import posthog from 'posthog-js';
import { ChatItem, IChatItemProps } from 'react-chat-elements';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';
import ApiDoctorConversationsManager from 'services/api/ApiDoctorConversationsManager';
import ApiPatientConversationsManager from 'services/api/ApiPatientConversationsManager';
import { ConversationDTO } from 'services/api/models/ConversationDTO';
import { conversationActions } from 'store/conversations';
import { selectCurrentUser } from 'store/users/selectors';
import logoAsset from '../../assets/logo.png';
import AppColors from '../../config/AppColors';

export type ChatsListProps = {
  conversations: ConversationDTO[];
  // eslint-disable-next-line react/no-unused-prop-types
  title: string | null;
  readOnlyConvs: boolean;
  maximumConversations?: number;
};

const useStyles = createUseStyles({
  title: {
    paddingLeft: 13,
  },
  noChats: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
    color: AppColors.text.discrete,
  },
  chatsListCaregiver: {
    display: 'block',
    overflow: 'auto',
    minHeight: '30%',

    '& .rce-citem-body--bottom-status span': {
      backgroundColor: AppColors.main,
      borderRadius: '50%',
    },
  },
  chatsListPatient: {
    display: 'block',
    overflow: 'auto',
    maxHeight: 280, // about 4 conversations
    '-webkit-mask-image': 'linear-gradient(180deg, #000 80%, transparent)',
    marginBottom: 8,

    '& .rce-citem': {
      backgroundColor: 'transparent !important',
    },

    '& .rce-citem-body--bottom-status span': {
      backgroundColor: AppColors.main,
      borderRadius: '50%',
    },
  },
  backgroundFillBehindGradient: {
    height: 20,
  },
});

export default function ChatsList(props: ChatsListProps) {
  const { conversations, title, readOnlyConvs, maximumConversations } = props;

  const displayedConversations = maximumConversations ? conversations.slice(0, maximumConversations) : conversations;

  // Hooks
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Selectors
  const { conversationId: mainConversationId } = useSelectConversations();
  const currentUser = useSelector(selectCurrentUser);

  function onChatClicked(conversation: IChatItemProps) {
    if (!readOnlyConvs) {
      navigate(routes.conversation.route.replace(':conversationId', conversation.id.toString()));

      // Mark the messages as read
      dispatch(conversationActions.resetUnreadMessages({ conversationId: conversation.id.toString() }));
      if (currentUser?.is_caregiver) {
        ApiDoctorConversationsManager.markConversationAsRead(conversation.id.toString());
      } else {
        ApiPatientConversationsManager.markConversationAsRead(conversation.id.toString());
      }
      amplitude.track('chats.conversation_opened');
      posthog.capture('chats.conversation_opened');
    } else {
      navigate(
        routes.patientHistoricConversation.route
          .replace(':conversationId', mainConversationId)
          .replace(':historicConversation', conversation.id.toString()),
      );
    }
  }
  const convertConversations = (conversationsList: ConversationDTO[]) => {
    return conversationsList.map(conversation => {
      // If the current user is a caregiver, display the patient avatar or a failover avatar. Otherwise, display the caregiver's avatar
      const recipientAvatar = currentUser?.is_caregiver
        ? conversation?.patient.photo_url || logoAsset || t('chats.noCaregiver.avatar')
        : conversation?.current_owner?.photo_url || logoAsset;

      // If the current user is a caregiver, display the patient name or a failover name. Otherwise, display the caregiver's name
      const recipientName = currentUser?.is_caregiver
        ? conversation?.patient?.display_name || t('chats.noCaregiver.name.forCaregiver')
        : conversation?.current_owner?.display_name || t('chats.noCaregiver.name.forPatient');

      return {
        ...conversation,
        avatar: recipientAvatar,
        alt: recipientName,
        title: recipientName,
        subtitle: conversation.last_message?.message_text || t('chats.noLastMessage'),
        date: conversation.last_message?.sent_at ? new Date(conversation.last_message?.sent_at) : new Date(),
        unread: conversation.number_of_unread_messages,
      };
    });
  };

  return (
    <>
      {title && <h3 className={styles.title}>{title}</h3>}

      {displayedConversations.length === 0 ? (
        <p className={styles.noChats}>
          {' '}
          {currentUser?.is_caregiver ? t('chats.list.noChats.forCaregiver') : t('chats.list.noChats.forPatient')}
        </p>
      ) : (
        <div className={currentUser?.is_caregiver ? styles.chatsListCaregiver : styles.chatsListPatient}>
          {convertConversations(displayedConversations).map(c => {
            return (
              <ChatItem
                key={c.id}
                id={c.id}
                avatar={c.avatar}
                alt={c.title}
                title={c.title}
                subtitle={c.subtitle}
                date={c.date}
                dateString={dayjs(c.date).fromNow()}
                unread={c.unread}
                onClick={() => onChatClicked(c)}
              />
            );
          })}
          {displayedConversations.length >= 4 && <div className={styles.backgroundFillBehindGradient} />}
        </div>
      )}
    </>
  );
}
