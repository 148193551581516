import AppColors from 'config/AppColors';
import { Dayjs } from 'dayjs';
import { Label, Line, LineChart, ReferenceArea, ResponsiveContainer, XAxis, YAxis } from 'recharts';

export type LinearCalendarChartProps = {
  dayLabels: Dayjs[];
  dayValues: number[];
  reverseColors?: boolean;
  range: number[];
};

const LinearCalendarChart: React.FC<LinearCalendarChartProps> = ({
  dayLabels,
  dayValues,
  reverseColors = false,
  range,
}) => {
  // Hooks
  const [unsafeRangeMin, unsafeRangeMax] = range;
  const rangeMax = unsafeRangeMax + 0.002;
  const rangeMin = unsafeRangeMin - 0.002;

  const normalizedValues = dayValues.map((v, i) => {
    if (v === null) return null;
    const normalized = (v - rangeMin) / (rangeMax - rangeMin);
    return {
      value: normalized * 3 + 1.5, // clamping to a 1.5-3.5 range to leave some margin on the graph
      dayIndex: i + 0.5, // adding .5 to the day index to plot points in the center of day columns
    };
  });

  const gradientName = `higherIsBetter-${rangeMin}-${rangeMax}`;
  const reverseGradientName = `lowerIsBetter-${rangeMin}-${rangeMax}`;
  return (
    <ResponsiveContainer minWidth={300} width="100%" height={70}>
      <LineChart data={normalizedValues} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
        <XAxis type="number" dataKey="dayIndex" domain={[0, dayLabels.length]} hide />
        <YAxis dataKey="value" domain={[0, 5]} hide />
        {dayLabels.map((day, index) => (
          <ReferenceArea
            key={`whitebar-${index}`}
            x1={index}
            x2={index + 1}
            y1={0}
            y2={10}
            fill="white"
            fillOpacity={1}
            stroke={AppColors.backgroundGrey}
            strokeWidth={2}
            ifOverflow="visible"
          />
        ))}
        {dayLabels.map((day, index) => (
          <ReferenceArea
            key={`daybar-${index}`}
            x1={index}
            x2={index + 1}
            y1={0}
            y2={1}
            fill={day.isoWeekday() < 6 ? '#E7E7E7' : '#979797'}
            fillOpacity={1}
            stroke={AppColors.backgroundGrey}
            strokeWidth={2}
            ifOverflow="visible"
            label={<Label fontSize={8} value={day.date()} fill={day.isoWeekday() < 6 ? '#979797' : 'white'} />}
          />
        ))}
        <defs>
          <linearGradient id={gradientName} x1="0%" x2="0%" y1="20%" y2="70%" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stopColor="#7FBAA9" />
            <stop offset="23%" stopColor="#CBD993" />
            <stop offset="58%" stopColor="#EDC27E" />
            <stop offset="75%" stopColor="#EDA67E" />
            <stop offset="100%" stopColor="#B44359" />
          </linearGradient>
          <linearGradient id={reverseGradientName} x1="0%" x2="0%" y1="70%" y2="10%" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stopColor="#7FBAA9" />
            <stop offset="23%" stopColor="#CBD993" />
            <stop offset="58%" stopColor="#EDC27E" />
            <stop offset="75%" stopColor="#EDA67E" />
            <stop offset="100%" stopColor="#B44359" />
          </linearGradient>
        </defs>
        <Line
          type="monotone"
          dataKey="value"
          stroke={reverseColors ? `url(#${reverseGradientName})` : `url(#${gradientName})`}
          strokeWidth={4}
          dot={{ strokeWidth: 2 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default LinearCalendarChart;
