/* eslint-disable @typescript-eslint/no-floating-promises */
import { useRequest } from 'ahooks';
import dayjs, { Dayjs } from 'dayjs';
import ApiSymptomTrackingManager from 'services/api/ApiSymptomTrackingManager';
import { DailyStatus } from 'services/api/models/SymptomTrackingDTO';

export default function useLoadConversations() {
  const today = dayjs();

  const {
    data: statusesList,
    loading: loadingData,
    refresh,
  } = useRequest(() => ApiSymptomTrackingManager.getHistory(), {
    cacheKey: 'tracking.history',
  });

  const loading = statusesList === undefined && loadingData;
  const hasTrackingBeenUsed = !loading && statusesList && statusesList.length > 0;

  const statusPerDay = Object.fromEntries((statusesList || []).map(status => [status.date as string, status]));

  const getSpecificDailyStatus = (date: Dayjs) => {
    return statusPerDay[date.format('YYYY-MM-DD')];
  };

  const hasTrackingForToday = getSpecificDailyStatus(today) !== undefined;

  const getDisplayedDays = (selectedDate: Dayjs) => {
    if (today.format('YYYY-MM') === selectedDate.format('YYYY-MM')) {
      // show last 31 days if we are looking at current month
      // (to avoid having empty graphs at the start of the month)
      return Array.from({ length: 31 }, (_, i) => today.subtract(i, 'days')).reverse();
    }

    return Array.from({ length: selectedDate.daysInMonth() }, (_, i) =>
      dayjs(new Date(selectedDate.year(), selectedDate.month(), i + 1)),
    );
  };

  const getStatusValueForDisplayedDays = (
    value: keyof DailyStatus,
    displayedDays: Dayjs[],
    defaultValue: any = null,
  ): any[] => {
    return displayedDays.map(day => {
      const dailyStatus = getSpecificDailyStatus(dayjs(day));
      return dailyStatus ? dailyStatus[value] : defaultValue;
    });
  };

  return {
    getSpecificDailyStatus,
    getDisplayedDays,
    getStatusValueForDisplayedDays,
    refresh,
    hasTrackingBeenUsed,
    hasTrackingForToday,
    loading,
  };
}
