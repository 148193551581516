/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-unescaped-entities */
import { AreaChartOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Image, Typography } from 'antd';
import AppColors from 'config/AppColors';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import useLogout from 'hooks/useLogout';
import { createUseStyles } from 'react-jss';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { RouteInfo, routes } from '../../services/RouteService';

const useStyles = createUseStyles({
  navBarContainer: {
    background: `${AppColors.offWhite} !important`,
    alignItems: 'center',
    padding: 10,
    paddingBottom: 'calc(env(safe-area-inset-bottom) / 2 + 20px)',
  },
  buttonsContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  buttonsCenterContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  container: {
    '& span.ant-btn-icon': {
      alignSelf: 'center',
    },
  },
  navigationText: {
    color: `${AppColors.text.navigation} !important`,
  },
  buttonMobile: {
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobileText: {
    fontSize: 12,
  },
  logoutIcon: {
    marginTop: 4,
    fontSize: 26,
  },
});

export default function BottomNavBar() {
  // Hooks
  const navigate = useNavigate();
  const styles = useStyles();
  const { logout } = useLogout();
  const { isSmallScreen } = useIsSmallScreen();
  const location = useLocation();

  const onClick = (route: string) => {
    if (route === routes.logout.route) {
      logout();
      navigate(routes.login.route, { replace: true });
    } else if (location.pathname === route) {
      // if we are already on the route, scroll to top
      navigate(route, { replace: true, state: { ...location.state, scrollValue: 0 } });
    } else {
      navigate(route);
    }
  };

  function renderIcon(src: any, size: number, svgIcon: any, active?: boolean) {
    if (svgIcon) {
      const iconColor = active === true ? `${AppColors.text.navigationActive}` : `${AppColors.text.navigation}`;
      return <div style={{ height: size, fontSize: size * 0.75, color: iconColor }}>{svgIcon}</div>;
    }
    return <Image alt="icon" height={size} width={size} preview={false} src={src} />;
  }

  function renderNavButton(params: {
    svgIcon?: any;
    routeInfo: RouteInfo;
    onClickFn?: () => void;
    isCenterIcon?: boolean;
    routesGroup?: string;

    // these are deprecated
    iconSrc?: any;
    selectedIconSrc?: any;
  }) {
    const centerButtonStyle = {
      borderWidth: '10px',
      borderColor: AppColors.offWhite,
      borderRadius: '35px',
      height: 'auto',
      padding: 0,
    };

    const matchingRoute = Object.values(routes).find(route => matchPath(location.pathname, route.route));

    const highlightIcon = matchingRoute && matchingRoute.routesGroup === params.routesGroup;
    const iconSource = highlightIcon ? params.selectedIconSrc : params.iconSrc;

    return (
      <Button
        className={styles.container}
        type="link"
        icon={!isSmallScreen && renderIcon(iconSource, 35, params.svgIcon, highlightIcon)}
        onClick={() => {
          if (params.onClickFn) {
            params.onClickFn();
          } else {
            onClick(params.routeInfo.route);
          }
        }}
        style={params.isCenterIcon ? centerButtonStyle : {}}
      >
        {isSmallScreen ? (
          <div className={styles.buttonMobile}>
            {renderIcon(iconSource, params.isCenterIcon ? 50 : 30, params.svgIcon, highlightIcon)}
            {params.isCenterIcon ? null : (
              <Typography.Text className={styles.mobileText}>
                <span className={styles.navigationText}>{params.routeInfo.label}</span>
              </Typography.Text>
            )}
          </div>
        ) : (
          <span className={styles.navigationText}>{params.routeInfo.label}</span>
        )}
      </Button>
    );
  }

  return (
    <div className={styles.navBarContainer}>
      <div className={styles.buttonsCenterContainer}>
        {renderNavButton({
          iconSrc: require('../../assets/logo.png'),
          selectedIconSrc: require('../../assets/logo-dark.png'),
          routeInfo: routes.home,
          routesGroup: 'home',
        })}
        {renderNavButton({
          iconSrc: require('../../assets/chat-light.png'),
          selectedIconSrc: require('../../assets/chat-dark.png'),
          routeInfo: routes.messagingHome,
          routesGroup: 'messaging',
        })}
        {renderNavButton({
          routeInfo: routes.symptomTracking,
          routesGroup: 'tracking',
          svgIcon: <AreaChartOutlined />,
        })}
        {renderNavButton({
          iconSrc: require('../../assets/book-light.png'),
          selectedIconSrc: require('../../assets/book-dark.png'),
          routeInfo: routes.info,
          routesGroup: 'info',
        })}
        {!isSmallScreen && (
          <Button className={styles.container} type="link">
            <LogoutOutlined
              className={styles.logoutIcon}
              onClick={() => {
                logout();
                navigate(routes.login.route, { replace: true });
              }}
            />
          </Button>
        )}
      </div>
    </div>
  );
}
