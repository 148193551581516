import { Avatar, Col } from 'antd';
import AppColors from 'config/AppColors';
import defaultCaregiverPhoto from '../assets/experts-man-dark.png';

export type CaregiverAvatarProps = {
  avatar: string;
  name?: string;
  caption?: string;
};

const CaregiverAvatar: React.FC<CaregiverAvatarProps> = ({ avatar, name, caption }) => {
  return (
    <div
      style={{
        textAlignLast: 'center',
      }}
    >
      <div>
        <Col>
          <Avatar src={avatar || defaultCaregiverPhoto} size={50} alt="profile" />
        </Col>
      </div>
      <div>
        <p
          style={{
            margin: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {name}
        </p>
      </div>
      <p
        style={{
          margin: 0,
          fontSize: '0.7em',
          lineHeight: '0.9em',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          color: AppColors.text.discrete,
        }}
      >
        {caption}
      </p>
    </div>
  );
};
export default CaregiverAvatar;
