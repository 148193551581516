import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import ScrollToTop from 'hooks/ScrollToTop';
import { locale } from 'locales/i18nManager';
import posthog from 'posthog-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store/configureStore';
import App from './App';
import AppColors from './config/AppColors';
import reportWebVitals from './reportWebVitals';

/* eslint-disable-next-line import/no-dynamic-require */
require(`dayjs/locale/fr`);

dayjs.locale(locale);
dayjs.extend(weekday);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

const SENTRY_BASE_CONF = {
  dsn: 'https://792a823ba039e63f000fb6863b0ca954@o4507486974377984.ingest.de.sentry.io/4507486992138320',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};
if (import.meta.env.VITE_API_SERVER_URL === 'https://api.chat.gyngercare.com/')
  Sentry.init({
    ...SENTRY_BASE_CONF,
    environment: 'production',
    tracePropagationTargets: [/^https:\/\/api.chat.gyngercare\.com\//],
  });
else if (import.meta.env.VITE_API_SERVER_URL === 'https://api.staging-briac.gyngercare.com/')
  Sentry.init({
    ...SENTRY_BASE_CONF,
    environment: 'staging',
    tracePropagationTargets: [/^https:\/\/api.staging-briac.gyngercare\.com\//],
  });

if (import.meta.env.VITE_AMPLITUDE_API_KEY) {
  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
    serverZone: 'EU',
    identityStorage: 'localStorage',
    trackingOptions: {
      ipAddress: false,
    },
    defaultTracking: {
      pageViews: false,
    },
  });
  const sessionReplayTracking = sessionReplayPlugin({
    privacyConfig: { defaultMaskLevel: 'conservative' },
  });
  amplitude.add(sessionReplayTracking);
}

if (import.meta.env.VITE_POSTHOG_API_KEY) {
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only',
    mask_all_text: true,
    session_recording: {
      maskAllInputs: true,
      maskTextSelector: '*',
    },
    before_send(event: any) {
      if (event?.properties.$ip) {
        event.properties.$ip = null;
      }

      if (event && import.meta.env.VITE_GIT_TAG) {
        event.properties.gynger_software_version = import.meta.env.VITE_GIT_TAG;
      }

      event.properties.gynger_platform = Capacitor.getPlatform();

      return event;
    },
  });
}

// Clear the existing HTML content
document.body.innerHTML = '<div id="root"></div>';

const rootElement = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);

// Render your React component instead
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider
          locale={frFR}
          theme={{
            token: {
              // Brand color is one of the most direct visual elements to reflect the characteristics and communication of the product. After you have selected the brand color, we will automatically generate a complete color palette and assign it effective design semantics
              colorPrimary: AppColors.main,
              colorError: AppColors.colorMap.veryBad,
              // Used to represent the operation information of the Token sequence, such as Alert, Tag, Progress, and other components use these map tokens.	string	#1677ff
              colorInfo: AppColors.main,
              // Used to derive the base variable of the text color gradient
              colorTextBase: AppColors.colorTextBase,
              // The fourth level of text color is the lightest text color, such as form input prompt text, disabled color text, etc
              // colorTextQuaternary: AppColors.colorTextDisabled,
              // Control the color of text in disabled state.
              colorTextDisabled: AppColors.colorTextDisabled,
              // The font family of Ant Design prioritizes the default interface font of the system, and provides a set of alternative font libraries that are suitable for screen display to maintain the readability and readability of the font under different platforms and browsers, reflecting the friendly, stable and professional characteristics.
              // fontFamily: fonts.fontFamily,
              // Border radius of base components	number	6
              borderRadius: 8,
            },
          }}
        >
          <ScrollToTop />
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
