import * as amplitude from '@amplitude/analytics-browser';
import { SendOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Form, Input, Space } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import GGButton from 'components/buttons/GGButton';
import useSelectConversations from 'hooks/useSelectConversations';
import posthog from 'posthog-js';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiDoctorConversationsManager from 'services/api/ApiDoctorConversationsManager';
import ApiPatientConversationsManager from 'services/api/ApiPatientConversationsManager';
import { routes } from 'services/RouteService';
import { conversationActions } from 'store/conversations';
import { selectCurrentUser } from 'store/users/selectors';

const { TextArea } = Input;

export type MessageInputProps = {
  disabled?: boolean;
  animatePlaceholder?: boolean;
  minRows?: number;
  // when navigating away from this page, we can choose to make this page transient and make the next component
  // navigate back to the page prior to the one with this message input
  isOnTransientPage?: boolean;
};

const useStyles = createUseStyles({
  container: {
    zIndex: 9,
    padding: 13,
  },
  messageInput: {
    marginInlineEnd: '10px !important',
  },
  sendButton: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .ant-btn-icon': {
      marginLeft: 2,
    },
  },
});

const MessageInput: React.FC<MessageInputProps> = ({
  disabled = false,
  animatePlaceholder = false,
  minRows = 1,
  isOnTransientPage = false,
}) => {
  // State
  const [inputValue, setInputValue] = useState<string>();

  // Hooks
  const styles = useStyles();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [api, context] = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Selector
  const { conversationId: currentConversationId, inputText: currentInputText } = useSelectConversations();
  const currentUser = useSelector(selectCurrentUser);

  const saveInputTextValueInStore = (text?: string) => {
    dispatch(
      conversationActions.updateConversationInputText({
        conversationId: currentConversationId,
        inputText: text,
      }),
    );
  };

  const saveInputText = (text?: string) => {
    if (currentConversationId) {
      // Existing conversation, save the text in Redux store
      saveInputTextValueInStore(text);
    } else {
      // New conversation (= no conversation id), save the text in local state
      setInputValue(text);
    }
  };

  function sendMessage(messageText: string) {
    if (!currentConversationId) {
      return ApiPatientConversationsManager.createPatientConversations({
        initialMessageText: messageText,
      });
    }

    if (currentUser?.is_caregiver) {
      return ApiDoctorConversationsManager.postMessageToDoctorConversation({
        conversationId: currentConversationId,
        messageText,
      });
    }

    return ApiPatientConversationsManager.postMessageToPatientConversations({
      conversationId: currentConversationId,
      messageText,
    });
  }

  const { loading, run } = useRequest((messageText: string) => sendMessage(messageText), {
    manual: true,
    onSuccess: result => {
      // Reset the form
      form.resetFields();

      // Reset the input text stored in local state or Redux store
      saveInputText(undefined);

      // Trigger callback on message sent
      if (!currentConversationId) {
        navigate(routes.conversation.route.replace(':conversationId', result.id), { replace: isOnTransientPage });
      }
    },
    onError: () => {
      api.error({
        message: t('chats.list.errors.sendMessage'),
      });
    },
  });

  const onFinish = (values: any) => {
    amplitude.track('chats.message_sent');
    posthog.capture('chats.message_sent');
    run(values.message);
  };

  // animate placeholders within the textarea
  const placeholders = t<string, { returnObjects: true }, string[]>('chats.new.exampleMessages', {
    returnObjects: true,
  });
  const [placeholder, setPlaceholder] = useState('');
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  useEffect(() => {
    const currentPlaceholder = placeholders[currentPlaceholderIndex];
    function nextAnimationTick() {
      if (placeholder.length < currentPlaceholder.length) {
        setPlaceholder(currentPlaceholder.slice(0, placeholder.length + 1));
      } else {
        setCurrentPlaceholderIndex((currentPlaceholderIndex + 1) % placeholders.length);
        setPlaceholder('');
      }
    }

    if (animatePlaceholder) {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(nextAnimationTick, placeholder.length === currentPlaceholder.length ? 1200 : 20);
    }
  }, [placeholder, currentPlaceholderIndex]);

  // Get the input text, either from the Redux store or local state
  const input = currentInputText || inputValue;
  // A text is present and no empty text
  const isValidInputValue = !!(input && input.length > 0);

  return (
    <Form
      className={styles.container}
      layout="inline"
      form={form}
      onFinish={onFinish}
      fields={[
        {
          name: ['message'],
          value: currentInputText || inputValue,
        },
      ]}
    >
      {context}
      <Form.Item
        className={styles.messageInput}
        style={{ flex: 1 }}
        name="message"
        rules={[{ required: true, message: '' }]}
      >
        <TextArea
          placeholder={animatePlaceholder ? placeholder : t('chats.fields.message.placeholder')}
          size="large"
          autoComplete="off"
          autoCorrect="on"
          autoSize={{ minRows, maxRows: 5 }}
          onChange={e => saveInputText(e.target.value)}
          data-testid="message-input"
        />
      </Form.Item>

      <Form.Item className={styles.sendButton} wrapperCol={{ span: 12, offset: 6 }}>
        <Space>
          <GGButton
            type="primary"
            htmlType="submit"
            size="large"
            icon={<SendOutlined />}
            disabled={loading || disabled || !isValidInputValue}
            loading={loading}
            shape="circle"
          />
        </Space>
      </Form.Item>
    </Form>
  );
};
export default MessageInput;
