/* eslint-disable global-require */
import { CloseOutlined } from '@ant-design/icons';
import { Preferences } from '@capacitor/preferences';
import { useRequest } from 'ahooks';
import { Avatar, Card } from 'antd';
import Meta from 'antd/es/card/Meta';
import GGButton from 'components/buttons/GGButton';
import AppColors from 'config/AppColors';
import { createUseStyles } from 'react-jss';

export type HelperCardProps = {
  text: string;
  shouldDisplayPreferencesKey: string;
};

const useStyles = createUseStyles({
  explainCard: {
    backgroundColor: AppColors.backgroundLightGreen,
    width: '100%',
    whiteSpace: 'pre-line',
    marginBottom: 16,
  },

  closeInfoPanel: {
    float: 'right',
    fontSize: 21,
    '& button': {
      boxShadow: 'none',
    },
  },
});

const HelperCard: React.FC<HelperCardProps> = ({ text, shouldDisplayPreferencesKey }) => {
  const styles = useStyles();

  const {
    run: getShouldDisplayHelp,
    data: shouldDisplayHelper,
    loading: loadingHelperToggle,
  } = useRequest(() => Preferences.get({ key: shouldDisplayPreferencesKey }));
  const { run: closeHelper } = useRequest(() => Preferences.set({ key: shouldDisplayPreferencesKey, value: 'false' }), {
    manual: true,
    onSuccess: () => {
      getShouldDisplayHelp();
    },
  });
  const shouldDisplayHelp = !loadingHelperToggle && shouldDisplayHelper?.value !== 'false';

  return (
    shouldDisplayHelp && (
      <Card className={styles.explainCard}>
        <Meta
          avatar={<Avatar src={require('../../assets/logo.png')} />}
          description={
            <>
              <span className={styles.closeInfoPanel}>
                <GGButton type="text" onClick={() => closeHelper()}>
                  <CloseOutlined />
                </GGButton>
              </span>
              {text}
            </>
          }
        />
      </Card>
    )
  );
};
export default HelperCard;
