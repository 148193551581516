import { Col, Row } from 'antd';
import ConversationTopBar from 'components/chats/ConversationTopBar';
import MessagesList from 'components/chats/MessagesList';
import MessageInput from 'components/inputs/MessageInput';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import defaultAvatarUrl from '../assets/experts-man-dark.png';

const useStyles = createUseStyles({
  container: {
    height: '100%',
  },
  conversationsContainer: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
  },
});

export default function NewConversation() {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryStringParams, setQueryStringParams] = useSearchParams();

  function onBackButtonClick() {
    navigate(-1);
  }

  const introMessageText =
    queryStringParams.has('conversationType') && queryStringParams.get('conversationType') === 'checkup'
      ? t('chats.new.introMessageHealthCheckUp')
      : t('chats.new.introMessageAskQuestion');

  const viewTitle =
    queryStringParams.has('conversationType') && queryStringParams.get('conversationType') === 'checkup'
      ? t('chats.new.titleHealthCheckUp')
      : t('chats.new.titleAskQuestion');

  const messages = [
    {
      message_text: introMessageText,
      sender: {
        id: t('chats.new.introMessageSenderId'),
        display_name: t('chats.new.introMessageSenderName'),
        first_name: t('chats.new.introMessageSenderName'),
        is_caregiver: false,
        photo_url: defaultAvatarUrl,
        number_of_conversations_as_patient: 0,
      },
      sent_at: new Date().toISOString(),
    },
  ];

  return (
    <Row className={styles.container}>
      <Col className={styles.conversationsContainer} span={24}>
        <ConversationTopBar showBackButton name={viewTitle} onBackButtonClick={() => onBackButtonClick()} />
        <MessagesList messages={messages} showExampleQuestions />
        <MessageInput isOnTransientPage />
      </Col>
    </Row>
  );
}
