/* eslint-disable global-require */
import * as amplitude from '@amplitude/analytics-browser';

import { InfoCircleFilled, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Calendar, Empty, Modal, Row } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import Drop from 'assets/drop.svg?react';
import GGButton from 'components/buttons/GGButton';
import CalendarTabs from 'components/cards/CalendarTabs';
import HelperCard from 'components/cards/HelperCard';
import LinearCalendarChart from 'components/charts/LinearCalendarChart';
import SymptomsChart from 'components/charts/SymptomsChart';
import PageTopBar from 'components/navigation/PageTopBar';
import SymptomTag from 'components/SymptomTag';
import SymptomTrackingReminder from 'components/SymptomTrackingReminder';
import AppColors from 'config/AppColors';
import dayjs, { Dayjs } from 'dayjs';
import useSymptomTrackingData from 'hooks/useSymptomTrackingData';
import posthog from 'posthog-js';
import Keys from 'preferences/preferences';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: AppColors.backgroundGrey,
  },
  container: {
    padding: '8px 12px 32px',
    overflowY: 'scroll',
    flexGrow: 1,
    maxWidth: 600,
    width: '100vw',
  },
  calendar: {
    background: 'none',
    marginBottom: 16,
    '& .ant-picker-panel': {
      border: 'none',
    },
    '& .ant-picker-date-panel': {
      backgroundColor: AppColors.backgroundGrey,
    },
    '& .ant-picker-body thead': {
      display: 'none',
    },
    '& .ant-picker-cell': {
      padding: 1,
      backgroundColor: 'white',
      border: `solid 3px ${AppColors.backgroundGrey}`,
    },
  },
  calendarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 17,
    color: AppColors.text.header,
  },
  dateCellContainer: {
    aspectRatio: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 18,
  },
  dateNumberOtherMonth: { color: AppColors.text.veryLightGray },
  dateNumberPast: {},
  dateNumberToday: { fontWeight: 'bold' },
  dateNumberFuture: { color: AppColors.text.lightGray },
  dayDots: { display: 'flex', flexDirection: 'row' },
  dateCellTopRow: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: 3,
    paddingRight: 4,
    height: 3,
    fontSize: 10,
    color: AppColors.text.veryLightGray,
    width: '100%',
    position: 'relative',
    top: 4,
  },
  dateCellBottomRow: { display: 'flex', flexDirection: 'row' },
  noDataDay: {
    height: 6,
    width: 6,
    backgroundColor: 'white',
  },
  filledOutDay: {
    height: 6,
    width: 6,
    backgroundColor: AppColors.main,
    shapeOutside: 'circle()',
    clipPath: 'circle()',
  },
  bloodDrop: {
    height: 7,
    width: 7,
  },
  graphTitle: {
    margin: '16px 0px 8px',
    color: AppColors.text.discrete,
  },
  emptySpaceBehindSurveyButton: {
    height: 80,
  },
  fillSurveyButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: 32,
    background: `linear-gradient(to top, ${AppColors.backgroundGrey}, ${AppColors.backgroundGrey} 50%, ${AppColors.backgroundGrey}00 100%);`,
  },
  specificDayModalDescription: {
    marginBottom: 0,
    color: AppColors.text.veryLightGray,
  },
  specificDayModalTitle: {
    marginTop: 0,
  },
  specificDayModalBigValue: {
    margin: 0,
    marginTop: -5,
    fontWeight: 'bold',
    fontSize: 19,
    '& svg': {
      height: 13,
    },
    '& svg:first-child': {
      marginLeft: 10,
    },
  },
  specificDayModalValue: {
    margin: 0,
    whiteSpace: 'pre-line',
  },
});

export default function SymptomTracking() {
  // Hooks
  const styles = useStyles();
  const [api, context] = useNotification();
  const { t } = useTranslation();
  const ref = useRef(null);
  const navigate = useNavigate();

  const [specificDay, setSpecificDay] = useState<Dayjs>();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  const GENERAL_STATE_MAP: { [key: number]: { value: string; color: string } } = {
    0: { value: t('symptomTracking.survey.generalState.veryBad'), color: AppColors.colorMap.veryBad },
    1: { value: t('symptomTracking.survey.generalState.bad'), color: AppColors.colorMap.bad },
    2: { value: t('symptomTracking.survey.generalState.average'), color: AppColors.colorMap.average },
    3: { value: t('symptomTracking.survey.generalState.good'), color: AppColors.colorMap.good },
    4: { value: t('symptomTracking.survey.generalState.veryGood'), color: AppColors.colorMap.veryGood },
  };
  const MENSTRUATION_MAP: { [key: number]: { value: string; color: string } } = {
    0: { value: t('symptomTracking.survey.menstruation.no'), color: AppColors.colorMap.veryGood },
    1: { value: t('symptomTracking.survey.menstruation.low'), color: AppColors.colorMap.good },
    2: { value: t('symptomTracking.survey.menstruation.medium'), color: AppColors.colorMap.average },
    3: { value: t('symptomTracking.survey.menstruation.high'), color: AppColors.colorMap.bad },
    4: { value: t('symptomTracking.survey.menstruation.veryHigh'), color: AppColors.colorMap.veryBad },
  };
  const PAIN_MAP: { [key: number]: { value: string; color: string } } = {
    0: { value: t('symptomTracking.survey.pain.none'), color: AppColors.colorMap.veryGood },
    1: { value: t('symptomTracking.survey.pain.light'), color: AppColors.colorMap.good },
    2: { value: t('symptomTracking.survey.pain.moderate'), color: AppColors.colorMap.average },
    3: { value: t('symptomTracking.survey.pain.strong'), color: AppColors.colorMap.bad },
    4: { value: t('symptomTracking.survey.pain.intense'), color: AppColors.colorMap.veryBad },
  };

  const { getSpecificDailyStatus, getDisplayedDays, getStatusValueForDisplayedDays, refresh } =
    useSymptomTrackingData();

  const today = dayjs();
  const displayedDays = getDisplayedDays(selectedDate);

  const onDateSelected = (date: Dayjs) => {
    setSelectedDate(date);
    if (date <= today) setSpecificDay(date);
    amplitude.track('tracking.calendar_day_selected');
    posthog.capture('tracking.calendar_day_selected');
  };

  const specificDayData = specificDay ? getSpecificDailyStatus(specificDay) : undefined;
  const isTheredataForToday = getSpecificDailyStatus(today) !== undefined;

  function renderMenstruationLevel(value: number) {
    return [...Array(value)].map((_, i) => (
      <Drop key={i} fill={AppColors.colorMap.veryBad} className={styles.bloodDrop} />
    ));
  }

  function getDateStyle(date: Dayjs) {
    if (date.month() !== today.month()) return styles.dateNumberOtherMonth;

    if (date.date() < today.date()) return styles.dateNumberPast;
    if (date.date() > today.date()) return styles.dateNumberFuture;

    return styles.dateNumberToday;
  }

  const renderDateCell = (current: Dayjs) => {
    const dayData = getSpecificDailyStatus(current);
    return (
      <div className={styles.dateCellContainer}>
        <div className={styles.dateCellTopRow}>{dayData && dayData.comment && <InfoCircleFilled />}</div>
        <div className={getDateStyle(current)}>{current.date()}</div>
        {dayData ? (
          <div className={styles.dayDots}>
            <div className={styles.dateCellBottomRow}>
              {dayData.menstruation_amount === 0 ? (
                <div
                  className={styles.filledOutDay}
                  data-testid={`tracking.filled-out-day-${current.format('YYYY-MM-DD')}`}
                />
              ) : (
                renderMenstruationLevel(dayData.menstruation_amount)
              )}
            </div>
          </div>
        ) : (
          <div className={styles.noDataDay} />
        )}
      </div>
    );
  };

  const previousMonth = () => {
    // note: we add 2 days to exclude any edge-effects with timezones
    setSelectedDate(selectedDate.startOf('month').subtract(1, 'month').add(2, 'days'));
  };
  const nextMonth = () => {
    // note: we add 2 days to exclude any edge-effects with timezones
    setSelectedDate(selectedDate.startOf('month').add(1, 'month').add(2, 'days'));
  };

  const renderCalendarHeader = ({ value }: { value: Dayjs }) => {
    return (
      <div className={styles.calendarHeader}>
        <GGButton onClick={previousMonth}>
          <LeftOutlined />
        </GGButton>
        {value.format('MMMM YYYY')}
        <GGButton onClick={nextMonth}>
          <RightOutlined />
        </GGButton>
      </div>
    );
  };

  useEffect(() => {
    // sometimes in functional tests, I think useRequest considers two very close requests as the same
    // and does not refresh the data when this page mounts. This fixes it
    refresh();
  }, []);

  return (
    <>
      <div className={styles.pageContainer}>
        <PageTopBar title={t('symptomTracking.title')} />
        <div className={styles.container} ref={ref}>
          {context}

          <HelperCard
            text={t('symptomTracking.description')}
            shouldDisplayPreferencesKey={Keys.TRACKING_SHOULD_DISPLAY_HELPER}
          />
          <Calendar
            className={styles.calendar}
            fullscreen={false}
            fullCellRender={renderDateCell}
            headerRender={renderCalendarHeader}
            onSelect={onDateSelected}
            value={selectedDate}
          />
          <Row style={{ marginBottom: 15, justifyContent: 'center' }}>
            <SymptomTrackingReminder />
          </Row>
          <Row>
            <h3 className={styles.graphTitle}>{t('symptomTracking.graphs.generalState')}</h3>
            <LinearCalendarChart
              dayLabels={displayedDays}
              dayValues={getStatusValueForDisplayedDays('general_state', displayedDays)}
              range={[0, 4]}
            />
          </Row>
          <Row>
            <h3 className={styles.graphTitle}>{t('symptomTracking.graphs.menstruation')}</h3>
            <LinearCalendarChart
              dayLabels={displayedDays}
              dayValues={getStatusValueForDisplayedDays('menstruation_amount', displayedDays)}
              range={[0, 4]}
              reverseColors
            />
          </Row>
          <Row>
            <h3 className={styles.graphTitle}>{t('symptomTracking.graphs.pain')}</h3>
            <LinearCalendarChart
              dayLabels={displayedDays}
              dayValues={getStatusValueForDisplayedDays('pain_level', displayedDays)}
              range={[0, 4]}
              reverseColors
            />
          </Row>
          <Row>
            <h3 className={styles.graphTitle}>{t('symptomTracking.graphs.symptoms')}</h3>
            <SymptomsChart
              dayLabels={displayedDays}
              dayValues={getStatusValueForDisplayedDays('symptoms', displayedDays, [])}
            />
          </Row>
          <Row>
            <h3 className={styles.graphTitle}>{t('symptomTracking.graphs.notes')}</h3>
            <CalendarTabs dailyStatuses={displayedDays.map(getSpecificDailyStatus).filter(d => d !== undefined)} />
          </Row>
          {!isTheredataForToday && <div className={styles.emptySpaceBehindSurveyButton} />}
        </div>
        {!isTheredataForToday && (
          <div className={styles.fillSurveyButton}>
            <GGButton
              type="primary"
              size="large"
              block
              onClick={() => {
                navigate(routes.symptomTrackingSurvey.route);
                amplitude.track('tracking.survey.started', {
                  source: 'daily_button',
                });
                posthog.capture('tracking.survey.started', {
                  source: 'daily_button',
                });
              }}
            >
              {t('symptomTracking.actions.fillDailySurvey')}
            </GGButton>
          </div>
        )}
      </div>
      <Modal open={specificDay !== undefined} onCancel={() => setSpecificDay(undefined)} footer={<span />}>
        <h2 className={styles.specificDayModalTitle}>{specificDay?.format('LL')}</h2>
        {specificDayData === undefined && (
          <>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('symptomTracking.noDataForDay')} />
            <GGButton
              type="primary"
              size="large"
              block
              onClick={() => {
                navigate({
                  pathname: routes.symptomTrackingSurvey.route,
                  search: createSearchParams({
                    date: (specificDay as Dayjs).format('YYYY-MM-DD'),
                  }).toString(),
                });
                amplitude.track('tracking.survey.started', {
                  source:
                    specificDay?.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
                      ? 'calendar_today'
                      : 'calendar_past_day',
                });
                posthog.capture('tracking.survey.started', {
                  source:
                    specificDay?.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
                      ? 'calendar_today'
                      : 'calendar_past_day',
                });
              }}
            >
              {t('symptomTracking.actions.fillDailySurvey')}
            </GGButton>
          </>
        )}
        {specificDayData !== undefined && (
          <div>
            {
              // TODO this is not so good
            }
            <p className={styles.specificDayModalDescription}>{t('État général')}</p>
            <p
              className={styles.specificDayModalBigValue}
              style={{ color: GENERAL_STATE_MAP[specificDayData?.general_state].color }}
            >
              {GENERAL_STATE_MAP[specificDayData?.general_state].value}
            </p>
            <p className={styles.specificDayModalDescription}>{t('Menstruations')}</p>
            <p
              className={styles.specificDayModalBigValue}
              style={{ color: MENSTRUATION_MAP[specificDayData?.menstruation_amount].color }}
            >
              {MENSTRUATION_MAP[specificDayData?.menstruation_amount].value}
              {Array.from({ length: specificDayData?.menstruation_amount }).map((_, i) => (
                <Drop key={i} fill={AppColors.colorMap.veryBad} />
              ))}
            </p>
            <p className={styles.specificDayModalDescription}>{t('Douleurs')}</p>
            <p
              className={styles.specificDayModalBigValue}
              style={{ color: PAIN_MAP[specificDayData?.pain_level].color }}
            >
              {PAIN_MAP[specificDayData?.pain_level].value}
            </p>
            <p className={styles.specificDayModalDescription}>{t('Symptômes')}</p>
            <p className={styles.specificDayModalValue}>
              {specificDayData?.symptoms.length > 0
                ? specificDayData?.symptoms.map(symptom => <SymptomTag key={symptom} symptom={symptom} />)
                : 'Aucun symptômes renseignés.'}
            </p>
            {specificDayData?.extra_symptoms && (
              <>
                <p className={styles.specificDayModalDescription}>{t('symptomTracking.graphs.otherSymptoms')}</p>
                <p className={styles.specificDayModalValue}>{specificDayData?.extra_symptoms}</p>
              </>
            )}
            {specificDayData?.comment && (
              <>
                <p className={styles.specificDayModalDescription}>{t('symptomTracking.graphs.notes')}</p>
                <p className={styles.specificDayModalValue}>{specificDayData?.comment}</p>
              </>
            )}
            <GGButton
              type="primary"
              size="large"
              block
              style={{ marginTop: 16 }}
              onClick={() => {
                navigate({
                  pathname: routes.symptomTrackingSurvey.route,
                  search: createSearchParams({
                    date: (specificDay as Dayjs).format('YYYY-MM-DD'),
                    edit: 'true',
                  }).toString(),
                });
                amplitude.track('tracking.survey.edit_started', {
                  source:
                    specificDay?.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
                      ? 'calendar_today'
                      : 'calendar_past_day',
                });
                posthog.capture('tracking.survey.edit_started', {
                  source:
                    specificDay?.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
                      ? 'calendar_today'
                      : 'calendar_past_day',
                });
              }}
            >
              {t('symptomTracking.actions.editDailySurvey')}
            </GGButton>
          </div>
        )}
      </Modal>
    </>
  );
}
