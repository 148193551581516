/* eslint-disable global-require */
import * as amplitude from '@amplitude/analytics-browser';
import { useRequest } from 'ahooks';
import { Carousel, Col, Form, Input, Progress, Radio, Row, Select, Typography } from 'antd';
import GGButton from 'components/buttons/GGButton';
import AppColors from 'config/AppColors';
import posthog from 'posthog-js';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiSymptomTrackingManager from 'services/api/ApiSymptomTrackingManager';
import { OnboardingSurvey, PatientFile } from 'services/api/models/SymptomTrackingDTO';
import { routes } from 'services/RouteService';
import { selectCurrentUser } from 'store/users/selectors';

const { Title } = Typography;

const useStyles = createUseStyles({
  carouselContainer: {
    // This looks stupid, but if you find something better, please let me know!
    height: 'calc(100dvh - env(safe-area-inset-top))',
    '& .ant-carousel': {
      height: '100%',
      '& .slick-slider': {
        height: '100%',
        '& .slick-list': {
          height: '100%',
          '& .slick-track': {
            height: '100%',
            '& .slick-slide': {
              height: '100%',
            },
            '& .slick-slide > div': {
              height: '100%',
            },
          },
        },
      },
    },
  },
  carousel: {
    backgroundColor: AppColors.main,
  },
  carouselPage: {
    height: '100%',
    overflow: 'scroll',
    paddingBottom: 64,
  },
  carouselPageSection: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,

    '& h3,p': { color: 'white' },
  },
  carouselPageSectionFullPageNoGrow: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: '10px 10px 64px 10px',
    flex: 0,

    '& .ant-row': {
      width: '100%',
      padding: '10px 16px',
    },
  },
  titleEmphasis: {
    color: AppColors.backgroundLightGreen,
  },
  dots: {
    bottom: '20px !important',

    '& li > button': {
      backgroundColor: `${AppColors.backgroundDarkGrey} !important`,
    },
    '& li.slick-active > button': {
      backgroundColor: `${AppColors.main} !important`,
    },
  },
  answersContainer: {
    display: 'flex',
    maxWidth: '80%',
    margin: 'auto',
    flexDirection: 'column',
    '& h5': {
      color: 'white',
      marginTop: 16,
    },
    '& button': {
      height: 50,
      marginBottom: 20,
    },
    '& .anticon': {
      fontSize: 24,
    },

    '& .ant-btn-dashed': {
      backgroundColor: `${AppColors.backgroundDarkGreen} !important`,
      color: `white !important`,
    },
    '& .ant-btn-text': {
      color: `white !important`,
      height: 30,
    },
    '& .ant-btn-primary': {
      backgroundColor: `white !important`,
      color: `${AppColors.colorTextBase} !important`,
    },
    '& p': {
      color: 'white',
    },
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',

    '& p': {
      color: 'white',
      marginBottom: 4,
    },

    '& .ant-form': {
      width: '100%',
    },

    '& .ant-radio-group': {
      width: '100%',
      '& .ant-radio-button-wrapper:not(:first-child)::before': {
        backgroundColor: AppColors.main,
        width: 2,
      },
    },
    '& .ant-radio-button-wrapper': {
      border: 'none',
      backgroundColor: AppColors.backgroundDarkGreen,
      color: 'white',
      width: '50%',
      borderInlineStart: 'none',
    },
    '& .ant-radio-button-wrapper-checked': {
      backgroundColor: 'white !important',
      color: `${AppColors.colorTextBase} !important`,
    },
  },
  postFileDescription: {
    fontSize: 17,
    margin: 0,
  },
  callToActionHelper: {
    fontSize: 12,
    marginBottom: 4,
  },
  yearSelect: {
    width: '100%',
    height: 40,
  },
  verticalSpace: {
    height: 16,
    width: '100%',
  },
  answerButtonWithIconText: {
    flex: 1,
  },
  infoText: {
    color: 'white',
    '& button': {
      marginTop: 15,
    },
  },
  symptomsAnswersContainer: {
    display: 'flex',
    maxWidth: '80%',
    margin: 'auto',
    justifyContent: 'space-around',

    '& button': {
      height: 50,
      marginBottom: 20,
      lineHeight: 1.3,
    },
    '& .ant-btn-dashed': {
      backgroundColor: `${AppColors.backgroundDarkGreen} !important`,
      color: `white !important`,
    },
    '& .ant-btn-primary': {
      backgroundColor: `white !important`,
      color: `${AppColors.main} !important`,
    },
  },
  freeTextFieldForm: {
    width: '100%',
    '& textarea': {
      backgroundColor: AppColors.backgroundMidGreen,
      height: 150,
    },
  },
  spinnerContainer: {
    '& .ant-spin-dot': {
      color: 'white',
    },
  },
});

const useMultiSelect = () => {
  const [values, setValues] = useState(new Set<string>());

  function isValueSelected(value: string) {
    return values.has(value);
  }

  function toggleValue(symptom: string) {
    if (isValueSelected(symptom)) setValues(oldValues => new Set([...oldValues].filter(item => item !== symptom)));
    else setValues(oldValues => new Set([...oldValues, symptom]));
  }

  function renderButton(valueKey: string, label: string, styleOverride?: React.CSSProperties) {
    return (
      <GGButton
        key={valueKey}
        style={{ width: '40%', textWrap: 'wrap', ...styleOverride }}
        type={isValueSelected(valueKey) ? 'primary' : 'dashed'}
        onClick={() => toggleValue(valueKey)}
      >
        {label}
      </GGButton>
    );
  }

  return { values, renderButton };
};

const SymptomTrackingSurvey: React.FC = () => {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = useStyles();
  const currentUser = useSelector(selectCurrentUser);

  const carousel = useRef(null);

  const symptomsOptions = ['FATIGUE', 'DIGESTIVE', 'URINARY', 'SEXUAL', 'BLEEDING', 'MOOD', 'MENTAL', 'DIZZINESS'];
  const pathologiesOptions = [
    'ENDOMETRIOSIS',
    'SOPK',
    'FIBROME',
    'ADENOMYOMATOSIS',
    'BREAST_CANCER',
    'UTERUS_CANCER',
    'PMS',
  ];
  const expectationsOptions = [
    'ADVICE',
    'ANSWERS',
    'SPEAK_TO_SPECIALIST',
    'UNDERSTAND_SYMPTOMS',
    'SECOND_OPINION',
    'RELIABLE_INFORMATION',
  ];
  const questionsOptions = [
    'CONTRACEPTION',
    'MENSTRUATION',
    'FERTILITY',
    'MENOPAUSE',
    'PATHOLOGIES',
    'TREATMENTS',
    'SEXUALITY',
  ];

  const { values: symptomsValues, renderButton: renderSymptomButton } = useMultiSelect();
  const { values: pathologiesValues, renderButton: renderPathologyButton } = useMultiSelect();
  const { values: expectationsValues, renderButton: renderReasonToJoinButton } = useMultiSelect();
  const { values: questionsValues, renderButton: renderQuestionButton } = useMultiSelect();

  const [hasPainOrDiscomfort, setHasPainOrDiscomfort] = useState<undefined | boolean>(undefined);
  const [showExtraSymptomsField, setShowExtraSymptomsField] = useState(false);
  const [showPathologiesField, setShowPathologiesField] = useState(false);
  const [showReasonToJoinField, setShowReasonToJoinField] = useState(false);
  const [showQuestionsField, setShowQuestionsField] = useState(false);

  const [extraSymptomsForm] = Form.useForm();
  const extraSymptomsValue = Form.useWatch('symptoms', extraSymptomsForm);
  const [pathologiesForm] = Form.useForm();
  const extraPathologiesValue = Form.useWatch('pathologies', pathologiesForm);
  const [extraExpectationForm] = Form.useForm();
  const extraExpectationValue = Form.useWatch('reasonToJoin', extraExpectationForm);
  const [questionsForm] = Form.useForm();
  const extraQuestionsValue = Form.useWatch('questions', questionsForm);

  const nextPage = () => {
    (carousel?.current as any)?.next();
  };

  const { loading: postingOnboardingSurvey, run: postOnboardingSurvey } = useRequest(
    () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const params: OnboardingSurvey = {
        feels_pain_or_discomfort: hasPainOrDiscomfort === undefined ? false : hasPainOrDiscomfort,
        symptoms: Array.from(symptomsValues),
        extra_symptoms: extraSymptomsValue,
        pathologies: Array.from(pathologiesValues),
        extra_pathologies: extraPathologiesValue,
        expectations: Array.from(expectationsValues),
        extra_expectations: extraExpectationValue,
        questions: Array.from(questionsValues),
        extra_questions: extraQuestionsValue,
      };
      return ApiSymptomTrackingManager.postOnboardingSurvey(params);
    },
    {
      manual: true,
      loadingDelay: 300,
      onError: e => {
        // TODO error handling
        console.error(e);
      },
    },
  );

  const { loading: postingPatientFile, run: postPatientFile } = useRequest(
    (formValues: PatientFile) => {
      return ApiSymptomTrackingManager.postPatientFile(formValues);
    },
    {
      manual: true,
      loadingDelay: 300,
    },
  );

  const renderProgress = (step: number) => {
    return (
      <Progress
        strokeColor="white"
        trailColor={AppColors.backgroundDarkGreen}
        percent={100 * (step / 7)}
        showInfo={false}
      />
    );
  };

  const carouselPages = [
    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              <Title level={3}>
                {t('onboarding.questionnaire.greeting')}{' '}
                <span className={styles.titleEmphasis}>{currentUser?.first_name}</span>, <br />
                <br />
              </Title>

              <p>{t('onboarding.questionnaire.description')}</p>
            </Col>
          </Row>
          <Row className={styles.answersContainer}>
            <GGButton onClick={() => nextPage()}>{t('onboarding.questionnaire.start')}</GGButton>
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              {renderProgress(1)}
              <Title level={3}>{t('onboarding.questionnaire.painQuestion')}</Title>
            </Col>
          </Row>
          <Row className={styles.answersContainer}>
            <GGButton
              type={hasPainOrDiscomfort === true ? 'primary' : 'dashed'}
              onClick={() => {
                setHasPainOrDiscomfort(true);
                nextPage();
              }}
            >
              {t('onboarding.questionnaire.yes')}
            </GGButton>
            <GGButton
              type={hasPainOrDiscomfort === false ? 'primary' : 'dashed'}
              onClick={() => {
                setHasPainOrDiscomfort(false);
                nextPage();
              }}
            >
              {t('onboarding.questionnaire.no')}
            </GGButton>
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              {renderProgress(2)}
              <Title level={3}>{t('onboarding.questionnaire.symptoms.question')}</Title>
              <p>{t('onboarding.questionnaire.symptoms.description')}</p>
            </Col>
          </Row>
          <Row className={styles.symptomsAnswersContainer}>
            {symptomsOptions.map(symptom =>
              renderSymptomButton(symptom, t(`symptomTracking.survey.symptoms.${symptom}`)),
            )}
            {showExtraSymptomsField && (
              <Form form={extraSymptomsForm} className={styles.freeTextFieldForm}>
                <Form.Item name="symptoms">
                  <Input.TextArea
                    variant="borderless"
                    autoCorrect="on"
                    placeholder={t('onboarding.questionnaire.symptoms.otherSymptomsPlaceholder')}
                  />
                </Form.Item>
              </Form>
            )}
            {!showExtraSymptomsField && (
              <GGButton type="dashed" onClick={() => setShowExtraSymptomsField(true)}>
                {t('onboarding.questionnaire.symptoms.otherSymptoms')}
              </GGButton>
            )}
          </Row>
          <Row className={styles.carouselPageSection}>
            <GGButton
              onClick={() => {
                nextPage();
              }}
            >
              {showExtraSymptomsField || symptomsValues.size > 0
                ? t('onboarding.questionnaire.symptoms.saveSymptoms')
                : t('onboarding.questionnaire.symptoms.noSymptoms')}
            </GGButton>
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              {renderProgress(3)}
              <Title level={3}>{t('onboarding.questionnaire.pathologies.question')}</Title>
            </Col>
          </Row>
          <Row className={styles.symptomsAnswersContainer}>
            {pathologiesOptions.map(pathology =>
              renderPathologyButton(pathology, t(`onboarding.questionnaire.pathologies.options.${pathology}`), {
                width: '100%',
              }),
            )}
            {showPathologiesField && (
              <Form form={pathologiesForm} className={styles.freeTextFieldForm}>
                <Form.Item name="pathologies">
                  <Input.TextArea
                    variant="borderless"
                    autoCorrect="on"
                    placeholder={t('onboarding.questionnaire.pathologies.otherPathologiesPlaceholder')}
                  />
                </Form.Item>
              </Form>
            )}
            {!showPathologiesField && (
              <GGButton type="dashed" onClick={() => setShowPathologiesField(true)}>
                {t('onboarding.questionnaire.pathologies.otherPathologies')}
              </GGButton>
            )}
          </Row>
          <Row className={styles.carouselPageSection}>
            <GGButton
              onClick={() => {
                nextPage();
              }}
            >
              {showPathologiesField || pathologiesValues.size > 0
                ? t('onboarding.questionnaire.pathologies.savePathologies')
                : t('onboarding.questionnaire.pathologies.noPathologies')}
            </GGButton>
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              {renderProgress(4)}
              <Title level={3}>{t('onboarding.questionnaire.expectations.question')}</Title>
            </Col>
          </Row>
          <Row className={styles.symptomsAnswersContainer}>
            {expectationsOptions.map(expectation =>
              renderReasonToJoinButton(expectation, t(`onboarding.questionnaire.expectations.options.${expectation}`), {
                width: '100%',
              }),
            )}
            {showReasonToJoinField && (
              <Form form={extraExpectationForm} className={styles.freeTextFieldForm}>
                <Form.Item name="reasonToJoin">
                  <Input.TextArea
                    variant="borderless"
                    autoCorrect="on"
                    placeholder={t('onboarding.questionnaire.expectations.otherExpectationsPlaceholder')}
                  />
                </Form.Item>
              </Form>
            )}
            {!showReasonToJoinField && (
              <GGButton type="dashed" onClick={() => setShowReasonToJoinField(true)}>
                {t('onboarding.questionnaire.expectations.otherExpectations')}
              </GGButton>
            )}
          </Row>
          <Row className={styles.carouselPageSection}>
            <GGButton
              onClick={() => {
                nextPage();
              }}
            >
              {showReasonToJoinField || expectationsValues.size > 0
                ? t('onboarding.questionnaire.expectations.saveExpectations')
                : t('onboarding.questionnaire.expectations.noExpectations')}
            </GGButton>
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              {renderProgress(5)}
              <Title level={3}>{t('onboarding.questionnaire.questions.question')}</Title>
            </Col>
          </Row>
          <Row className={styles.symptomsAnswersContainer}>
            {questionsOptions.map(question =>
              renderQuestionButton(question, t(`onboarding.questionnaire.questions.options.${question}`)),
            )}
            {showQuestionsField && (
              <Form form={questionsForm} className={styles.freeTextFieldForm}>
                <Form.Item name="questions">
                  <Input.TextArea
                    variant="borderless"
                    autoCorrect="on"
                    placeholder={t('onboarding.questionnaire.questions.otherQuestionsPlaceholder')}
                  />
                </Form.Item>
              </Form>
            )}
            {!showQuestionsField && (
              <>
                <div className={styles.verticalSpace} />
                <GGButton type="dashed" onClick={() => setShowQuestionsField(true)}>
                  {t('onboarding.questionnaire.questions.otherQuestions')}
                </GGButton>
              </>
            )}
          </Row>
          <Row className={styles.carouselPageSection}>
            <GGButton
              loading={postingOnboardingSurvey}
              onClick={() => {
                postOnboardingSurvey();
                amplitude.track('onboarding_questionnaire.onboarding_survey_submitted');
                posthog.capture('onboarding_questionnaire.onboarding_survey_submitted');
                nextPage();
              }}
            >
              {showQuestionsField || questionsValues.size > 0
                ? t('onboarding.questionnaire.questions.saveQuestions')
                : t('onboarding.questionnaire.questions.noQuestions')}
            </GGButton>
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              <div className={styles.verticalSpace} />
              <div className={styles.verticalSpace} />
              <Title level={3}>
                {t('onboarding.questionnaire.firstThankYou')},{' '}
                <span className={styles.titleEmphasis}>{currentUser?.first_name}</span> ! <br />
                <br />
              </Title>

              <p>{t('onboarding.questionnaire.postProductSurveyDescription')}</p>
            </Col>
          </Row>
          <Row className={styles.answersContainer}>
            <GGButton onClick={() => nextPage()}>{t('onboarding.questionnaire.continue')}</GGButton>
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            {renderProgress(6.5)}
            <Title level={3}>{t('onboarding.questionnaire.file.title')}</Title>
          </Row>
          <Row className={styles.fileContainer}>
            <Form
              onFinish={formValues => {
                postPatientFile(formValues);
                amplitude.track('onboarding_questionnaire.patient_file_submitted');
                posthog.capture('onboarding_questionnaire.patient_file_submitted');
                nextPage();
              }}
            >
              <p>{t('onboarding.questionnaire.file.birthYear')}</p>
              <Form.Item
                name="birth_year"
                rules={[{ required: true, message: t('onboarding.questionnaire.file.requiredField') }]}
              >
                <Select
                  className={styles.yearSelect}
                  showSearch
                  placeholder={t('onboarding.questionnaire.file.birthYearPlaceholder')}
                  optionFilterProp="label"
                  filterSort={(optionA: { label: string; value: string }, optionB: { label: string; value: string }) =>
                    (optionB?.label ?? '').toLowerCase().localeCompare((optionA?.label ?? '').toLowerCase())
                  }
                  options={Array.from({ length: new Date().getFullYear() - 1900 }, (_, index) => ({
                    label: `${1900 + index}`,
                    value: `${1900 + index}`,
                  }))}
                />
              </Form.Item>

              <p>{t('onboarding.questionnaire.file.doYouTakeContraception')}</p>
              <Form.Item
                name="takes_contraception"
                rules={[{ required: true, message: t('onboarding.questionnaire.file.requiredField') }]}
              >
                <Radio.Group
                  size="large"
                  options={[
                    { label: t('onboarding.questionnaire.yes'), value: true },
                    { label: t('onboarding.questionnaire.no'), value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>

              <p>{t('onboarding.questionnaire.file.doYouHaveChildren')}</p>
              <Form.Item
                name="has_children"
                rules={[{ required: true, message: t('onboarding.questionnaire.file.requiredField') }]}
              >
                <Radio.Group
                  size="large"
                  options={[
                    { label: t('onboarding.questionnaire.yes'), value: true },
                    { label: t('onboarding.questionnaire.no'), value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>

              <p>{t('onboarding.questionnaire.file.areYouPregnant')}</p>
              <Form.Item
                name="is_pregnant"
                rules={[{ required: true, message: t('onboarding.questionnaire.file.requiredField') }]}
              >
                <Radio.Group
                  size="large"
                  options={[
                    { label: t('onboarding.questionnaire.yes'), value: true },
                    { label: t('onboarding.questionnaire.no'), value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>

              <p>{t('onboarding.questionnaire.file.areYouTryingToConceive')}</p>
              <Form.Item
                name="is_trying_to_conceive"
                rules={[{ required: true, message: t('onboarding.questionnaire.file.requiredField') }]}
              >
                <Radio.Group
                  size="large"
                  options={[
                    { label: t('onboarding.questionnaire.yes'), value: true },
                    { label: t('onboarding.questionnaire.no'), value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>

              <p>{t('onboarding.questionnaire.file.areYouTakingAnyMedication')}</p>
              <Form.Item
                name="is_taking_treatment"
                rules={[{ required: true, message: t('onboarding.questionnaire.file.requiredField') }]}
              >
                <Radio.Group
                  size="large"
                  options={[
                    { label: t('onboarding.questionnaire.yes'), value: true },
                    { label: t('onboarding.questionnaire.no'), value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <div className={styles.verticalSpace} />
              <div className={styles.verticalSpace} />
              <GGButton size="large" block htmlType="submit" loading={postingPatientFile}>
                {t('onboarding.questionnaire.continue')}
              </GGButton>
            </Form>
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              <div className={styles.verticalSpace} />
              <div className={styles.verticalSpace} />
              <Title level={3}>
                {t('onboarding.questionnaire.congratulations')},{' '}
                <span className={styles.titleEmphasis}>{currentUser?.first_name}</span> ! <br />
              </Title>
              <div className={styles.verticalSpace} />
              <div className={styles.verticalSpace} />

              <p className={styles.postFileDescription}>
                <Trans i18nKey="onboarding.questionnaire.postFile.description" components={{ bold: <strong /> }} />
              </p>
            </Col>
          </Row>
          <Row className={styles.answersContainer}>
            <div className={styles.verticalSpace} />
            <div className={styles.verticalSpace} />
            <GGButton
              onClick={() => {
                navigate(routes.home.route);
              }}
            >
              {t('onboarding.questionnaire.postFile.letsGo')}
            </GGButton>
          </Row>
        </>
      ),
    },
  ];

  const renderOnboardingCarouselPageTemplate = (
    index: number,
    children: { content?: React.ReactElement; scrollableContent?: React.ReactElement },
  ) => {
    return (
      <div key={index} className={styles.carouselPage}>
        {children.content && <Row className={styles.carouselPageSectionFullPageNoGrow}>{children.content}</Row>}
      </div>
    );
  };

  return (
    <div className={styles.carouselContainer}>
      <Carousel ref={carousel} className={styles.carousel} infinite={false} dots={{ className: styles.dots }}>
        {carouselPages.map((page, index) => renderOnboardingCarouselPageTemplate(index, page))}
      </Carousel>
    </div>
  );
};

export default SymptomTrackingSurvey;
