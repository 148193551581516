/* eslint-disable global-require */
import * as amplitude from '@amplitude/analytics-browser';
import { CheckOutlined, CloseOutlined, FrownFilled, MehFilled, SmileFilled } from '@ant-design/icons';
import { Preferences } from '@capacitor/preferences';
import { useRequest } from 'ahooks';
import { Carousel, Col, Form, Input, Row, Spin, Typography } from 'antd';
import GGButton from 'components/buttons/GGButton';
import SymptomTrackingReminder from 'components/SymptomTrackingReminder';
import AppColors from 'config/AppColors';
import dayjs from 'dayjs';
import usePreferences from 'hooks/usePreferences';
import posthog from 'posthog-js';
import Keys from 'preferences/preferences';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ApiSymptomTrackingManager from 'services/api/ApiSymptomTrackingManager';
import { routes } from 'services/RouteService';
import { selectCurrentUser } from 'store/users/selectors';
import Platform from 'utils/Platform';
import Drop from '../assets/drop.svg?react';

const { Title } = Typography;

const useStyles = createUseStyles({
  carouselContainer: {
    // This looks stupid, but if you find something better, please let me know!
    height: '100%',
    '& .ant-carousel': {
      height: '100%',
      '& .slick-slider': {
        height: '100%',
        '& .slick-list': {
          height: '100%',
          '& .slick-track': {
            height: '100%',
            '& .slick-slide': {
              height: '100%',
            },
            '& .slick-slide > div': {
              height: '100%',
            },
          },
        },
      },
    },
  },
  carousel: {
    backgroundColor: AppColors.main,
  },
  carouselPage: {
    height: '100%',
    overflow: 'scroll',
  },
  carouselPageSection: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,

    '& h3,p': { color: 'white' },
  },
  carouselPageSectionFullPageNoGrow: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
    flex: 0,
  },
  titleEmphasis: {
    color: AppColors.backgroundLightGreen,
  },
  dots: {
    bottom: '20px !important',

    '& li > button': {
      backgroundColor: `${AppColors.backgroundDarkGrey} !important`,
    },
    '& li.slick-active > button': {
      backgroundColor: `${AppColors.main} !important`,
    },
  },
  answersContainer: {
    display: 'flex',
    maxWidth: '80%',
    margin: 'auto',
    flexDirection: 'column',
    '& h5': {
      color: 'white',
      marginTop: 16,
    },
    '& button': {
      height: 50,
      marginBottom: 20,
    },
    '& .anticon': {
      fontSize: 24,
    },

    '& .ant-btn-dashed': {
      backgroundColor: `${AppColors.backgroundDarkGreen} !important`,
      color: `white !important`,
    },
    '& .ant-btn-text': {
      color: `white !important`,
      height: 30,
    },
    '& .ant-btn-primary': {
      backgroundColor: `white !important`,
      color: `${AppColors.colorTextBase} !important`,
    },
  },
  verticalSpace: {
    height: 16,
  },
  answerButtonWithIconText: {
    flex: 1,
  },
  infoText: {
    color: 'white',
    '& button': {
      marginTop: 15,
    },
  },
  symptomsAnswersContainer: {
    display: 'flex',
    maxWidth: '80%',
    margin: 'auto',
    justifyContent: 'space-around',

    '& button': {
      height: 50,
      marginBottom: 20,
      lineHeight: 1.3,
    },
    '& .ant-btn-dashed': {
      backgroundColor: `${AppColors.backgroundDarkGreen} !important`,
      color: `white !important`,
    },
    '& .ant-btn-primary': {
      backgroundColor: `white !important`,
      color: `${AppColors.main} !important`,
    },
  },
  freeTextFieldForm: {
    width: '100%',
    '& textarea': {
      backgroundColor: AppColors.backgroundMidGreen,
      height: 150,
    },
  },
  spinnerContainer: {
    '& .ant-spin-dot': {
      color: 'white',
    },
  },
});

const SymptomTrackingSurvey: React.FC = () => {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = useStyles();
  const [urlParams] = useSearchParams();
  const currentUser = useSelector(selectCurrentUser);
  const today = new Date().toISOString().slice(0, 10);
  const date = urlParams.has('date') ? (urlParams.get('date') as string) : today;
  const isEditing = urlParams.has('edit') ? (urlParams.get('edit') as string) === 'true' : false;

  const [extraSymptomsForm] = Form.useForm();
  const extraSymptomsValue = Form.useWatch('symptoms', extraSymptomsForm);

  const [freeNotesForm] = Form.useForm();
  const freeNotes = Form.useWatch('notes', freeNotesForm);

  const carousel = useRef(null);
  const [survey, setSurvey] = useState({
    date,
    general_state: -1,
    menstruation_amount: -1,
    pain_level: -1,
    symptoms: new Set<string>([]),
  });

  const [showExtraSymptomsField, setShowExtraSymptomsField] = useState(false);
  const [askPeriod, setAskPeriod] = useState(false);
  const [showSkipPeriodWarning, setShowSkipPeriodWarning] = useState(false);

  const { value: reminderTime, loading: loadingReminderTime } = usePreferences(Keys.TRACKING_REMINDER_TIME);
  const areNotificationsEnabled = reminderTime.hour !== undefined;

  const { value: shouldAskAgainForReminder, set: setShouldAskForReminder } = usePreferences(
    Keys.TRACKING_SURVEY_SHOULD_ASK_FOR_REMINDER,
  );
  const shouldAskForReminder = shouldAskAgainForReminder !== false && !areNotificationsEnabled && Platform.isNative();

  const { data: shouldAskForPeriodPreference, loading: loadingSurveyPreferences } = useRequest(() =>
    Preferences.get({ key: Keys.TRACKING_SURVEY_SHOULD_ASK_FOR_PERIOD }),
  );
  const { run: storeShouldAskForPeriod } = useRequest(
    shouldAskForPeriod =>
      Preferences.set({
        key: Keys.TRACKING_SURVEY_SHOULD_ASK_FOR_PERIOD,
        value: shouldAskForPeriod ? 'true' : 'false',
      }),
    {
      manual: true,
    },
  );

  const shouldAskForPeriod = shouldAskForPeriodPreference?.value !== 'false';

  const invalidSurvey =
    survey.general_state === -1 ||
    survey.pain_level === -1 ||
    (shouldAskForPeriod && survey.menstruation_amount === -1);

  const { run: loadSurvey, loading: isLoadingSurveyData } = useRequest(
    () => ApiSymptomTrackingManager.getSpecificDailyStatus(date),
    {
      manual: true,
      onSuccess: data => {
        setSurvey({ ...data, date: data.date as string, symptoms: new Set(data.symptoms) });
        setAskPeriod(data.menstruation_amount > 0);
        freeNotesForm.setFieldsValue({ notes: data.comment });
        if (data.extra_symptoms) {
          extraSymptomsForm.setFieldsValue({ symptoms: data.extra_symptoms });
          setShowExtraSymptomsField(true);
        }
      },
    },
  );

  const nextPage = () => {
    (carousel?.current as any)?.next();
  };

  const { loading: postingSurvey, run: postSurvey } = useRequest(
    () => {
      const params = {
        ...survey,
        symptoms: Array.from(survey.symptoms),
        extra_symptoms: extraSymptomsValue,
        comment: freeNotes,
        menstruation_amount: shouldAskForPeriod ? survey.menstruation_amount : 0,
      };
      if (isEditing) return ApiSymptomTrackingManager.patchSurvey(params);
      return ApiSymptomTrackingManager.postSurvey(params);
    },
    {
      manual: true,
      onError: e => {
        console.error(e);
      },
    },
  );

  function chooseGeneralState(value: number) {
    setSurvey(s => ({ ...s, general_state: value }));
    amplitude.track('tracking.survey.general_state_chosen');
    posthog.capture('tracking.survey.general_state_chosen');
    nextPage();
  }

  function chooseMenstruationAmount(value: number) {
    setSurvey(s => ({ ...s, menstruation_amount: value }));
    nextPage();
    amplitude.track('tracking.survey.menstruation_amount_chosen');
    posthog.capture('tracking.survey.menstruation_amount_chosen');
  }

  function chooseIsInMenstruationPhase(value: 'yes' | 'no' | 'noperiod') {
    if (value === 'yes') {
      setAskPeriod(true);
      setShowSkipPeriodWarning(false);
    }
    if (value === 'no') {
      setAskPeriod(false);
      setShowSkipPeriodWarning(false);
      chooseMenstruationAmount(0);
    }
    if (value === 'noperiod') {
      setAskPeriod(false);
      setShowSkipPeriodWarning(true);
      amplitude.track('tracking.survey.no_menstruation_chosen');
      posthog.capture('tracking.survey.no_menstruation_chosen');
    }
    amplitude.track('tracking.survey.menstruation_phase_chosen');
    posthog.capture('tracking.survey.menstruation_phase_chosen');
  }

  function validateSkipPeriod() {
    storeShouldAskForPeriod(false);
    chooseMenstruationAmount(0);
    // TODO should we save this on the server as well ?
  }

  function choosePainAmount(value: number) {
    setSurvey(s => ({ ...s, pain_level: value }));
    nextPage();
    amplitude.track('tracking.survey.pain_level_chosen');
    posthog.capture('tracking.survey.pain_level_chosen');
  }

  function isSymptomSelected(symptom: string) {
    return survey.symptoms.has(symptom);
  }

  function toggleSymptom(symptom: string) {
    if (isSymptomSelected(symptom))
      setSurvey(oldSurvey => ({
        ...oldSurvey,
        symptoms: new Set([...oldSurvey.symptoms].filter(item => item !== symptom)),
      }));
    else setSurvey(oldSurvey => ({ ...oldSurvey, symptoms: new Set([...oldSurvey.symptoms, symptom]) }));
  }

  function renderSymptomButton(symptomKey: string, label: string) {
    return (
      <GGButton
        style={{ width: '40%', textWrap: 'wrap' }}
        type={isSymptomSelected(symptomKey) ? 'primary' : 'dashed'}
        onClick={() => toggleSymptom(symptomKey)}
      >
        {label}
      </GGButton>
    );
  }

  function renderGeneralStateButton(value: number, label: string) {
    const selected = survey.general_state;
    return (
      <GGButton
        onClick={() => chooseGeneralState(value)}
        type={selected !== -1 && selected !== value ? 'dashed' : 'primary'}
      >
        {value > 2 && <SmileFilled style={{ color: AppColors.colorMap[label as keyof typeof AppColors.colorMap] }} />}
        {value === 2 && <MehFilled style={{ color: AppColors.colorMap[label as keyof typeof AppColors.colorMap] }} />}
        {value < 2 && <FrownFilled style={{ color: AppColors.colorMap[label as keyof typeof AppColors.colorMap] }} />}
        <span className={styles.answerButtonWithIconText}>{t(`symptomTracking.survey.generalState.${label}`)}</span>
      </GGButton>
    );
  }

  function renderPainLevelButton(value: number, label: string) {
    const selected = survey.pain_level;
    return (
      <GGButton
        onClick={() => choosePainAmount(value)}
        type={selected !== -1 && selected !== value ? 'dashed' : 'primary'}
      >
        <span className={styles.answerButtonWithIconText}>{t(`symptomTracking.survey.pain.${label}`)}</span>
      </GGButton>
    );
  }

  function renderMenstruationAmountButton(value: number, label: string) {
    const selected = survey.menstruation_amount;
    return (
      <GGButton
        onClick={() => chooseMenstruationAmount(value)}
        type={selected !== -1 && selected !== value ? 'dashed' : 'primary'}
      >
        {Array.from({ length: value }).map((_, index) => (
          <Drop fill={AppColors.colorMap.veryBad} key={index} />
        ))}
        {Array.from({ length: 4 - value }).map((_, index) => (
          <Drop fill={AppColors.text.veryLightGray} key={index} />
        ))}
        <span className={styles.answerButtonWithIconText}>{t(`symptomTracking.survey.menstruation.${label}`)}</span>
      </GGButton>
    );
  }

  const menstruationConfirmationHighlighted = askPeriod || survey.menstruation_amount === -1 || showSkipPeriodWarning;
  const noMenstruationConfirmationHighlighted =
    !askPeriod && (survey.menstruation_amount === -1 || survey.menstruation_amount === 0 || showSkipPeriodWarning);

  const surveyPages = [
    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              <Title level={3}>
                {t('symptomTracking.survey.greeting')}{' '}
                <span className={styles.titleEmphasis}>{currentUser?.first_name}</span>, <br />
                <br />
                {date === today
                  ? t('symptomTracking.survey.howAreYouToday')
                  : t('symptomTracking.survey.howWereYou', { date: dayjs(date).format('LL') })}
              </Title>
            </Col>
          </Row>
          <Row className={styles.answersContainer}>
            {renderGeneralStateButton(4, 'veryGood')}
            {renderGeneralStateButton(3, 'good')}
            {renderGeneralStateButton(2, 'average')}
            {renderGeneralStateButton(1, 'bad')}
            {renderGeneralStateButton(0, 'veryBad')}
          </Row>
        </>
      ),
    },

    ...(loadingSurveyPreferences || loadingReminderTime ? [{}] : []),

    ...(shouldAskForPeriod
      ? [
          {
            content: (
              <>
                <Row className={styles.carouselPageSection}>
                  <Col>
                    <Title level={3}>{t('symptomTracking.survey.menstruation.periodQuestion')}</Title>
                  </Col>
                </Row>
                <Row className={styles.answersContainer}>
                  <GGButton
                    onClick={() => chooseIsInMenstruationPhase('yes')}
                    type={menstruationConfirmationHighlighted ? 'primary' : 'dashed'}
                  >
                    <Drop fill={AppColors.colorMap.veryBad} />
                    <span className={styles.answerButtonWithIconText}>
                      {t('symptomTracking.survey.menstruation.yes')}
                    </span>
                  </GGButton>
                  <GGButton
                    onClick={() => chooseIsInMenstruationPhase('no')}
                    type={noMenstruationConfirmationHighlighted ? 'primary' : 'dashed'}
                  >
                    <Drop fill={AppColors.text.veryLightGray} />
                    <span className={styles.answerButtonWithIconText}>
                      {t('symptomTracking.survey.menstruation.no')}
                    </span>
                  </GGButton>
                  <div className={styles.verticalSpace} />
                  <GGButton onClick={() => chooseIsInMenstruationPhase('noperiod')}>
                    <CloseOutlined />
                    <span className={styles.answerButtonWithIconText}>
                      {t('symptomTracking.survey.menstruation.noPeriod')}
                    </span>
                  </GGButton>
                  {showSkipPeriodWarning && (
                    <p className={styles.infoText}>
                      {t('symptomTracking.survey.menstruation.skipPeriodWarning')}
                      <br />
                      <GGButton onClick={() => validateSkipPeriod()}>
                        <CheckOutlined />
                        <span className={styles.answerButtonWithIconText}>
                          {t('symptomTracking.survey.menstruation.understood')}
                        </span>
                      </GGButton>
                    </p>
                  )}
                </Row>
                {askPeriod && (
                  <Row className={styles.answersContainer}>
                    <Title level={5}>{t('symptomTracking.survey.menstruation.menstruationAmount')}</Title>
                    {renderMenstruationAmountButton(1, 'low')}
                    {renderMenstruationAmountButton(2, 'medium')}
                    {renderMenstruationAmountButton(3, 'high')}
                    {renderMenstruationAmountButton(4, 'veryHigh')}
                  </Row>
                )}
              </>
            ),
          },
        ]
      : []),

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              <Title level={3}>{t('symptomTracking.survey.pain.painQuestion')}</Title>
            </Col>
          </Row>
          <Row className={styles.answersContainer}>
            {renderPainLevelButton(0, 'none')}
            {renderPainLevelButton(1, 'light')}
            {renderPainLevelButton(2, 'moderate')}
            {renderPainLevelButton(3, 'strong')}
            {renderPainLevelButton(4, 'intense')}
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              <Title level={3}>{t('symptomTracking.survey.symptoms.otherSymptoms')}</Title>
            </Col>
          </Row>
          <Row className={styles.symptomsAnswersContainer}>
            {renderSymptomButton('FATIGUE', t('symptomTracking.survey.symptoms.FATIGUE'))}
            {renderSymptomButton('DIGESTIVE', t('symptomTracking.survey.symptoms.DIGESTIVE'))}
            {renderSymptomButton('URINARY', t('symptomTracking.survey.symptoms.URINARY'))}
            {renderSymptomButton('SEXUAL', t('symptomTracking.survey.symptoms.SEXUAL'))}
            {renderSymptomButton('BLEEDING', t('symptomTracking.survey.symptoms.BLEEDING'))}
            {renderSymptomButton('MOOD', t('symptomTracking.survey.symptoms.MOOD'))}
            {renderSymptomButton('MENTAL', t('symptomTracking.survey.symptoms.MENTAL'))}
            {renderSymptomButton('DIZZINESS', t('symptomTracking.survey.symptoms.DIZZINESS'))}
            {showExtraSymptomsField && (
              <Form form={extraSymptomsForm} className={styles.freeTextFieldForm}>
                <Form.Item name="symptoms">
                  <Input.TextArea
                    variant="borderless"
                    autoCorrect="on"
                    placeholder={t('symptomTracking.survey.symptoms.otherSymptomsDescription')}
                  />
                </Form.Item>
              </Form>
            )}
            {!showExtraSymptomsField && (
              <GGButton onClick={() => setShowExtraSymptomsField(true)}>
                {t('symptomTracking.survey.symptoms.other')}
              </GGButton>
            )}
          </Row>
          <Row className={styles.carouselPageSection}>
            <GGButton
              onClick={() => {
                nextPage();
                amplitude.track('tracking.survey.symptoms_chosen');
                posthog.capture('tracking.survey.symptoms_chosen');
              }}
            >
              {showExtraSymptomsField || survey.symptoms.size > 0
                ? t('symptomTracking.survey.symptoms.saveSymptoms')
                : t('symptomTracking.survey.symptoms.noSymptoms')}
            </GGButton>
          </Row>
        </>
      ),
    },

    {
      content: (
        <>
          <Row className={styles.carouselPageSection}>
            <Col>
              <Title level={3}>{t('symptomTracking.survey.addComment')}</Title>
            </Col>
          </Row>
          <Row className={styles.symptomsAnswersContainer}>
            <Form form={freeNotesForm} className={styles.freeTextFieldForm}>
              <Form.Item name="notes">
                <Input.TextArea variant="borderless" placeholder={t('symptomTracking.survey.freeTextDescription')} />
              </Form.Item>
            </Form>
            {invalidSurvey && <p className={styles.infoText}>{t('symptomTracking.survey.missingAnswers')}</p>}
            <GGButton
              loading={postingSurvey}
              disabled={invalidSurvey}
              onClick={() => {
                postSurvey();
                amplitude.track('tracking.survey.completed');
                posthog.capture('tracking.survey.completed');
                if (!shouldAskForReminder) navigate(routes.symptomTracking.route);
                else nextPage();
              }}
            >
              {t('symptomTracking.survey.saveFollowUp')}
            </GGButton>
          </Row>
        </>
      ),
    },

    ...(shouldAskForReminder
      ? [
          {
            content: (
              <>
                <Row className={styles.carouselPageSection}>
                  <Col>
                    <Title level={3}>{t('symptomTracking.survey.reminder.question')}</Title>
                  </Col>
                  <p>{t('symptomTracking.survey.reminder.explain')}</p>
                </Row>
                <div className={styles.verticalSpace} />
                <Row className={styles.answersContainer}>
                  <SymptomTrackingReminder
                    isCallToAction
                    onReminderEnabled={() => navigate(routes.symptomTracking.route)}
                  />
                  <GGButton
                    type="dashed"
                    onClick={() => {
                      amplitude.track('tracking.reminder_setting_deferred');
                      posthog.capture('tracking.reminder_setting_deferred');
                      navigate(routes.symptomTracking.route);
                    }}
                  >
                    {t('symptomTracking.survey.reminder.noThanks')}
                  </GGButton>
                  <GGButton
                    type="text"
                    onClick={() => {
                      amplitude.track('tracking.reminder_setting_refused');
                      posthog.capture('tracking.reminder_setting_refused');
                      setShouldAskForReminder(false);
                      navigate(routes.symptomTracking.route);
                    }}
                  >
                    {t('symptomTracking.survey.reminder.doNotAskAgain')}
                  </GGButton>
                </Row>
              </>
            ),
          },
        ]
      : []),
  ];

  const carouselPages =
    isEditing && isLoadingSurveyData
      ? [
          {
            content: (
              <Row className={styles.carouselPageSection}>
                <Col>
                  <Title level={3}>{t('symptomTracking.survey.loading')}</Title>
                  <Title level={3} className={styles.spinnerContainer}>
                    <Spin size="large" />
                  </Title>
                </Col>
              </Row>
            ),
          },
        ]
      : surveyPages;

  const renderOnboardingCarouselPageTemplate = (
    index: number,
    children: { content?: React.ReactElement; scrollableContent?: React.ReactElement },
  ) => {
    return (
      <div key={index} className={styles.carouselPage}>
        {children.content && (
          <Row className={styles.carouselPageSectionFullPageNoGrow}>
            <Col>{children.content}</Col>
          </Row>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (isEditing) loadSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.carouselContainer}>
      <Carousel ref={carousel} className={styles.carousel} infinite={false} dots={{ className: styles.dots }}>
        {carouselPages.map((page, index) => renderOnboardingCarouselPageTemplate(index, page))}
      </Carousel>
    </div>
  );
};

export default SymptomTrackingSurvey;
