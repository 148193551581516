import { ConversationMessage, ConversationStatus } from './api/models/ConversationDTO';
import { UserDTO } from './api/models/UserDTO';

export type WebSocketDataChatMessage = {
  message_text: string;
  // The sender id
  sender: {
    display_name: string;
    first_name: string;
    id: string;
    is_caregiver: boolean;
    photo_url: string;
    number_of_conversations_as_patient: number;
  };
  sent_at: string;
  // The conversation id
  conversation: string;
};
export type WebSocketDataReadNotification = {
  conversation: string;
  reader: string;
  last_read_datetime: string;
};
export type WebSocketDataConversationRequestToGroup = {
  current_owner: UserDTO;
  id: string;
  last_message: ConversationMessage;
  patient: UserDTO;
  number_of_unread_messages: number;
  status: ConversationStatus;
};
export type WebSocketDataConversationAssigned = {
  id: string; // Id of the conversation
  new_owner: string; // Id of the user
};
export type WebSocketDataConversationStatusUpdated = {
  id: string;
  new_status: string;
};

export type WebSocketData = {
  type:
    | 'chat_message'
    | 'unassigned_message'
    | 'conversation_assigned'
    | 'conversation.assignment.asked_group'
    | 'conversation.read_notification'
    | 'conversation.status.updated';
  payload:
    | WebSocketDataChatMessage
    | WebSocketDataConversationAssigned
    | WebSocketDataConversationRequestToGroup
    | WebSocketDataConversationStatusUpdated
    | WebSocketDataReadNotification;
};

export type WebSocketServiceCtorParams = {
  accessToken?: string;
  onError?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
  onMessage?: (data: WebSocketData) => void;
};

const WEB_SOCKET_RECONNECT_TIMEOUT_MS = 30000;

export default class WebSocketService {
  private static instance: WebSocketService;

  private chatSocket?: WebSocket;

  private static timeouts: NodeJS.Timeout[] = [];

  private constructor(params: WebSocketServiceCtorParams) {
    this.chatSocket = this.connectWebSocket(params);
  }

  // eslint-disable-next-line class-methods-use-this
  protected connectWebSocket(params: WebSocketServiceCtorParams) {
    if (WebSocketService.instance && WebSocketService.instance.chatSocket) {
      return WebSocketService.instance.chatSocket;
    }

    const wsUrl = import.meta.env.VITE_WEB_SOCKET_URL;

    const chatSocket = new WebSocket(`${wsUrl}?token=${params.accessToken}`);

    chatSocket.onopen = () => {
      if (params.onOpen) {
        params.onOpen();
      }
    };

    chatSocket.onerror = () => {
      if (params.onError) {
        params.onError();
      }

      chatSocket.close();
    };

    chatSocket.onclose = () => {
      if (params.onClose) {
        params.onClose();
      }
    };

    // Reconnect if the Web Socket connection is lost
    const timeout = setInterval(() => {
      if (
        WebSocketService.instance &&
        WebSocketService.instance.chatSocket &&
        WebSocketService.instance.chatSocket.readyState === WebSocketService.instance.chatSocket.CLOSED
      ) {
        WebSocketService.getNewInstance(params);
      }
    }, WEB_SOCKET_RECONNECT_TIMEOUT_MS);
    WebSocketService.timeouts = Array.from(WebSocketService.timeouts.concat(timeout));

    chatSocket.onmessage = event => {
      const data = JSON.parse(event.data);

      if (params.onMessage) {
        params.onMessage(data);
      }
    };

    return chatSocket;
  }

  static getInstance = (params: WebSocketServiceCtorParams): WebSocketService => {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService(params);
    }

    return WebSocketService.instance;
  };

  static getNewInstance = (params: WebSocketServiceCtorParams): WebSocketService => {
    // Close previous Web Socket connection
    WebSocketService.close();

    WebSocketService.instance = new WebSocketService(params);

    return WebSocketService.instance;
  };

  static close = () => {
    if (WebSocketService.timeouts && WebSocketService.timeouts.length > 0) {
      WebSocketService.timeouts.forEach(timeout => clearInterval(timeout));
      WebSocketService.timeouts = [];
    }

    if (WebSocketService.instance) {
      if (
        WebSocketService.instance.chatSocket &&
        WebSocketService.instance.chatSocket.readyState === WebSocketService.instance.chatSocket.OPEN
      ) {
        WebSocketService.instance.chatSocket.close();
      }
      WebSocketService.instance.chatSocket = undefined;
    }
  };
}
