import { ApiManager } from './ApiManager';
import { PostDTO, TagDTO } from './models/PostDTO';

export default class ApiContentManager {
  static async getPosts(): Promise<PostDTO[]> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/content/posts/`);
    return response.data;
  }

  static async getTags(): Promise<TagDTO[]> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/content/tags/`);
    return response.data;
  }

  static async getPost(id: string): Promise<PostDTO> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/content/posts/${id}/`);
    return response.data;
  }

  static async searchPosts(query: string | undefined, tags: string | undefined): Promise<PostDTO[]> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/content/posts/`, {
      params: { search: query, tags },
    });
    return response.data;
  }

  static async getRecentlyReadPosts(): Promise<PostDTO[]> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/content/posts/recently-read/`);
    return response.data;
  }
}
