/* eslint-disable global-require */
import * as amplitude from '@amplitude/analytics-browser';
import { useRequest } from 'ahooks';
import { Card, Col, Divider, Form, List, Row, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import useNotification from 'antd/es/notification/useNotification';
import GGButton from 'components/buttons/GGButton';
import HelperCard from 'components/cards/HelperCard';
import PageTopBar from 'components/navigation/PageTopBar';
import AppColors from 'config/AppColors';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import posthog from 'posthog-js';
import Keys from 'preferences/preferences';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { departments } from 'services/FrenchDepartmentsService';
import ApiCaregiversManager, {
  ApiCaregiversManagerGetCaregiversDirectoryParams,
} from 'services/api/ApiCaregiversManager';
import { CaregiverDTO } from 'services/api/models/CaregiverDTO';
import { CaregiverGroupDTO } from 'services/api/models/CaregiverGroupDTO';
import ScrollUtil from 'utils/ScrollUtil';

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  container: {
    height: '100%',
    padding: '8px 8px 0px',
    backgroundColor: AppColors.backgroundGrey,
    overflowY: 'scroll',
  },
  filterSelect: {
    height: 48,
    marginBottom: -15,
  },
  list: {
    padding: 0,
    width: '100%',
  },
  item: {},
  detailsContainer: {
    display: 'flex',
    backgroundColor: AppColors.card.background,
    borderRadius: 8,
    flex: 1,
    flexDirection: 'column',
    padding: 8,
  },
  expertise: {
    fontStyle: 'italic',
  },
  address: {},
  phone: {},
});

export default function CaregiversDirectoryPage() {
  // Hooks
  const styles = useStyles();
  const [api, context] = useNotification();
  const { t } = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const [form] = useForm();
  const ref = useRef(null);
  const navigate = useNavigate();

  function scrollToTop() {
    ScrollUtil.scrollToTop(ref.current);
  }

  const {
    loading: loadingGetCaregiverGroupsList,
    data: caregiverGroups,
    run: getCaregiverGroupsList,
  } = useRequest(() => ApiCaregiversManager.getCaregiverGroupsList(), {
    manual: true,
    cacheKey: 'getCaregiverGroupsList',
    onSuccess: () => {
      scrollToTop();
    },
    onError: () => {
      api.error({
        message: t('caregivers.list.errors.fetch'),
      });
    },
  });

  useEffect(() => {
    if (!caregiverGroups && !loadingGetCaregiverGroupsList) {
      getCaregiverGroupsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, data, run } = useRequest(
    (params?: ApiCaregiversManagerGetCaregiversDirectoryParams) => ApiCaregiversManager.getCaregiversDirectory(params),
    {
      cacheKey: 'experts-directory',
      onError: () => {
        api.error({
          message: t('caregiversDirectory.errors.list'),
        });
      },
    },
  );

  const onFinish = (values: any) => {
    amplitude.track('caregivers_directory.filters_applied', {
      caregiverGroupId: values.filterCaregiverGroupId,
      zipCode: values.filterZipCode,
    });
    posthog.capture('caregivers_directory.filters_applied', {
      caregiverGroupId: values.filterCaregiverGroupId,
      zipCode: values.filterZipCode,
    });
    run({
      ...(values.filterCaregiverGroupId && { caregiverGroupId: values.filterCaregiverGroupId }),
      ...(values.filterZipCode && { zipCode: values.filterZipCode }),
    });
  };

  const departmentOptions = departments.map(d => ({ label: `${d.number} - ${d.name}`, value: d.number }));
  const filterDepartments = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const displayAddressChunks = (caregiver: CaregiverDTO) =>
    caregiver.address_street || caregiver.address_zip_code || caregiver.address_city;
  const displayAddressChunksBreakline = (caregiver: CaregiverDTO) =>
    caregiver.address_street && (caregiver.address_zip_code || caregiver.address_city);

  return (
    <div className={styles.pageContainer}>
      <PageTopBar title={t('caregiversDirectory.title')} backButton onBackButtonClicked={() => navigate(-1)} />
      <div className={styles.container} ref={ref}>
        {context}

        <Row>
          <HelperCard
            text={t('caregiversDirectory.description')}
            shouldDisplayPreferencesKey={Keys.CAREGIVERS_DIRECTORY_SHOULD_DISPLAY_HELPER}
          />
          <Form
            form={form}
            layout={isSmallScreen ? 'horizontal' : 'inline'}
            onFinish={onFinish}
            style={{ marginTop: 16, width: '100%' }}
          >
            <Col span={isSmallScreen ? 24 : 6}>
              <Form.Item name="filterCaregiverGroupId">
                <Select
                  className={styles.filterSelect}
                  placeholder={t('caregiversDirectory.filters.caregiverGroup')}
                  loading={loadingGetCaregiverGroupsList}
                >
                  {caregiverGroups &&
                    caregiverGroups.map(caregiverGroup => (
                      <Select.Option key={caregiverGroup.id} value={caregiverGroup.id}>
                        {caregiverGroup.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={isSmallScreen ? 24 : 6}>
              <Form.Item name="filterZipCode">
                <Select
                  showSearch
                  className={styles.filterSelect}
                  placeholder={t('caregiversDirectory.filters.department')}
                  optionFilterProp="children"
                  filterOption={filterDepartments}
                  options={departmentOptions}
                />
              </Form.Item>
            </Col>

            <Col span={isSmallScreen ? 24 : 12}>
              <Space size="small">
                <GGButton type="primary" htmlType="submit" size="large">
                  {t('global.filters.search')}
                </GGButton>
                <GGButton
                  size="large"
                  onClick={() => {
                    form.resetFields();
                    run();
                  }}
                >
                  {t('global.filters.clear')}
                </GGButton>
              </Space>
            </Col>

            <Divider />
          </Form>
        </Row>

        <Row>
          <List
            dataSource={data}
            bordered={false}
            loading={loading}
            grid={{ gutter: 8, column: isSmallScreen ? 1 : 4 }}
            className={styles.list}
            locale={{ emptyText: t('caregiversDirectory.errors.noCaregiversFound') }}
            renderItem={caregiver => {
              return (
                <List.Item className={styles.item}>
                  <Card
                    title={caregiver.display_name}
                    styles={{
                      header: {
                        color: AppColors.main,
                        fontWeight: 'bold',
                      },
                    }}
                  >
                    <span className={styles.expertise}>
                      {caregiver.caregiver_groups.map((group: CaregiverGroupDTO) => group.name).join(', ')}
                    </span>
                    <br />
                    {displayAddressChunks(caregiver) && (
                      <>
                        <span className={styles.address}>
                          {caregiver.address_street}
                          {displayAddressChunksBreakline(caregiver) && <br />}
                          {(caregiver.address_zip_code || caregiver.address_city) && (
                            <>
                              {caregiver.address_zip_code} {caregiver.address_city}
                            </>
                          )}
                        </span>
                        <br />
                      </>
                    )}
                    <span className={styles.phone}>{caregiver.phone_number || ' '}</span>
                  </Card>
                </List.Item>
              );
            }}
          />
        </Row>
      </div>
    </div>
  );
}
