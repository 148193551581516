import { ApiManager } from './ApiManager';
import { DailyStatus, OnboardingSurvey, PatientFile } from './models/SymptomTrackingDTO';

export default class ApiSymptomTrackingManager {
  static async getHistory(): Promise<DailyStatus[]> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/tracking/`);
    return response.data;
  }

  static async getSpecificDailyStatus(day: string): Promise<DailyStatus> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/tracking/${day}/`);
    return response.data;
  }

  static async postSurvey(survey: DailyStatus): Promise<void> {
    const apiManager = ApiManager.getInstance();

    await apiManager.post(`/api/tracking/`, survey);
  }

  static async patchSurvey(survey: DailyStatus): Promise<void> {
    const apiManager = ApiManager.getInstance();

    await apiManager.patch(`/api/tracking/${survey.date}/`, survey);
  }

  static async postOnboardingSurvey(survey: OnboardingSurvey): Promise<void> {
    const apiManager = ApiManager.getInstance();

    await apiManager.post(`/api/onboarding/`, survey);
  }

  static async postPatientFile(file: PatientFile): Promise<void> {
    const apiManager = ApiManager.getInstance();

    await apiManager.post(`/api/patient-file/`, file);
  }

  static async getOnboardingSurvey(patientId: string): Promise<OnboardingSurvey> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/onboarding/${patientId}/`);
    return response.data;
  }

  static async getPatientFile(patientId: string): Promise<PatientFile> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.get(`/api/patient-file/${patientId}/`);
    return response.data;
  }
}
