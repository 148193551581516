/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable prettier/prettier */
import { LoadingOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Alert, Button, Col, Form, Input, Row, Spin } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import AppColors from 'config/AppColors';
import useSelectConversations from 'hooks/useSelectConversations';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ApiDoctorConversationsManager from 'services/api/ApiDoctorConversationsManager';
import ApiPatientConversationsManager from 'services/api/ApiPatientConversationsManager';
import { conversationActions } from 'store/conversations';
import { selectWebsocketConnected } from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';
import ConversationTopBar from '../components/chats/ConversationTopBar';

export type ConversationSummaryProps = {
  historic?: boolean;
};

const useStyles = createUseStyles({
  container: {
    height: '100%',
  },
  chatsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRightColor: 'rgba(0, 0, 0, 0.1)',
    borderRightStyle: 'solid',
    borderRightWidth: '0.555556px',
  },
  caregiverSummaryContainer: {
    height: '100%',
    textAlign: 'center',
  },
  summaryForm: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& .ant-alert': {
      margin: 10,
    },
  },
  summaryFormTextArea: {
    flex: 1,
    '& div': {
      height: '100%',
    },

    '& textarea': {
      height: '100%',
      fontSize: '18px !important',
    },
  },
  patientSummaryContainer: {
    flex: 1,
    textAlign: 'center',
    overflow: 'scroll',
  },
  summaryText: {
    whiteSpace: 'pre-line',
    textAlign: 'left',
    padding: 10,
    fontSize: '18px !important',
  },
  noSummaryText: {
    whiteSpace: 'pre-line',
    padding: 10,
    color: AppColors.text.discrete,
  },
});

const ConversationSummary: React.FC<ConversationSummaryProps> = ({ historic = false }) => {
  // Hooks
  const styles = useStyles();
  const [api, context] = useNotification();
  const { t } = useTranslation();

  const urlParams = useParams();
  const isWebsocketConnected = useSelector(selectWebsocketConnected);

  const {
    conversationSummary,
    historicConversationSummary,
    conversationSummaryDraft,
    historicConversationSummaryDraft,
  } = useSelectConversations();

  const conversationId = (historic ? urlParams.historicConversation : urlParams.conversationId) as string;
  const displayedSummary = historic ? historicConversationSummary : conversationSummary;
  const displayedSummaryDraft = historic ? historicConversationSummaryDraft : conversationSummaryDraft;

  const initialSummaryValue = displayedSummaryDraft || displayedSummary;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [form] = Form.useForm();
  const summaryValue = Form.useWatch('summary', form);

  // we need this ref to access the summary value on unmount
  const summaryValueRef = useRef(initialSummaryValue);
  useEffect(() => {
    if (summaryValue !== undefined) summaryValueRef.current = summaryValue;
  }, [summaryValue]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { run, refresh, loading } = useRequest(
    () => {
      return currentUser?.is_caregiver
        ? ApiDoctorConversationsManager.getDoctorConversationMessageList({
            conversationId,
          })
        : ApiPatientConversationsManager.getPatientConversationsMessageList({
            conversationId,
          });
    },
    {
      manual: true,
      onSuccess: result => {
        dispatch(conversationActions.setConversationSummary({ conversationId, summary: result.summary }));
      },
      onError: () => {
        api.error({ message: t('chats.summary.summaryLoadError') });
      },
    },
  );

  const { run: updateSummary } = useRequest(
    summary => {
      return ApiDoctorConversationsManager.updateConversationSummary(conversationId, summary);
    },
    {
      manual: true,
      onSuccess: (result, params) => {
        dispatch(conversationActions.setConversationSummary({ conversationId, summary: params[0] }));
        api.success({ message: t('chats.summary.summarySaved') });
      },
      onError: () => {
        api.error({ message: t('chats.summary.summarySaveError') });
      },
    },
  );

  function saveDraft() {
    dispatch(conversationActions.setConversationSummaryDraft({ conversationId, summary: summaryValueRef.current }));
  }

  function onBackButtonClicked() {
    saveDraft();
    navigate(-1);
  }

  useEffect(() => {
    if (isWebsocketConnected) {
      // reload the history on websocket connection
      refresh();
    }
  }, [refresh, isWebsocketConnected]);

  useEffect(() => {
    form.setFieldsValue({ summary: initialSummaryValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, initialSummaryValue]);

  useEffect(() => {
    // this runs when the component mounts
    run();
    return () => {
      // this runs when the component unmounts
      saveDraft();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstLoading = loading && !displayedSummary;

  return (
    <Row className={styles.container}>
      {context}
      <Col className={styles.chatsContainer} xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 6 }}>
        <ConversationTopBar
          showBackButton
          avatar={null}
          name={t('chats.subtitle.summary')}
          caregiverId={undefined}
          onBackButtonClick={() => onBackButtonClicked()}
        />
        {currentUser?.is_caregiver ? (
          <div className={styles.caregiverSummaryContainer}>
            {loading ? (
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            ) : (
              <Form
                form={form}
                onFinish={values => updateSummary(values.summary)}
                initialValues={{ summary: initialSummaryValue }}
                className={styles.summaryForm}
              >
                <Form.Item name="summary" className={styles.summaryFormTextArea}>
                  <Input.TextArea
                    variant="borderless"
                    autoCorrect="on"
                    placeholder={t('chats.summary.summaryPlaceholder')}
                  />
                </Form.Item>
                <Alert message={t('chats.summary.caregiverPublicWarning')} type="warning" />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={!summaryValue || summaryValue === displayedSummary}
                  >
                    {t('chats.summary.save')}
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        ) : (
          <div className={styles.patientSummaryContainer}>
            {firstLoading ? (
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            ) : (
              <p
                className={displayedSummary ? styles.summaryText : styles.noSummaryText}
                style={loading ? { color: AppColors.text.discrete } : {}}
              >
                {displayedSummary || <p>{t('chats.summary.noSummary')}</p>}
              </p>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ConversationSummary;
