/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-danger */
import * as amplitude from '@amplitude/analytics-browser';
import { RightOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Skeleton } from 'antd';
import PageTopBar from 'components/navigation/PageTopBar';
import PostsList from 'components/PostsList';
import AppColors from 'config/AppColors';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import posthog from 'posthog-js';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiContentManager from 'services/api/ApiContentManager';
import { routes } from 'services/RouteService';
import ScrollUtil from 'utils/ScrollUtil';

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  container: {
    backgroundColor: AppColors.backgroundGrey,
    height: '100%',
    overflowY: 'scroll',

    '& h2': {
      fontSize: '1.8em',
    },
  },
  textContainer: {
    maxWidth: 600,
    padding: '8px 16px 0px',
    margin: '0 auto',
  },
  content: {
    marginBottom: 16,
    fontSize: '1.2em',
    lineHeight: '1.3em',

    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },

    '& iframe': {
      // for embedded youtube videos for instance
      maxWidth: '100%',
      border: 'none',
    },

    '& h3': {
      fontSize: '1.2em',
      margin: '32px 0px 0px',
    },
    '& h4': {
      fontSize: '1em',
      marginBlockStart: '0em',
      marginBlockEnd: '-0.5em',
    },
  },
  coverImageContainer: {
    width: '100%',
    height: 200,
    overflow: 'hidden',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  writtenBy: {
    color: AppColors.text.veryLightGray,
    fontWeight: 'normal',
    marginBottom: 48,
  },
  chatCallToAction: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    backgroundColor: 'white',
    borderRadius: 16,
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.05)',

    margin: '32px 16px',
    padding: 16,

    '& h4, p': {
      margin: 0,
    },
  },
});

export default function ArticlePage() {
  // Hooks
  const { t } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();

  const urlParams = useParams();

  const scrollRef = useRef<HTMLDivElement>(null);

  const {
    run: loadPost,
    data: post,
    loading: isLoadingPost,
  } = useRequest(() => ApiContentManager.getPost(urlParams.index as string), {
    manual: true,
    cacheKey: `content.post.${urlParams.index}`,
    cacheTime: 1000 * 60 * 60, // 60 minutes
  });

  useEffect(() => {
    ScrollUtil.scrollToTop(scrollRef.current);
    loadPost();
  }, [urlParams.index]);

  const replaceLinks = html => {
    // replace <a> tags in the content with <Link> tags to navigate with react router
    return parse(html, {
      replace: node => {
        if (node.name === 'a' && node.attribs?.href) {
          const { href, ...restAttribs } = node.attribs;
          const text = node.children?.[0]?.data || '';
          // Replace <a> with <Link> while preserving all other attributes
          return (
            <Link
              to={href}
              {...restAttribs}
              onClick={() => {
                if (href.startsWith('/')) {
                  posthog.capture('info.article.link_to_other_section_clicked', {
                    article_id: post?.id,
                    article_title: post?.title,
                    link_url: href,
                  });
                }
              }}
            >
              {text}
            </Link>
          );
        }
      },
    });
  };

  function back() {
    amplitude.track('info.post_closed');
    posthog.capture('info.post_closed');
    navigate(-1);
  }

  return (
    <div className={styles.pageContainer}>
      <PageTopBar title={t('menu.info')} backButton onBackButtonClicked={() => back()} />
      {isLoadingPost ? (
        <div className={styles.container} ref={scrollRef}>
          <div className={styles.textContainer}>
            <Skeleton active paragraph={{ rows: 5 }} />
            <Skeleton active title={false} paragraph={{ rows: 5 }} />
            <Skeleton active paragraph={false} />
            <Skeleton.Input block active style={{ height: 64 }} />
          </div>
        </div>
      ) : (
        <div className={styles.container} ref={scrollRef}>
          {post?.cover_image_url && (
            <div className={styles.coverImageContainer}>
              <img src={post?.cover_image_url} alt={post?.title} className={styles.coverImage} />
            </div>
          )}
          <div className={styles.textContainer}>
            <h2>{post?.title}</h2>
            <div className={styles.content}>{replaceLinks(post?.content ?? '')}</div>
            <h4 className={styles.writtenBy}>
              {t('infoPage.article.writtenBy', {
                author: post?.author.display_name,
                date: dayjs(post?.created_at).format('LL'),
              })}
            </h4>
            {post?.related_posts?.length > 0 && (
              <>
                <h3>{t('infoPage.article.relatedPosts')}</h3>
                <PostsList
                  posts={post?.related_posts ?? []}
                  posthogOnArticleOpenExtras={{ opened_on_page: 'article', referring_article_id: post?.id?.toString() }}
                />
              </>
            )}
            <div
              className={styles.chatCallToAction}
              onClick={() => {
                navigate(routes.messagingHome.route);
                posthog.capture('info.article.chat_call_to_action_clicked', {
                  article_id: post?.id,
                  article_title: post?.title,
                });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  navigate(routes.messagingHome.route);
                  posthog.capture('info.article.chat_call_to_action_clicked', {
                    article_id: post?.id,
                    article_title: post?.title,
                  });
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img src={require('assets/caregivers-no-ai.png')} alt="chat" height={48} />
              <div>
                <h4>{t('infoPage.article.chatCallToAction.title')}</h4>
                <p>{t('infoPage.article.chatCallToAction.description')}</p>
              </div>
              <RightOutlined />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
