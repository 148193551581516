import { App } from '@capacitor/app';
import { useRequest } from 'ahooks';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiManager } from 'services/api/ApiManager';
import ApiTokenManager, {
  ApiTokenGetTokenParams,
  ApiTokenRefreshAccessTokenParams,
} from 'services/api/ApiTokenManager';
import ApiUsersManager from 'services/api/ApiUsersManager';
import { userActions } from 'store/users';
import { selectCurrentUser } from 'store/users/selectors';
import useWindowFocus from 'use-window-focus';
import JwtUtils from 'utils/JwtUtils';
import Platform from 'utils/Platform';
import useSecretStorage from './useSecretStorage';

type GetCurrentUserParams = {
  email: string;
};

export default function useAuthGuard() {
  // Hooks
  const dispatch = useDispatch();
  const { value: localStorageTokens, set: setLocalStorageTokens } = useSecretStorage('gynger-access-tokens');
  const localStorageAccessToken = localStorageTokens?.access;
  const localStorageRefreshToken = localStorageTokens?.refresh;
  // we can't use the object directly because two different objects with the same values are not equal,
  // causing useEffects to trigger indefinitely, so we package the two tokens in a string to use as a dependency
  const tokensDeps = localStorageAccessToken + localStorageRefreshToken;

  const refreshTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const appStateResumed = useRef<boolean>(true);

  // Selector
  const currentUser = useSelector(selectCurrentUser);

  const windowFocused = useWindowFocus();
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  App.addListener('appStateChange', ({ isActive }) => {
    appStateResumed.current = isActive;
  });

  const {
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    run: getCurrentUser,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } = useRequest((params?: GetCurrentUserParams) => ApiUsersManager.getUsersMe(), {
    manual: true,
    onSuccess: (result, params) => {
      dispatch(
        userActions.setCurrentUser({
          ...result,
          ...(params && params.length > 0 && params[0]?.email && { email: params[0].email }),
        }),
      );
      return result;
    },
  });

  const setTokensInLocalStorageAndMemory = (params: { accessToken: string; refreshToken?: string }) => {
    ApiManager.setTokens({ accessToken: params.accessToken, refreshToken: params.refreshToken });

    setLocalStorageTokens({ access: params.accessToken, refresh: params.refreshToken });
  };

  const clearTokens = () => {
    clearTimeout(refreshTimerRef.current);

    ApiManager.setTokens({ accessToken: undefined, refreshToken: undefined });

    setLocalStorageTokens({ access: undefined, refresh: undefined });
  };

  const {
    loading: loadingRefreshAccessToken,
    error: errorRefreshAccessToken,
    run: refreshAccessToken,
  } = useRequest((params: ApiTokenRefreshAccessTokenParams) => ApiTokenManager.refreshAccessToken(params), {
    manual: true,
    onSuccess: result => {
      setTokensInLocalStorageAndMemory({ accessToken: result.access, refreshToken: result.refresh });

      getCurrentUser();

      const decodedAccessToken = JwtUtils.parseJwt(result.access);
      if (decodedAccessToken) {
        const expiresIn = JwtUtils.expiresIn(decodedAccessToken);
        clearTimeout(refreshTimerRef.current);
        refreshTimerRef.current = setTimeout(() => {
          refreshAccessToken({ refresh: result.refresh });
        }, expiresIn);
      }

      return result;
    },
    onError: () => {
      clearTokens();
    },
  });

  const refreshAccessTokenWhenExpired = (accessToken: string, refreshToken: string) => {
    const decodedAccessToken = JwtUtils.parseJwt(accessToken);

    if (decodedAccessToken) {
      const expiresIn = JwtUtils.expiresIn(decodedAccessToken);
      clearTimeout(refreshTimerRef.current);
      refreshTimerRef.current = setTimeout(() => {
        refreshAccessToken({ refresh: refreshToken });
      }, expiresIn);
    }
  };

  const {
    loading: loadingTokens,
    error: errorTokens,
    run: getAccessToken,
  } = useRequest((params: ApiTokenGetTokenParams) => ApiTokenManager.getAccessToken(params), {
    manual: true,
    onSuccess: (result, params) => {
      setTokensInLocalStorageAndMemory({ accessToken: result.access, refreshToken: result.refresh });

      refreshAccessTokenWhenExpired(result.access, result.refresh);

      getCurrentUser();

      if (Platform.getPlatform() === 'ios') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        SavePassword.promptDialog({ username: params[0].email, password: params[0].password });
      }

      return result;
    },
    onError: () => {
      clearTokens();
    },
  });

  const isLoading = loadingTokens || loadingRefreshAccessToken || loadingCurrentUser;
  const error = errorTokens || errorRefreshAccessToken || errorCurrentUser;
  const accessToken = ApiManager.getAccessToken();
  // The API is callable when there is a valid access token and a current user
  const canCallApi = !!(accessToken && currentUser);

  const validateOrRefreshTokens = (xxAccessToken?: string, xxRefreshToken?: string) => {
    // First, check there are the tokens
    if (xxAccessToken && xxRefreshToken) {
      // If so, decode them
      const decodedAccessToken = JwtUtils.parseJwt(xxAccessToken);
      const decodedRefreshToken = JwtUtils.parseJwt(xxRefreshToken);

      if (decodedAccessToken && decodedRefreshToken && !JwtUtils.isExpired(decodedAccessToken)) {
        if (!currentUser) {
          getCurrentUser();
        }
        return true;
      }

      // If the access token has expired, renew it
      refreshAccessToken({ refresh: xxRefreshToken });
      return true;
    }

    return false;
  };

  const getValidTokenOrRefresh = () => {
    if (!isLoading) {
      // First, try to get access and refresh token from the local storage
      if (localStorageAccessToken && localStorageRefreshToken) {
        // load the local storage tokens in memory
        ApiManager.setTokens({ accessToken: localStorageAccessToken, refreshToken: localStorageRefreshToken });

        if (validateOrRefreshTokens(localStorageAccessToken, localStorageRefreshToken)) {
          refreshAccessTokenWhenExpired(localStorageAccessToken, localStorageRefreshToken);
        }
      } else {
        const { accessToken: access, refreshToken } = ApiManager.getTokens();
        if (validateOrRefreshTokens(access, refreshToken) && access && refreshToken) {
          refreshAccessTokenWhenExpired(access, refreshToken);
        }
      }
    }
  };

  // On mount, if the access token exist but expired, or no token, then refresh token silently, and get user details from API
  useEffect(() => {
    getValidTokenOrRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocused, appStateResumed, tokensDeps]);

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(refreshTimerRef.current);
  }, []);

  return {
    canCallApi,
    isLoading,
    error,
    accessToken,
    getAccessToken,
  };
}
