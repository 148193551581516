/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */

import { Segmented, Spin, Typography } from 'antd';
import AppColors from 'config/AppColors';
import useLoadConversations from 'hooks/useLoadConversations';
import { createUseStyles } from 'react-jss';

import { InfoCircleOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import GGButton from 'components/buttons/GGButton';
import ChatsList from 'components/chats/ChatsList';
import Explainer from 'components/chats/Explainer';
import CaregiverAvatarList from 'components/lists/CaregiverAvatarList';
import PageTopBar from 'components/navigation/PageTopBar';
import SkeletonChats from 'components/skeleton/SkeletonChats';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';
import { ConversationDTO } from 'services/api/models/ConversationDTO';
import {
  selectClosedConversations,
  selectConversationLoadingStatus,
  selectConversations,
  selectLoadedConversationsOnce,
  selectMyRequestsConversations,
  selectOnHoldConversations,
  selectUnassignedConversations,
  selectWaitingForReassignmentConversations,
} from 'store/conversations/selectors';
import { AssignedConversationSegmentTypes, UnassignedConversationSegmentTypes } from 'store/conversations/types';
import { selectCurrentUser } from 'store/users/selectors';
import { RootState } from 'types';

const { Text } = Typography;

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    backgroundColor: AppColors.backgroundGrey,
  },
  container: {
    height: '100%',
    overflowY: 'scroll',
    maxWidth: 600,
    width: '100vw',
  },
  patientViewContainer: {
    overflowY: 'scroll',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 8px',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    flex: 1,
    '& img': {
      marginTop: 32,
      marginBottom: 32,
    },
  },
  noConvContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 8px',
    alignItems: 'center',
    marginBottom: 16,
  },
  withConvContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 8px',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 16,
  },
  topicTitle: {
    color: `${AppColors.text.discrete} !important`,
    padding: 4,
    margin: 0,
  },
  topicText: {
    padding: 8,
    textAlign: 'center',
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    marginTop: 16,
  },
  cardColumn: {
    padding: '0 !important',
    display: 'flex',
    justifyContent: 'center',
  },
  bigCardContainer: {
    padding: 8,
  },
  caregiversListContainer: {
    marginTop: 16,
    marginBottom: 16,
    width: '100%',
  },
  cardIcon: {
    minWidth: 80,
    maxWidth: 80,
  },
  cardSvgIcon: {
    height: 80,
    color: AppColors.main,
    fontSize: 80 * 0.75,
  },
});

export default function MessagingHome() {
  useLoadConversations();

  // Selectors
  const currentUser = useSelector(selectCurrentUser);
  const conversations = useSelector(
    (state: RootState) => selectConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const unassignedConversations = useSelector(
    (state: RootState) => selectUnassignedConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const myRequestsConversations = useSelector(
    (state: RootState) => selectMyRequestsConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const waitingForReassignmentConversations = useSelector(
    (state: RootState) => selectWaitingForReassignmentConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const onHoldConversations = useSelector(
    (state: RootState) => selectOnHoldConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const closedConversations = useSelector(
    (state: RootState) => selectClosedConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const conversationLoadingStatus = useSelector(selectConversationLoadingStatus);
  const loadedConversationsOnce = useSelector(selectLoadedConversationsOnce);

  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = useStyles();

  // States
  const [unassignedSegmentValue, setUnassignedSegmentValue] =
    useState<UnassignedConversationSegmentTypes>('unassigned');
  const [assignedSegmentValue, setAssignedSegmentValue] = useState<AssignedConversationSegmentTypes>('assigned');
  const scrollableElement = useRef<HTMLDivElement>(null);

  function newConversation() {
    navigate(routes.newChat.route);
  }

  const getConversationsListByUnassignedValue = () => {
    switch (unassignedSegmentValue) {
      case 'unassigned':
        return {
          title: t('chats.subtitle.unassigned'),
          conversations: unassignedConversations || [],
        };
      case 'myRequest':
        return {
          title: t('chats.subtitle.myRequest'),
          conversations: myRequestsConversations || [],
        };
      case 'waitingForReassignment':
        return {
          title: t('chats.subtitle.waitingForReassignment'),
          conversations: waitingForReassignmentConversations || [],
        };
      default:
        return {
          title: t('chats.subtitle.unassigned'),
          conversations: [],
        };
    }
  };

  const getConversationsListByAssignedValue = () => {
    switch (assignedSegmentValue) {
      case 'assigned':
        return {
          title: t('chats.subtitle.assigned'),
          conversations: conversations || [],
        };
      case 'onHold':
        return {
          title: t('chats.subtitle.onHold'),
          conversations: onHoldConversations || [],
        };
      case 'closed':
        return {
          title: t('chats.subtitle.closed'),
          conversations: closedConversations || [],
        };

      default:
        return {
          title: t('chats.subtitle.assigned'),
          conversations: [],
        };
    }
  };

  function renderChats<T>(params: {
    title: string;
    conversations: ConversationDTO[];
    options: { label: string; value: string }[];
    onChange: (value: T) => void;
  }) {
    return (
      <>
        <Segmented
          options={params.options}
          block
          onChange={value => params.onChange(value as T)}
          style={{ marginTop: 13, marginLeft: 13, marginRight: 13 }}
        />
        {conversationLoadingStatus === 'LOADING' ? (
          <SkeletonChats numberOfItems={3} />
        ) : (
          <ChatsList title={params.title} conversations={params.conversations} readOnlyConvs={false} />
        )}
      </>
    );
  }

  const renderUnassignedChats = () => {
    if (!currentUser?.is_caregiver) {
      return null;
    }

    const data = getConversationsListByUnassignedValue();

    return renderChats({
      title: data.title,
      conversations: data.conversations,
      options: [
        { label: `${t('chats.subtitle.unassigned')} (${unassignedConversations.length})`, value: 'unassigned' },
        { label: `${t('chats.subtitle.myRequest')} (${myRequestsConversations.length})`, value: 'myRequest' },
        {
          label: `${t('chats.subtitle.waitingForReassignment')} (${waitingForReassignmentConversations.length})`,
          value: 'waitingForReassignment',
        },
      ],
      onChange: value => setUnassignedSegmentValue(value as UnassignedConversationSegmentTypes),
    });
  };

  const renderAssignedChats = () => {
    if (!currentUser?.is_caregiver) {
      return conversationLoadingStatus === 'LOADING' ? (
        <SkeletonChats numberOfItems={3} />
      ) : (
        <ChatsList conversations={conversations} title={null} readOnlyConvs={false} />
      );
    }

    const data = getConversationsListByAssignedValue();

    return renderChats({
      title: data.title,
      conversations: data.conversations,
      options: [
        { label: `${t('chats.subtitle.assigned')}`, value: 'assigned' },
        { label: `${t('chats.subtitle.onHold')} (${onHoldConversations.length})`, value: 'onHold' },
        {
          label: `${t('chats.subtitle.closed')}`,
          value: 'closed',
        },
      ],
      onChange: value => setAssignedSegmentValue(value as AssignedConversationSegmentTypes),
    });
  };

  const renderPatientWithConversations = () => (
    <div className={styles.patientViewContainer}>
      {renderUnassignedChats()}
      {renderAssignedChats()}

      <div className={styles.withConvContentContainer}>
        <Text className={styles.topicTitle}>{t('chats.dashboardView.topicToCover')}</Text>
      </div>

      <div className={styles.withConvContentContainer}>
        <Title className={styles.topicTitle} level={5}>
          {t('chats.dashboardView.caregiversTeam')}
        </Title>
        <CaregiverAvatarList direction="horizontal" />
      </div>

      <div className={styles.ctaContainer}>
        <GGButton
          color="discrete"
          type="dashed"
          size="large"
          onClick={() => navigate(routes.messagingHowto.route)}
          icon={<InfoCircleOutlined />}
        >
          {t('chats.goToExplainer')}
        </GGButton>
      </div>
    </div>
  );

  const renderPatientNoConversations = () => (
    <div className={styles.patientViewContainer}>
      <Explainer scrollableElement={scrollableElement} />
    </div>
  );

  const renderLoadingPage = () => (
    <div className={styles.loadingContainer}>
      <img src={require('assets/logo.png')} height={48} />
      <p>{t('chats.loadingPage.message')}</p>
      <Spin />
    </div>
  );

  const renderCaregiver = () => (
    <>
      {renderUnassignedChats()}
      {renderAssignedChats()}
    </>
  );

  return (
    <div className={styles.pageContainer}>
      <PageTopBar title={t('chats.title')} />
      <div className={styles.container} ref={scrollableElement}>
        {currentUser?.is_caregiver
          ? renderCaregiver()
          : !loadedConversationsOnce
            ? renderLoadingPage()
            : conversations.length !== 0
              ? renderPatientWithConversations()
              : renderPatientNoConversations()}
      </div>
    </div>
  );
}
