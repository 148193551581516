import ColorHash from 'color-hash-ts';

import AppColors from 'config/AppColors';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Label, ReferenceArea, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';

export type SymptomsChartProps = {
  dayLabels: Dayjs[];
  dayValues: string[][];
};

interface ScatterPoint {
  x: number;
  y: number;
}

const useStyles = createUseStyles({
  symptomLegend: {
    margin: '5px 12px 0px 0px',
    display: 'inline-flex',
    alignItems: 'center',
    color: AppColors.text.veryLightGray,
  },
  symptomLegendDot: {
    height: 8,
    width: 8,
    display: 'inline-block',
    marginRight: 4,
    borderRadius: '50%',
  },
});

const SymptomsChart: React.FC<SymptomsChartProps> = ({ dayLabels, dayValues }) => {
  const { t } = useTranslation();
  const colorHash = new ColorHash();

  const styles = useStyles();

  const allSymptoms = new Set<string>();
  dayValues.forEach(daySymptoms => {
    daySymptoms.forEach(symptom => allSymptoms.add(symptom));
  });
  const symptomMap = Object.fromEntries(
    Array.from(allSymptoms)
      .sort()
      .map((symptom, index) => [symptom, index]),
  );

  const scatterPoints = new Map<string, ScatterPoint[]>(Object.keys(symptomMap).map(s => [s, []]));
  dayValues.forEach((daySymptoms, dayIndex) => {
    daySymptoms.forEach(symptom => {
      (scatterPoints.get(symptom) as ScatterPoint[]).push({ x: dayIndex + 0.5, y: symptomMap[symptom] + 1.5 });
    });
  });

  return (
    <>
      <ResponsiveContainer minWidth={300} width="100%" height={70}>
        <ScatterChart data={dayValues} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <XAxis type="number" dataKey="x" domain={[0, dayLabels.length]} hide />
          <YAxis type="number" dataKey="y" domain={[0, 5]} hide />

          {dayLabels.map((day, index) => (
            <ReferenceArea
              key={`whitebar-${day.toISOString()}`}
              x1={index}
              x2={index + 1}
              y1={0}
              y2={10}
              fill="white"
              fillOpacity={1}
              stroke={AppColors.backgroundGrey}
              strokeWidth={2}
              ifOverflow="visible"
            />
          ))}
          {dayLabels.map((day, index) => (
            <ReferenceArea
              key={`daybar-${day.toISOString()}`}
              x1={index}
              x2={index + 1}
              y1={0}
              y2={1}
              fill={day.isoWeekday() < 6 ? '#E7E7E7' : '#979797'}
              fillOpacity={1}
              stroke={AppColors.backgroundGrey}
              strokeWidth={2}
              ifOverflow="visible"
              label={<Label fontSize={8} value={day.date()} fill={day.isoWeekday() < 6 ? '#979797' : 'white'} />}
            />
          ))}

          {Array.from(scatterPoints.entries()).map(([symptom, points]) => (
            <Scatter key={symptom} name={symptom} data={points} fill={colorHash.hex(symptom)} />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
      <div>
        {Array.from(allSymptoms)
          .sort()
          .reverse()
          .map(symptom => (
            <span className={styles.symptomLegend} key={symptom}>
              <div className={styles.symptomLegendDot} style={{ backgroundColor: `${colorHash.hex(symptom)}` }} />
              {t(`symptomTracking.survey.symptoms.${symptom}`)}
            </span>
          ))}
      </div>
    </>
  );
};
export default SymptomsChart;
