/* eslint-disable no-nested-ternary */
import { initSurvicate } from '@survicate/survicate-web-surveys-wrapper/widget_wrapper';
import { useRequest } from 'ahooks';
import { Card, Col, Image, Row, Skeleton, Statistic } from 'antd';
import GGButton from 'components/buttons/GGButton';
import LinearCalendarChart from 'components/charts/LinearCalendarChart';
import ChatsList from 'components/chats/ChatsList';
import CaregiverAvatarList from 'components/lists/CaregiverAvatarList';
import PageTopBar from 'components/navigation/PageTopBar';
import PostsList from 'components/PostsList';
import AppColors from 'config/AppColors';
import dayjs from 'dayjs';
import useLoadConversations from 'hooks/useLoadConversations';
import useScrollRestoration from 'hooks/useScrollRestoration';
import useSymptomTrackingData from 'hooks/useSymptomTrackingData';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiContentManager from 'services/api/ApiContentManager';
import ApiUsersManager from 'services/api/ApiUsersManager';
import { routes } from 'services/RouteService';
import { contentActions } from 'store/content';
import { selectSuggestedPosts } from 'store/content/selectors';
import {
  selectConversationLoadingStatusIsLoading,
  selectConversations,
  selectMyRequestsConversations,
  selectUnassignedConversations,
  selectWaitingForReassignmentConversations,
} from 'store/conversations/selectors';
import { userActions } from 'store/users';
import { selectCurrentUser } from 'store/users/selectors';
import { RootState } from 'types';

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: AppColors.backgroundGrey,
  },
  container: {
    backgroundColor: AppColors.backgroundGrey,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    maxWidth: 600,
    width: '100vw',
    padding: 8,
    paddingBottom: 24,
    overflowY: 'auto',

    '& .rce-citem-avatar': {
      paddingLeft: 0,
    },
    '& .rce-citem-body': {
      paddingRight: 0,
    },
  },
  moduleCard: {
    border: 'none',
    boxShadow: '0px 5px rgba(120, 120, 120, 0.1)',
    borderRadius: 16,
    '& .ant-card-body': {
      padding: '12px 16px 20px',
    },
  },
  welcomeMessage: {
    width: '100%',
    textAlign: 'center',
    marginTop: 32,
    color: '#595959',
  },
  cardTitle: {
    margin: 0,
    color: '#595959',
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  trackingTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  graphLabel: {
    margin: 0,
    color: AppColors.text.veryLightGray,
  },
  trackingGraphContainer: {
    marginBottom: 16,
  },
  caregiversListContainer: {
    marginBottom: 8,
  },
  caregiverStatsContainer: {
    marginBottom: 8,
  },
});

export default function Home() {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const areConversationsLoading = useSelector(selectConversationLoadingStatusIsLoading);
  const conversations = useSelector(
    (state: RootState) => selectConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const unassignedConversations = useSelector(
    (state: RootState) => selectUnassignedConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const myRequestsConversations = useSelector(
    (state: RootState) => selectMyRequestsConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );
  const waitingForReassignmentConversations = useSelector(
    (state: RootState) => selectWaitingForReassignmentConversations(state, currentUser?.is_caregiver),
    shallowEqual,
  );

  useLoadConversations();
  const {
    getDisplayedDays,
    getStatusValueForDisplayedDays,
    hasTrackingBeenUsed,
    hasTrackingForToday,
    loading: loadingTracking,
  } = useSymptomTrackingData();
  const displayedDays = getDisplayedDays(dayjs());
  const generalStateValues = getStatusValueForDisplayedDays('general_state', displayedDays);

  const suggestedPosts = useSelector(selectSuggestedPosts);

  const {
    run: loadPosts,
    data: posts,
    loading: isLoadingPosts,
  } = useRequest(() => ApiContentManager.getPosts(), {
    manual: true,
    cacheKey: `content.suggestedPosts`,
    cacheTime: 1000 * 60 * 10, // 10 minutes
    onSuccess: data => {
      if (!suggestedPosts) {
        // storing suggested posts in the store to avoid fetching them again
        // as they are shuffled on the backend. This way, we can keep
        // the same order for a given session of a user.
        // This makes it feel more natural to the user, and also helps
        // when coming back to a list of articles from a specific article
        // as it keeps the same position in the list of suggested articles.
        dispatch(contentActions.setSuggestedPosts(data));
      }
    },
  });

  useEffect(() => {
    if (!suggestedPosts) {
      loadPosts();
    }
  }, [suggestedPosts, loadPosts]);

  const scrollableRef = useRef<HTMLDivElement>(null);
  useScrollRestoration(scrollableRef);

  useEffect(() => {
    /**
     * Display the NPS survey when
     * - The `should_ask_for_nps` is true
     * - There is a current user and he is not a caregiver
     */
    if (
      currentUser &&
      currentUser.should_ask_for_nps &&
      !currentUser.is_caregiver &&
      import.meta.env.VITE_SURVICATE_WORKSPACE_KEY
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      initSurvicate({ workspaceKey: import.meta.env.VITE_SURVICATE_WORKSPACE_KEY }).then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        ApiUsersManager.acknowledgeNPS();

        /**
         * Now ideally we'd want to run the following code to make sure we actually had an interaction
         * from the user BUT THESE CHACAL SURVICATE DEVELOPERS are hiding in their docs that this is
         * only available for paid plans -- AND YOU HAVE TO SPECIFICALLY USE ONE SPECIFIC NPM PACKAGE
         * ALSO BECAUSE THE OTHER ONE IS ONLY AVAILABLE FOR PAID USERS BUT WAIT THERE'S MORE
         * 
         *    !! THE PAID PLAN IS 100$... A MONTH!!! EVERY MONTH FOR SUCH A PIECE OF SH*T !! 
         * 
         * So anyway I'm just initializing the instance like this and immediately acknowledging on the
         * server side to never ask the user again for this shit survey.
         * 
         * const survicate = getSurvicateInstance();
         * if (survicate) {
         *  // acknowledging a completion or dismiss of the form to the server,
         *  // to avoid showing it in the future
         *  survicate.addEventListener(ApiEvent.surveyClosed, () => {
         *    ApiUsersManager.acknowledgeNPS();
         *  });
         *  survicate.addEventListener(ApiEvent.surveyCompleted, () => {
         *    ApiUsersManager.acknowledgeNPS();
         *  });
        }
        */
      });

      // update the current user locally to disable NPS
      dispatch(userActions.disableNPSSurvey());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const skeleton = (
    <div className={styles.skeletonContainer}>
      <Skeleton.Input block active style={{ height: 100 }} />
      <Skeleton.Input block active />
    </div>
  );

  const messagesCardForCaregivers = (
    <>
      <Row className={styles.caregiverStatsContainer}>
        <Col span={8}>
          <Statistic
            title={t('home.messages.waitingForMe')}
            value={unassignedConversations.length + myRequestsConversations.length}
            precision={0}
          />
        </Col>
        <Col span={8}>
          <Statistic title={t('home.messages.inProgress')} value={conversations.length} precision={0} />
        </Col>
        <Col span={8}>
          <Statistic
            title={t('home.messages.waitingForReassignment')}
            value={waitingForReassignmentConversations.length}
            precision={0}
          />
        </Col>
      </Row>
      <GGButton block type="primary" size="large" onClick={() => navigate(routes.messagingHome.route)}>
        {t('home.messages.goToMessages')}
      </GGButton>
    </>
  );
  const messagesCardWithoutMessages = (
    <>
      <p>{t('home.messages.description')}</p>
      <div className={styles.caregiversListContainer}>
        <CaregiverAvatarList direction="horizontal" />
      </div>
      <GGButton block type="primary" size="large" onClick={() => navigate(routes.messagingHome.route)}>
        {t('home.messages.callToAction')}
      </GGButton>
    </>
  );

  const messagesCardWithMessages = (
    <>
      <ChatsList conversations={conversations} title={null} readOnlyConvs={false} maximumConversations={1} />
      <GGButton block color="discrete" size="large" onClick={() => navigate(routes.messagingHome.route)}>
        {t('home.messages.goToMessages')}
      </GGButton>
    </>
  );
  const trackingCardWithoutData = (
    <>
      <p>{t('home.tracking.description')}</p>
      <div className={styles.trackingGraphContainer}>
        <Image src={require('../assets/home-tracking-example.png')} preview={false} />
      </div>

      <GGButton block type="primary" size="large" onClick={() => navigate(routes.symptomTrackingSurvey.route)}>
        {t('home.tracking.fillSurvey')}
      </GGButton>
    </>
  );

  const trackingCardWithData = (
    <>
      <div className={styles.trackingGraphContainer}>
        <LinearCalendarChart dayLabels={displayedDays} dayValues={generalStateValues} range={[0, 4]} />
      </div>
      {hasTrackingForToday ? (
        <GGButton block color="discrete" size="large" onClick={() => navigate(routes.symptomTracking.route)}>
          {t('home.tracking.goToTracking')}
        </GGButton>
      ) : (
        <GGButton block type="primary" size="large" onClick={() => navigate(routes.symptomTrackingSurvey.route)}>
          {t('home.tracking.fillSurvey')}
        </GGButton>
      )}
    </>
  );

  return (
    <div className={styles.pageContainer}>
      <PageTopBar title={t('home.pageTitle')} />
      <div className={styles.container} ref={scrollableRef}>
        <h2 className={styles.welcomeMessage}>{t('home.greeting', { firstName: currentUser?.first_name })}</h2>

        <Card className={styles.moduleCard}>
          <h2 className={styles.cardTitle}>{t('home.messages.title')}</h2>
          {areConversationsLoading
            ? skeleton
            : currentUser?.is_caregiver
              ? messagesCardForCaregivers
              : conversations.length > 0
                ? messagesCardWithMessages
                : messagesCardWithoutMessages}
        </Card>

        <Card className={styles.moduleCard}>
          <div className={styles.trackingTitleContainer}>
            <h2 className={styles.cardTitle}>{t('home.tracking.title')}</h2>
            {hasTrackingBeenUsed && <p className={styles.graphLabel}>{t('home.tracking.graphLabel')}</p>}
          </div>
          {loadingTracking ? skeleton : hasTrackingBeenUsed ? trackingCardWithData : trackingCardWithoutData}
        </Card>

        <Card className={styles.moduleCard}>
          <h2 className={styles.cardTitle}>{t('home.info.title')}</h2>
          <Row>
            {isLoadingPosts ? (
              <div>
                <Skeleton.Input block active style={{ height: 64, marginTop: 16 }} />
                <Skeleton.Input block active style={{ height: 64, marginTop: 16 }} />
                <Skeleton.Input block active style={{ height: 64, marginTop: 16 }} />
              </div>
            ) : (
              <PostsList posts={posts ?? []} limitPosts={4} posthogOnArticleOpenExtras={{ opened_on_page: 'home' }} />
            )}
          </Row>
        </Card>
      </div>
    </div>
  );
}
