import dayjs from 'dayjs';
import { IMessage } from 'react-chat-elements';
import { ConversationMessage } from 'services/api/models/ConversationDTO';
import { GGApiMessage, GGApiSender } from 'services/api/models/GGApiMessage';
import { UserDTO } from 'services/api/models/UserDTO';
import { MessageSummary } from 'store/conversations/types';
import defaultCaregiverPhoto from '../assets/experts-man-dark.png';
import AppColors from '../config/AppColors';

export type ChatMessage = IMessage & GGApiMessage;

function messageStatus(message: ConversationMessage, currentUser: UserDTO, conversationSummary: MessageSummary) {
  if (currentUser?.is_caregiver) {
    const readStatus = conversationSummary?.conversation.read_statuses?.filter(
      status => status.reader === conversationSummary?.conversation.patient.id,
    );
    if (readStatus?.length && readStatus[0].last_read_datetime >= message.sent_at) {
      return 'read';
    }
  }
  return 'sent';
}

export default class MessageUtil {
  static convertMessagesToComponentDataType(
    data: ConversationMessage[],
    currentUser: UserDTO,
    conversationSummary: MessageSummary,
  ): ChatMessage[] {
    return data.map((message, index) => {
      const isSenderMe = message.sender?.id === currentUser?.id;
      const title = isSenderMe ? null : message.sender?.display_name;
      const position = isSenderMe ? 'right' : 'left';
      const titleColor = isSenderMe ? AppColors.messages.me.title : AppColors.messages.recipient.title;

      const getSenderCaregiverPhoto = (sender?: GGApiSender) =>
        sender?.is_caregiver ? sender?.photo_url || defaultCaregiverPhoto : sender?.photo_url;

      return {
        id: index.toString(),
        date: dayjs(message.sent_at).fromNow(),
        dateString: dayjs(message.sent_at).fromNow(),
        status: messageStatus(message, currentUser, conversationSummary),
        sender: {
          id: message.sender.id,
          display_name: message.sender.display_name,
          photo_url: message.sender.photo_url,
          is_caregiver: message.sender.is_caregiver,
        },
        text: message.message_text,
        type: 'text' as const,
        title,
        position,
        titleColor,
        // Do not display avatars if the current user is a caregiver
        // Otherwise (patient), display the caregiver photo or a failover
        avatar: currentUser?.is_caregiver ? null : getSenderCaregiverPhoto(message.sender),
        focus: false,
        forwarded: false,
        replyButton: false,
        removeButton: false,
        notch: false,
        retracted: false,
      } as ChatMessage;
    });
  }
}
