import { GetResult, Preferences } from '@capacitor/preferences';
import { useRequest } from 'ahooks';

export default function usePreferences(key: string, onGetSuccess: (value: any) => void = () => {}) {
  const parseValue = (value: GetResult | undefined) => JSON.parse(value?.value || '{}');

  const {
    run: get,
    data: rawValue,
    loading,
  } = useRequest(() => Preferences.get({ key }), {
    onSuccess: result => {
      onGetSuccess(parseValue(result));
    },
  });
  const { run: set } = useRequest(value => Preferences.set({ key, value }), {
    manual: true,
    onSuccess: () => {
      get();
    },
  });
  const { run: clear } = useRequest(() => Preferences.remove({ key }), {
    manual: true,
    onSuccess: () => {
      get();
    },
  });

  const value = parseValue(rawValue);

  return {
    get,
    set,
    clear,
    loading,
    value,
  };
}
