/* eslint-disable global-require */
import { Col, Divider, Grid, Image, Typography, theme } from 'antd';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from 'services/RouteService';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

export type AuthContainerProps = {
  title: string;
  description: string;
  mode: 'register' | 'login' | 'passwordReset';
  bottomLinkTo: 'register' | 'login';
};

export default function AuthContainer(props: PropsWithChildren<AuthContainerProps>) {
  const { title, description, mode, children, bottomLinkTo } = props;

  // Hooks
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const { token } = useToken();

  const styles = {
    section: {
      alignItems: mode === 'register' ? 'start' : 'center',
      backgroundColor: token.colorBgContainer,
      display: 'flex',
      flexDirection: 'row' as const,
      height: screens.md && mode !== 'register' ? '100dvh' : 'auto',
    },
    containerImage: {
      display: screens.md ? 'flex' : 'none',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
    image: {
      height: 'auto',
      width: '100%',
    },
    container: {
      margin: '0 auto',
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      flex: `0 0 ${screens.md ? '40%' : '80%'}`,
    },
    header: {
      marginBottom: token.marginXL,
    },
    text: {
      color: token.colorTextSecondary,
      whiteSpace: 'pre-line',
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
    otherActions: {
      marginTop: 15,
    },
    otherActionsLink: {
      textDecoration: 'underline',
    },
    linkToSite: {
      color: token.colorTextSecondary,
    },
  };

  return (
    <section style={styles.section}>
      <Col style={styles.containerImage} span={screens.md ? 12 : 0}>
        <Image alt="login-bg" preview={false} src={require('../../assets/login-bg.jpg')} style={styles.image} />
      </Col>
      <Col style={styles.container} span={screens.md ? 12 : 24}>
        <div style={styles.header}>
          <Title style={styles.title}>{title}</Title>
          <Text style={styles.text}>{description}</Text>
        </div>
        {children}
        <div style={styles.otherActions}>
          <Link
            to={bottomLinkTo === 'register' ? routes.registerAskIfCode.route : routes.login.route}
            style={styles.otherActionsLink}
          >
            {bottomLinkTo === 'register'
              ? t('loginRegister.actions.noAccount')
              : t('loginRegister.actions.alreadyRegistered')}
          </Link>
        </div>
        <Divider />
      </Col>
    </section>
  );
}
