/* eslint-disable global-require */
import * as amplitude from '@amplitude/analytics-browser';
import useNotification from 'antd/es/notification/useNotification';
import GGButton from 'components/buttons/GGButton';
import useAuthGuard from 'hooks/useAuthGuard';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';
import AuthContainer from '../components/containers/AuthContainer';

export default function RegisterNoCode() {
  // Hooks
  const { error } = useAuthGuard();
  const { t } = useTranslation();
  const [api, context] = useNotification();
  const navigate = useNavigate();
  useEffect(() => {
    if (error) {
      api.error({
        message: t('loginRegister.errors.login'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <AuthContainer
      title={t('loginRegister.title.register')}
      description={t('loginRegister.description.registerNoCode')}
      mode="login"
      bottomLinkTo="login"
    >
      {context}

      <GGButton
        // href={`mailto:${t('global.support.codeRequestEmailWithSubject')}`}
        block
        type="primary"
        htmlType="submit"
        disabled={false}
        loading={false}
        onClick={() => {
          amplitude.track('registration.understood_no_code');
          posthog.capture('registration.understood_no_code');
          navigate(routes.login.route);
        }}
      >
        {t('loginRegister.actions.understoodNoCode')}
      </GGButton>
    </AuthContainer>
  );
}
