/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { PostMetadataDTO } from 'services/api/models/PostDTO';
import resetAll from 'store/shared/resetAll';
import createSlice from 'utils/@reduxjs/toolkit';
import { ContentState } from './types';

export const initialState: ContentState = {
  suggestedPosts: undefined,
};

const slice = createSlice({
  name: 'content',
  initialState,
  extraReducers: (builder: any) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setSuggestedPosts: (state, action: PayloadAction<PostMetadataDTO[]>) => {
      state.suggestedPosts = action.payload;
    },
  },
});

/**
 * `actions` will be used to trigger change in the state from where ever you want
 */
export const { actions: contentActions, reducer: contentReducer } = slice;
