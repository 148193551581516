import Explainer from 'components/chats/Explainer';
import PageTopBar from 'components/navigation/PageTopBar';
import AppColors from 'config/AppColors';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate, useSearchParams } from 'react-router-dom';

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  container: {
    padding: '8px 8px 0px',
    backgroundColor: AppColors.backgroundGrey,
    height: '100%',
    overflowY: 'scroll',
  },
  htmlContainer: {
    width: '100',
  },
});

export default function MessagingHowto() {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryStringParams, setQueryStringParams] = useSearchParams();
  const scrollableElement = useRef<HTMLDivElement>(null);

  function onBackButtonClick() {
    navigate(-1);
  }

  return (
    <div className={styles.pageContainer}>
      <PageTopBar title={t('chats.explainer.title')} backButton onBackButtonClicked={() => onBackButtonClick()} />
      <div className={styles.container} ref={scrollableElement}>
        <Explainer isOnTransientPage scrollableElement={scrollableElement} disableNewConversationButton />
      </div>
    </div>
  );
}
