import * as amplitude from '@amplitude/analytics-browser';
import { BellFilled, BellOutlined } from '@ant-design/icons';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Modal, Row } from 'antd';
import AppColors from 'config/AppColors';
import useCheckNotificationsPermissions from 'hooks/useCheckNotificationsPermissions';
import usePreferences from 'hooks/usePreferences';
import ActionTypes from 'notifications';
import posthog from 'posthog-js';
import Keys from 'preferences/preferences';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Picker from 'react-mobile-picker';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/users/selectors';
import Platform from 'utils/Platform';
import GGButton from './buttons/GGButton';
import NotificationSuggester from './cards/NotificationSuggester';

export type SymptomTrackingReminderProps = {
  isCallToAction?: boolean;
  onReminderEnabled?: () => void;
};

const useStyles = createUseStyles({
  notificationScheduleForm: {
    justifyContent: 'space-around',

    '& .ant-alert': {
      marginBottom: 16,
    },
  },
  reminderTimePicker: {
    width: 200,
  },
});

const SymptomTrackingReminder: React.FC<SymptomTrackingReminderProps> = ({ isCallToAction, onReminderEnabled }) => {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const { doesUserHaveNotifications } = useCheckNotificationsPermissions();

  const [showNotificationScheduleModal, setShowNotificationScheduleModal] = useState<boolean>(false);
  const timePickerSelections: { hour: number[]; minute: number[] } = {
    hour: Array.from({ length: 24 }, (_, i) => i),
    minute: Array.from({ length: 12 }, (_, i) => i * 5),
  };

  const [timePickerValue, setTimePickerValue] = useState({ hour: 21, minute: 0 });

  const {
    set: storeReminderTime,
    clear: clearReminder,
    value: reminderTime,
    loading: loadingReminderTime,
  } = usePreferences(Keys.TRACKING_REMINDER_TIME, storedReminder => {
    if (storedReminder.hour !== undefined) setTimePickerValue(storedReminder);
  });
  const areNotificationsEnabled = reminderTime.hour !== undefined;

  const disableNotifications = () => {
    if (areNotificationsEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      LocalNotifications.getPending().then(pending =>
        LocalNotifications.cancel({ notifications: pending.notifications }),
      );
      clearReminder();
    }
    setShowNotificationScheduleModal(false);
    amplitude.track('tracking.reminder_disabled');
    posthog.capture('tracking.reminder_disabled');
  };
  const enableNotifications = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    LocalNotifications.schedule({
      notifications: [
        {
          title: t('appName'),
          body: t('symptomTracking.notification.dailyReminder', { name: currentUser?.first_name }),
          id: 1,
          schedule: { on: { hour: timePickerValue.hour, minute: timePickerValue.minute } },
          actionTypeId: ActionTypes.SYMPTOM_TRACKING_REMINDER,
        },
      ],
    });

    storeReminderTime(JSON.stringify(timePickerValue));
    setShowNotificationScheduleModal(false);
    amplitude.track('tracking.reminder_enabled_or_changed', {
      time: timePickerValue,
    });
    posthog.capture('tracking.reminder_enabled_or_changed', {
      time: timePickerValue,
    });

    if (onReminderEnabled) {
      onReminderEnabled();
    }
  };

  const showReminderModal = () => {
    setShowNotificationScheduleModal(true);
    amplitude.track('tracking.reminder_modal_opened');
    posthog.capture('tracking.reminder_modal_opened');
  };

  return (
    <>
      {areNotificationsEnabled ? (
        <GGButton onClick={showReminderModal} disabled={loadingReminderTime}>
          {t('symptomTracking.reminder.active')} :{' '}
          <b>
            {reminderTime.hour.toString().padStart(2, '0')}:{reminderTime.minute.toString().padStart(2, '0')}
          </b>
          <BellFilled style={{ color: AppColors.main }} />
        </GGButton>
      ) : (
        <GGButton
          onClick={showReminderModal}
          disabled={loadingReminderTime}
          style={isCallToAction ? {} : { color: AppColors.text.lightGray }}
        >
          {isCallToAction ? t('symptomTracking.reminder.callToAction') : t('symptomTracking.reminder.inactive')}{' '}
          <BellOutlined style={isCallToAction ? { color: AppColors.main } : {}} />
        </GGButton>
      )}
      <Modal
        open={showNotificationScheduleModal}
        onCancel={() => setShowNotificationScheduleModal(false)}
        footer={<span />}
      >
        {Platform.isNative() ? (
          <>
            {t('symptomTracking.reminder.setTimeQuestion')}
            <Row className={styles.notificationScheduleForm}>
              <Picker className={styles.reminderTimePicker} value={timePickerValue} onChange={setTimePickerValue}>
                <Picker.Column key="hour" name="hour">
                  {timePickerSelections.hour.map((option: number) => (
                    <Picker.Item key={`hour-${option}`} value={option}>
                      {option.toString().padStart(2, '0')}
                    </Picker.Item>
                  ))}
                </Picker.Column>
                <Picker.Column key="minute" name="minute">
                  {timePickerSelections.minute.map((option: number) => (
                    <Picker.Item key={`minute-${option}`} value={option}>
                      {option.toString().padStart(2, '0')}
                    </Picker.Item>
                  ))}
                </Picker.Column>
              </Picker>
            </Row>
            <Row className={styles.notificationScheduleForm}>
              {!doesUserHaveNotifications && (
                <NotificationSuggester text={t('symptomTracking.reminder.enableNotifications')} />
              )}
            </Row>
            <Row className={styles.notificationScheduleForm}>
              {areNotificationsEnabled ? (
                <GGButton type="primary" danger onClick={disableNotifications}>
                  {t('symptomTracking.reminder.disable')}
                </GGButton>
              ) : (
                <GGButton type="primary" disabled={!doesUserHaveNotifications} onClick={enableNotifications}>
                  {t('symptomTracking.reminder.enable')}
                </GGButton>
              )}
              {areNotificationsEnabled && (
                <GGButton
                  onClick={enableNotifications}
                  type="primary"
                  disabled={
                    timePickerValue.hour === reminderTime.hour && timePickerValue.minute === reminderTime.minute
                  }
                >
                  {t('symptomTracking.reminder.change')}
                </GGButton>
              )}
            </Row>
          </>
        ) : (
          <div>{t('symptomTracking.reminder.mobileOnly')}</div>
        )}
      </Modal>
    </>
  );
};

export default SymptomTrackingReminder;
