/* eslint-disable react/jsx-no-useless-fragment */
import { CheckOutlined, CloseOutlined, FrownFilled, SmileOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Empty, Modal, Tag } from 'antd';
import { AxiosError } from 'axios';
import SymptomTag from 'components/SymptomTag';
import AppColors from 'config/AppColors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ApiSymptomTrackingManager from 'services/api/ApiSymptomTrackingManager';

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    marginTop: '-15px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& h2': {
      marginBottom: 0,
    },
  },
  container: {
    height: '100%',
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: AppColors.backgroundGrey,
    overflowY: 'scroll',
  },
  profileCard: {
    margin: '10px auto',
    '& h4': {
      marginTop: 24,
      marginBottom: 4,
      color: AppColors.text.discrete,
    },
  },
  noAnswer: {
    fontStyle: 'italic',
  },
  personalInfo: {
    marginTop: 0,
    color: AppColors.text.discrete,
  },
  painTag: {
    padding: '8px 16px',
    fontSize: 15,
  },
  yesNoAnswer: {
    '& .anticon': {
      marginRight: 8,
    },

    '& .anticon-close': {
      color: AppColors.colorMap.veryBad,
    },
    '& .anticon-check': {
      color: AppColors.colorMap.veryGood,
    },
  },
  extraField: {
    fontStyle: 'italic',
    backgroundColor: AppColors.backgroundLightGrey,
    borderRadius: 8,
    padding: 8,
  },
});

export type PatientProfileModalProps = {
  patientId: string;
  patientName: string;
  open?: boolean;
  onClose?: () => void;
};

const PatientProfileModal: React.FC<PatientProfileModalProps> = ({
  patientId,
  patientName,
  open = false,
  onClose = undefined,
}) => {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();

  // State
  const [isModalOpen, setIsModalOpen] = useState(open);

  const { data: survey, loading: loadingOnboardingSurvey } = useRequest(
    () => ApiSymptomTrackingManager.getOnboardingSurvey(patientId),
    {
      onError: error => {
        // ignore 404 errors as not all users have filled out the onboarding survey
        if (!(error instanceof AxiosError && error.response?.status === 404)) {
          console.error(error);
        }
      },
    },
  );

  const { data: file, loading: loadingPatientFile } = useRequest(
    () => ApiSymptomTrackingManager.getPatientFile(patientId),
    {
      onError: error => {
        // ignore 404 errors as not all users have filled out the patient file
        if (!(error instanceof AxiosError && error.response?.status === 404)) {
          console.error(error);
        }
      },
    },
  );

  const handleOk = () => {
    setIsModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      loading={loadingOnboardingSurvey || loadingPatientFile}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={t('global.buttons.close')}
      closable={false}
    >
      <div className={styles.pageContainer}>
        {(!survey || !file) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('patientProfile.noData')} />}
        {survey && file && (
          <div className={styles.profileCard}>
            <h2>{patientName}</h2>
            <p className={styles.personalInfo}>
              {t('patientProfile.bornIn', { year: file.birth_year, age: new Date().getFullYear() - file.birth_year })}
            </p>

            <h4>{t('patientProfile.feels_pain_or_discomfort')}</h4>
            {survey.feels_pain_or_discomfort ? (
              <Tag className={styles.painTag} color={AppColors.colorMap.veryBad} icon={<FrownFilled />}>
                {t('patientProfile.yes')}
              </Tag>
            ) : (
              <Tag className={styles.painTag} color={AppColors.colorMap.veryGood} icon={<SmileOutlined />}>
                {t('patientProfile.no')}
              </Tag>
            )}

            <h4>{t('patientProfile.symptoms')}</h4>
            {survey.symptoms.map(symptom => (
              <SymptomTag key={symptom} symptom={symptom} />
            ))}
            {survey.extra_symptoms && <p className={styles.extraField}>{survey.extra_symptoms}</p>}
            {survey.symptoms.length === 0 && !survey.extra_symptoms && (
              <p className={styles.noAnswer}>{t('patientProfile.noSymptoms')}</p>
            )}

            <h4>{t('patientProfile.pathologies')}</h4>
            {survey.pathologies.map(pathology => (
              <Tag key={pathology}>{t(`onboarding.questionnaire.pathologies.options.${pathology}`)}</Tag>
            ))}
            {survey.extra_pathologies && <p className={styles.extraField}>{survey.extra_pathologies}</p>}
            {survey.pathologies.length === 0 && !survey.extra_pathologies && (
              <p className={styles.noAnswer}>{t('patientProfile.noPathologies')}</p>
            )}

            <h4>{t('patientProfile.expectations')}</h4>
            {survey.expectations.map(expectation => (
              <Tag key={expectation}>{t(`onboarding.questionnaire.expectations.options.${expectation}`)}</Tag>
            ))}
            {survey.extra_expectations && <p className={styles.extraField}>{survey.extra_expectations}</p>}
            {survey.expectations.length === 0 && !survey.extra_expectations && (
              <p className={styles.noAnswer}>{t('patientProfile.noExpectations')}</p>
            )}

            <h4>{t('patientProfile.questions')}</h4>
            {survey.questions.map(question => (
              <Tag key={question}>{t(`onboarding.questionnaire.questions.options.${question}`)}</Tag>
            ))}
            {survey.extra_questions && <p className={styles.extraField}>{survey.extra_questions}</p>}
            {survey.questions.length === 0 && !survey.extra_questions && (
              <p className={styles.noAnswer}>{t('patientProfile.noQuestions')}</p>
            )}

            <h4>{t('patientProfile.file')}</h4>
            <p className={styles.yesNoAnswer}>
              {file.takes_contraception ? (
                <>
                  <CheckOutlined />
                  {t('patientProfile.takesContraception')}
                </>
              ) : (
                <>
                  <CloseOutlined />
                  {t('patientProfile.takesNoContraception')}
                </>
              )}
            </p>
            <p className={styles.yesNoAnswer}>
              {file.has_children ? (
                <>
                  <CheckOutlined />
                  {t('patientProfile.hasChildren')}
                </>
              ) : (
                <>
                  <CloseOutlined />
                  {t('patientProfile.hasNoChildren')}
                </>
              )}
            </p>
            <p className={styles.yesNoAnswer}>
              {file.is_pregnant ? (
                <>
                  <CheckOutlined />
                  {t('patientProfile.isPregnant')}
                </>
              ) : (
                <>
                  <CloseOutlined />
                  {t('patientProfile.isNotPregnant')}
                </>
              )}
            </p>
            <p className={styles.yesNoAnswer}>
              {file.is_trying_to_conceive ? (
                <>
                  <CheckOutlined />
                  {t('patientProfile.isTryingToConceive')}
                </>
              ) : (
                <>
                  <CloseOutlined />
                  {t('patientProfile.isNotTryingToConceive')}
                </>
              )}
            </p>
            <p className={styles.yesNoAnswer}>
              {file.is_taking_treatment ? (
                <>
                  <CheckOutlined />
                  {t('patientProfile.isTakingTreatment')}
                </>
              ) : (
                <>
                  <CloseOutlined />
                  {t('patientProfile.isNotTakingTreatment')}
                </>
              )}
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default PatientProfileModal;
