import { Button, ButtonProps } from 'antd';
import AppColors from 'config/AppColors';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: (props: ButtonProps) => ({
    borderRadius: 5,
    border: 'none',
    boxShadow:
      props.type === 'dashed' || props.type === 'text' || props.type === 'link'
        ? 'none'
        : '0px 5px rgba(100, 100, 100, 0.2)',
    ...(props.color === 'discrete' ? { backgroundColor: AppColors.backgroundLightGrey } : {}),
    ...(props.color === 'secondary' ? { backgroundColor: AppColors.backgroundGrey } : {}),
  }),
});

export default function GGButton(props: ButtonProps) {
  const styles = useStyles(props);

  return <Button {...props} className={styles.button} />;
}
