import { useRequest } from 'ahooks';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

export default function useSecretStorage(key: string, onGetSuccess: (value: any) => void = () => {}) {
  const parseValue = (value: string) => JSON.parse(value || '{}');

  const {
    run: get,
    data: rawValue,
    loading,
  } = useRequest(() => SecureStoragePlugin.get({ key }), {
    onSuccess: result => {
      onGetSuccess(parseValue(result.value));
    },
    onError: error => {
      // web version of the error
      if (typeof error === 'string' && error === 'Item with given key does not exist') return;
      // native version of the error
      if (error.message === 'Item with given key does not exist') return;
      console.error(error);
    },
  });
  const { run: set } = useRequest(newValue => SecureStoragePlugin.set({ key, value: JSON.stringify(newValue) }), {
    manual: true,
    onSuccess: () => {
      get();
    },
  });

  const value = rawValue !== undefined ? parseValue(rawValue?.value) : undefined;

  return {
    get,
    set,
    loading,
    value,
  };
}
