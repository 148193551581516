const supportEmail = 'support@gyngercare.com';
const codeRequestEmail = 'code-request@gyngercare.com';

const fr = {
  appName: 'Gynger',
  appTagline: "L'app de suivi de santé des femmes",
  legal: {
    website: 'https://gyngercare.com/',
    termsAndPrivacy: 'https://gyngercare.com/mentionslegales/',
  },
  social: {
    instagram: 'https://www.instagram.com/gynger.care/',
    linkedIn: 'https://www.linkedin.com/company/gynger-care/',
  },
  global: {
    user: {
      me: 'Moi',
    },
    support: {
      supportEmail,
      supportEmailWithSubject: `${supportEmail}?subject=Demande de support`,
      codeRequestEmailWithSubject: `${codeRequestEmail}?subject=Demande de code&body=Bonjour, j'aimerais créer un compte sur Gynger mais mon entreprise n'est pas partenaire. Pouvez-vous m'aider à obtenir un code ?`,
    },
    errors: {
      authentication: {
        title: 'Non autorisé',
        message: `Une erreur s'est produite lors de votre connexion. Veuillez recharger la page ou contacter le support à l'adresse ${supportEmail}, si le problème persiste.`,
      },
      '403': {
        title: 'Non autorisé',
        message: `Désolé, vous n'êtes pas autorisé à accéder à cette page. Veuillez contacter le support à l'adresse ${supportEmail}, si vous pensez que c'est une erreur.`,
      },
      '404': {
        title: 'Page non trouvée',
        message: `Désolé, la page que vous avez visitée n'existe pas. Veuillez contacter le support à l'adresse ${supportEmail}, si vous pensez que c'est une erreur.`,
      },
      unknown: 'Inconnu',
    },
    buttons: {
      cancel: 'Annuler',
      ok: 'OK',
      reset: 'Réinitialiser',
      update: 'Modifier',
      close: 'Fermer',
    },
    filters: {
      search: 'Filtrer',
      clear: 'Effacer les filtres',
    },
  },
  settings: {
    chatsSection: 'Messagerie',
    periodQuestions: 'Me poser des questions sur mes règles',
    cancel: 'Annuler',
    chats: {
      emailNotifications: 'Recevoir des notifications de messages par email',
      emailNotificationsDescription: "Téléchargez l'application mobile pour une meilleure expérience de notifications",
    },
    accountSection: 'Mon compte',
    license: {
      currentLicense: 'Code',
      newLicense: 'Nouveau code',
      changeLicense: 'Modifier',
      saveLicense: 'Enregistrer',
      codeChanged: 'Code de license mis à jour',
      placeholder: 'Entrez votre nouveau code ici',
      errors: {
        invalidLicenseCode: "Ce code n'est pas valide",
        contractFull: `Le quota de licenses pour ce code est atteint, veuillez contacter le support au ${supportEmail}, ou votre entreprise ou mutuelle.`,
        default: "Une erreur s'est produite lors de la mise à jour du code de license",
      },
    },
  },
  caregiverGroups: {
    'Dieteticiens-Nutritionnistes': 'Diététicien-Nutritionniste',
    Radiologues: 'Radiologue',
    Ostheopates: 'Ostéopathe',
    Gynecologues: 'Gynécologue',
    'Gynecologues-obstetriciens': 'Gynécologue obstétricien',
    Urologues: 'Urologue',
    Kinesitherapeutes: 'Kinésithérapeute',
    Algologues: 'Algologue',
    Naturopathes: 'Naturopathe',
    Sophrologues: 'Sophrologue',
    'Sages-femmes': 'Sage-femme',
    Psychologues: 'Psychologue',
    'Gynger-feedback': 'Feedback Gynger',
    Sexologues: 'Sexologue',
    'Medecins-Generalistes': 'Médecin généraliste',
    'Internes-gynecologie': 'Interne en gynécologie',
  },
  menu: {
    home: 'Accueil',
    messagingHome: 'Messages',
    conversation: 'Conversations',
    newChat: 'Nouvelle question',
    settings: 'Paramètres',
    notFound: 'Page non trouvée',
    unauthorized: 'Non autorisé',
    login: 'Connexion',
    register: 'Création de compte',
    passwordReset: 'Réinitialisation du mot de passe',
    logout: 'Déconnexion',
    onboarding: 'Découvrir Gynger',
    moreInfo: 'Une question ?',
    support: 'Support',
    info: "M'informer",
    caregiversDirectory: 'Experts',
    caregiverProfile: 'Profil',
    tracking: 'Mon suivi',
  },
  home: {
    greeting: 'Bonjour {{firstName}} !',
    pageTitle: 'Gynger',
    trackingCallToAction: 'Mon carnet de suivi',
    messages: {
      title: 'Messagerie',
      description: 'Nos soignants vous répondent sous 24 heures',
      callToAction: 'Découvrir',
      goToMessages: 'Ouvrir la messagerie',
      waitingForMe: 'En attente',
      inProgress: 'En cours',
      waitingForReassignment: 'En redirection',
    },
    tracking: {
      title: 'Mon suivi',
      description: 'Un suivi quotidien pour comprendre votre corps et réduire vos symptômes',
      fillSurvey: 'Remplir mon suivi quotidien',
      goToTracking: 'Mon suivi',
      graphLabel: 'Bien-être général',
    },
    info: {
      title: "M'informer",
      showMore: 'Voir tous les articles',
    },
  },
  loginRegister: {
    title: {
      login: 'Connexion à Gynger',
      register: 'Créez un compte Gynger',
    },
    description: {
      login: 'Bienvenue sur Gynger ! Renseignez vos informations de connexion pour vous authentifier.',
      register: 'Créez un compte avec votre mot de passe, définissez un mot de passe et renseignez le code.',
      registerAskIfCode: "Avez-vous un code d'accès à Gynger ?",
      registerNoCode:
        "Pour l'instant, l'accès à Gynger requiert un code fourni par les employeurs ou mutuelles partenaires de Gynger.",
    },
    linkToSite: {
      tagline: 'Gynger, la messagerie gynécologique pour toutes.',
      link: "Cliquez ici pour plus d'informations.",
    },
    fields: {
      email: {
        title: 'E-mail',
        placeholder: 'E-mail',
        errors: {
          notEmail: "Format d'email invalide",
        },
      },
      password: {
        title: 'Mot de passe',
        placeholder: 'Mot de passe',
      },
      passwordConfirmation: {
        title: 'Confirmation du mot de passe',
        placeholder: 'Confirmation du mot de passe',
        errors: {
          required: 'Confirmez votre mot de passe',
          passwordsNotMatch: 'Vos mots de passe ne correspondent pas',
        },
      },
      firstName: {
        title: 'Prénom',
        placeholder: 'Prénom',
        errors: {
          required: 'Le prénom est requis',
        },
      },
      lastName: {
        title: 'Nom',
        placeholder: 'Nom de famille',
        errors: {
          required: 'Votre nom de famille est requis',
        },
      },
      licenseCode: {
        title: 'License',
        placeholder: 'Code de la license fourni par votre partenaire ou Gynger',
        errors: {
          required: 'Le code de la license est requis',
        },
      },
      tc_and_privacy_policy_consent: {
        title: "J'ai lu et j'accepte les CGU et la politique de confidentialité",
        errors: {
          required: 'Vous devez accepter',
        },
      },
      personal_information_access_consent: {
        title:
          "Je consens à ce que les informations que je transmets sur la messagerie puissent être lues par l'ensemble des soignants de Gynger Care",
        errors: {
          required: 'Vous devez accepter',
        },
      },
      email_notifications_consent: {
        title: "J'accepte de recevoir des notifications par email de la part de Gynger Care",
      },
    },
    actions: {
      login: 'Connexion',
      register: 'Création de compte',
      alreadyRegistered: 'Déjà un compte ? Authentifiez-vous avec votre email',
      noAccount: 'Pas de compte ? Créez un compte avec votre email',
      passwordReset: 'Mot de passe oublié ?',
      iHaveACode: "J'ai un code d'accès",
      iDontHaveACode: "Je n'ai pas de code d'accès",
      understoodNoCode: "J'ai compris",
    },
    errors: {
      login: `Une erreur s'est produite lors de la connexion. Merci de contacter ${supportEmail}`,
      register: `Une erreur s'est produite lors de la création de votre compte. Merci de contacter ${supportEmail}`,
      unauthorized: `E-mail ou mot de passe non reconnu. Si vous rencontrez des problèmes, contactez ${supportEmail}`,
      disconnected: `Vous avez été déconnecté(e). Veuillez vous reconnecter.`,
    },
  },
  passwordReset: {
    title: 'Réinitialisation du mot de passe',
    description: {
      sendEmail:
        'Remplissez ce formulaire avec votre email. Un lien vous sera envoyé pour réinitialiser votre mot de passe',
      reset: 'Utilisez ce formulaire pour réinitialiser votre mot de passe. Définissez un nouveau mot de passe',
    },
    fields: {
      email: {
        title: 'E-mail',
        placeholder: 'E-mail',
        errors: {
          notEmail: "Format d'email invalide",
        },
      },
      password: {
        title: 'Mot de passe',
        placeholder: 'Mot de passe',
        errors: {
          required: 'Définissez un nouveau mot de passe',
          minLength: 'Votre mot de passe doit contenir au moins 8 caractères',
          passwordsNotMatch: 'Vos mots de passe ne correspondent pas',
        },
      },
      passwordConfirmation: {
        title: 'Confirmation du mot de passe',
        placeholder: 'Confirmation du mot de passe',
        errors: {
          required: 'Confirmez votre nouveau mot de passe',
          minLength: 'Votre mot de passe doit contenir au moins 8 caractères',
          passwordsNotMatch: 'Vos mots de passe ne correspondent pas',
        },
      },
    },
    actions: {
      sendEmail: 'Envoyer le lien',
      reset: 'Réinitialiser',
    },
    success: {
      sendEmail:
        'Un email vous a été envoyé. Cliquez sur le lien que vous avez reçu pour réinitialiser votre mot de passe',
      reset: 'Votre mot de passe a été réinitialisé',
    },
    errors: {
      sendEmail: `Une erreur s'est produite lors de l'envoi du lien. Merci de contacter ${supportEmail}`,
      reset: `Une erreur s'est produite lors de la réinitialisation de votre mot de passe. Merci de contacter ${supportEmail}`,
    },
  },
  caregivers: {
    list: {
      errors: {
        fetch: "Une erreur s'est produite lors du chargement de la liste des spécialistes",
      },
    },
    actions: {
      requestOwnerChange: {
        success: 'La conversation a été transférée à un spécialiste',
        error: "Une erreur s'est produite lors du transfert de la conversation à un spécialiste",
      },
      statusChange: {
        success: 'Le statut de la conversation a été mis à jour.',
        error: "Une erreur s'est produite lors du changement de statut de la conversation",
      },
      selfAssign: {
        success: 'Vous vous êtes assigné la conversation !',
        error: "Une erreur s'est produite lors du transfert de la conversation vers vous-même",
      },
    },
  },
  patientProfile: {
    feels_pain_or_discomfort: 'Ressentez-vous des douleurs ou des inconforts ?',
    yes: 'Oui',
    no: 'Non',
    noData: 'Dossier non renseigné',
    symptoms: 'Symptômes ressentis',
    noSymptoms: 'Aucun symptôme ressenti',
    pathologies: 'Pathologies diagnostiquées',
    noPathologies: 'Aucune pathologie diagnostiquée',
    expectations: 'Attentes envers Gynger',
    noExpectations: 'Aucune attente',
    questions: 'Sujets qui posent question',
    noQuestions: 'Aucune question',
    file: 'Dossier patiente',
    bornIn: 'Née en {{year}} ({{age}} ans)',
    takesContraception: 'Prend une contraception',
    takesNoContraception: 'Ne prend pas de contraception',
    hasChildren: 'A des enfants',
    hasNoChildren: "N'a pas d'enfants",
    isPregnant: 'Est enceinte',
    isNotPregnant: "N'est pas enceinte",
    isTryingToConceive: "Essaye d'avoir des enfants",
    isNotTryingToConceive: "N'essaye pas d'avoir des enfants",
    isTakingTreatment: 'Prend un traitement',
    isNotTakingTreatment: 'Ne prend pas de traitement',
  },
  chats: {
    title: 'Messagerie',
    loadingPage: {
      message: 'Chargement de la messagerie...',
    },
    explainer: {
      title: 'Messagerie',
      youWantMedicalAdviceBut: 'Vous cherchez un avis médical mais...',
      gynecologistIsNotAvailable: "Votre gynécologue n'est <bold>pas disponible</bold> avant plusieurs mois",
      youAreNotSureItsWorthIt: "Vous n'êtes <bold>pas sûre</bold> qu'une consultation en vaille la peine",
      youDontWantToBeIgnored: "Vous ne <bold>voulez plus</bold> qu'on ignore vos symptômes",
      gyngerTransformsYourAccess: 'Gynger transforme votre accès aux soignants.',

      goodPathMainText1: 'Dites-nous ce qui vous amène',
      goodPathSecondaryText1: 'Une question de quelques mots suffit pour commencer !',
      goodPathMainText2: 'Votre sage-femme référente vous prend en charge sous 24 heures',
      goodPathSecondaryText2: 'Elle vous demandera des précisions si nécessaire.',
      goodPathMainText3: 'Parlez à des spécialistes',
      goodPathSecondaryText3:
        'Nutritionniste, gynécologue, sophrologue, sexologue... si nécessaire, votre sage-femme les rajoutera dans la conversation.',
      goodPathMainText4: 'Échangez aussi longtemps que nécessaire',
      goodPathSecondaryText4:
        'Simple question sur la contraception, ou accompagnement pour l’endométriose, notre équipe s’adapte à vous.',
      noChatbotHereTitle: 'Chez nous, pas de chatbot IA !',
      noChatbotHereText:
        'Toutes nos réponses sont écrites par des soignants spécialisés en santé des femmes, bienveillants et disponibles.',

      howCanWeHelpYou: 'Comment pouvons-nous vous aider, {{firstName}} ?',
      noInspiration: 'Pas d’inspiration pour ce premier message ?',
      inCaseOfEmergency: 'En cas d’urgence, contactez le 15 ou le 112.',
      firstMessageModal: `
        <p>Ça peut être impressionnant de faire le premier pas, c'est normal !</p>
        <p>Faites comme si vous arriviez chez votre médecin et que vous deviez répondre à une question simple:</p>
        <quote>Qu'est-ce qui vous amène ?</quote>
        <ul>
          <li>
            <strong>vous pouvez être brève</strong> : si les soignants Gynger ont besoin d'informations supplémentaires,
            ils vous les demanderont.
          </li>
          <li>
            <strong>vous pouvez être plus longue</strong> : si votre situation est complexe, vous pouvez la détailler
          </li>
        </ul>
        <p>
          De manière générale, n'hésitez pas à décrire votre <strong>situation</strong> (douleurs, symptômes, pathologies éventuelles, ...) et votre 
          <strong>objectif</strong> (comprendre et/ou diminuer les symptômes, savoir si vous devez consulter, avoir un
          deuxième avis, etc.).
        </p>
        <p>Tout premier message est valide et trouvera une réponse, mais voici quelques exemples:</p>
      `,
    },
    goToExplainer: 'Comment fonctionne la messagerie ?',
    subtitle: {
      unassigned: 'Demandes de groupe',
      myRequest: 'Mes demandes',
      waitingForReassignment: 'En attente',
      assigned: 'Mes conversations',
      closed: 'Fermées',
      onHold: 'En pause',
      history: 'Historique',
      summary: 'Notes',
    },
    noNotificationsCTA: 'Soyez notifié(e) de nos réponses',
    caregivers: {
      first: 'Marion, Sage-femme',
      second: 'Aurore, Sage-femme',
      third: 'Laurine, Sage-femme',
    },
    actions: {
      transferTo: 'Orienter vers',
      summary: 'Notes',
      history: 'Historique',
      selfAssign: 'Prendre ce patient en charge',
      initiateConversation: 'Je parle à un soignant',
      button: 'Actions',
      putOnHold: 'Mettre en pause',
      close: 'Fermer',
      openNewConversation: 'Ouvrir une nouvelle conversation',
    },
    fields: {
      message: {
        placeholder: 'Tapez votre message ...',
      },
    },
    noCaregiver: {
      avatar: 'NA',
      name: {
        forPatient: 'Équipe Gynger',
        forCaregiver: 'Équipe Gynger',
      },
      extraConversationMessage: {
        forPatient:
          "Merci pour votre message, les soignants Gynger vous répondront au plus vite !\n\nS'il s'agit d'une urgence, contactez le 15 ou le 112.\n\nSi vous avez d'autres questions, n'hésitez pas à les poser dans cette conversation.",
      },
    },
    noLastMessage: 'Pas encore de messages',
    summary: {
      noSummary: "L'équipe de soignants n'a pas encore entré de notes pour cette conversation.",
      save: 'Sauver',
      caregiverPublicWarning: 'Attention ! Ces notes sont visibles par les patientes',
      summaryPlaceholder:
        "Entrez ici la situation de la patiente et les recommandations faites par l'équipe de soignants",
      summarySaved: 'Notes mises à jour',
      summarySaveError: "Une erreur s'est produite lors de l'envoi des notes",
      summaryLoadError: "Une erreur s'est produite lors du chargement des notes",
    },
    list: {
      noChatsTitle: 'Pas de conversation pour le moment',
      noChats: {
        forPatient:
          'Pas encore de conversations.\n\nPosez vos questions à notre équipe de soignantes et soignants spécialisés en santé des femmes !',
        forCaregiver: "Pas de conversations pour le moment. Veuillez attendre qu'un patient pose une question",
      },
      errors: {
        fetch: "Une erreur s'est produite lors du chargement de vos conversations",
        sendMessage: "Une erreur s'est produite lors de l'envoi de votre message",
      },
    },
    new: {
      titleAskQuestion: 'Poser une question',
      titleHealthCheckUp: 'Faire le bilan',
      introMessageAskQuestion: `Bonjour, \n\n Posez-nous vos questions ici, l'équipe de soignants Gynger vous répondra au plus vite!\n\nS'il s'agit d'une urgence, contactez le 15 ou le 112.`,
      introMessageHealthCheckUp:
        "Bonjour,\n\nDécrivez-nous ici la raison de votre venue sur Gynger, et nous allons vous mettre en relation sous 24 heures avec votre sage-femme référente.\n\nElle vous aidera à dresser votre bilan santé, et nous ferons ensuite de notre mieux pour vous aider à aller mieux !\n\nS'il s'agit d'une urgence, contactez le 15 ou le 112.",
      introMessageSenderId: 'Gynger-User-Id',
      introMessageSenderName: 'Gynger',
      exampleMessages: [
        'Mes douleurs menstruelles augmentent depuis plusieurs mois, que faire ?',
        "Quels conseils nutrition avez-vous si je suis atteinte d'endométriose ?",
        'Comment augmenter mes chances de tomber enceinte ?',
        'Comment augmenter ma libido ?',
        'Comment trouver la bonne contraception ?',
        'Pourquoi ai-je des douleurs abdominales ?',
        'Quels vaccins dois-je réaliser pour mes enfants ?',
        "Comment savoir si j'approche de la ménopause ?",
      ],
    },
    dashboardView: {
      topicToCover: "Si vous avez d'autres questions, n'hésitez pas à les poser dans votre conversation existante !",
      caregiversTeam: "L'équipe de soignants",
      morePain: 'Mes douleurs menstruelles augmentent depuis plusieurs mois, que faire ?',
      whatIsGynger: 'Une question sur Gynger ?',
      anyQuestion: 'Une question, un problème ponctuel ou récurrent ?',
      quickAnswer: 'Notre équipe de soignants vous répond sous 24 heures.',
    },
  },
  webSockets: {
    messages: {
      error: "Une erreur s'est produite, vous devrez peut-être recharger la page pour voir les derniers messages",
      connecting: 'Connexion à notre système de messagerie ...',
      connected: 'Vous recevrez les derniers messages',
    },
  },
  infoPage: {
    description: `Ces articles sont proposés par nos soignants pour mieux comprendre votre santé et améliorer votre bien-être.\nNous en publions régulièrement!`,
    goToCaregivers: 'Trouver un soignant',
    recentlyRead: 'Récents',
    noPosts: 'Aucun article trouvé',
    search: {
      placeholder: 'Rechercher',
    },
    article: {
      relatedPosts: 'Aller plus loin',
      writtenBy: 'Écrit par {{author}} le {{date}}',
      chatCallToAction: {
        title: 'Besoin d’un avis médical ?',
        description: 'Notre équipe vous répond sous 24 heures.',
      },
    },
  },
  caregiversDirectory: {
    title: "Annuaire d'experts",
    infoContact: 'Info contact',
    description: `Trouvez des experts en santé des femmes proches de chez vous avec cet annuaire`,
    errors: {
      list: "Une erreur s'est produite lors du chargement de la liste des experts",
      noCaregiversFound: 'Aucun expert trouvé avec ces filtres',
    },
    filters: {
      title: 'Filtres',
      department: 'Département',
      caregiverGroup: 'Spécialisation',
    },
  },
  caregiverProfile: {
    title: 'Profil',
    errors: {
      fetch: "Une erreur s'est produite lors du chargement du profil de cet expert",
    },
  },
  onboarding: {
    carousel: {
      title: 'Bienvenue sur ',
      titleEnd: ' !',
      pages: {
        one: {
          text1start: 'Une équipe de soins ',
          text1Bold1: 'pluridisciplinaire',
          text1middle: ' et ',
          text1Bold2: 'spécialisée',
          text1end: ' en santé des femmes',
          text2start: 'Pour répondre à toutes vos questions ou vous accompagner à long terme via une messagerie ',
          text2Bold1: 'rapide',
          text2middle: ' et ',
          text2Bold2: 'sécurisée',
        },
        tracking: {
          textStart: 'Un outil de suivi pour ',
          textBold1: 'mieux comprendre',
          textMiddle: ' votre corps, vos symptômes et ',
          textBold2: 'réduire',
          textEnd: ' leur impact sur votre quotidien',
        },
        two: {
          textStart: 'Des ',
          textBold1: 'articles',
          textMiddle1: ' écrits par nos ',
          textBold2: 'professionnels',
          textMiddle2: ' pour ',
          textBold3: 'découvrir',
          textMiddle3: ' et mieux ',
          textBold4: 'comprendre',
        },
        three: {
          textStart: 'Un ',
          textBold1: 'annuaire',
          textMiddle1: ' de soignants spécialisés en santé des femmes ',
          textBold2: 'proches',
          textEnd: ' de chez vous',
        },
        four: {
          cards: {
            questions: 'Je pose une question à un soignant',
            track: 'Je commence mon carnet de suivi',
            check: 'Je fais un bilan avec ma sage-femme',
            learn: 'Je veux en apprendre plus',
            expert: 'Je cherche un expert près de chez moi',
          },
        },
      },
      buttons: {
        next: "Ce n'est pas tout !",
        end: 'Continuer',
      },
    },
    questionnaire: {
      greeting: 'Bienvenue',
      description: 'Pour mieux vous aider, nous avons quelques questions à vous poser.',
      start: "C'est parti !",

      yes: 'Oui',
      no: 'Non',
      continue: 'Continuer',
      thankYou: 'Merci',
      congratulations: 'Bravo',
      later: 'Plus tard',

      painQuestion: 'Ressentez-vous des douleurs ou des inconforts ?',

      symptoms: {
        question: 'Avez-vous des symptômes ?',
        description: 'Ils peuvent être récurrents ou ponctuels.',
        otherSymptoms: 'Autres...',
        saveSymptoms: 'Continuer',
        noSymptoms: 'Je ne ressens aucun symptôme',
        otherSymptomsPlaceholder: "Entrez ici d'autres symptômes ou des précisions sur vos symptômes",
      },

      pathologies: {
        question: "Avez-vous été diagnostiquée d'une pathologie ?",
        otherPathologies: 'Autres...',
        savePathologies: 'Continuer',
        noPathologies: 'Je ne pense pas',
        otherPathologiesPlaceholder: "Entrez ici d'autres pathologies ou des précisions sur vos pathologies",
        options: {
          // TODO translations
          ENDOMETRIOSIS: 'Endométriose',
          SOPK: 'SOPK',
          FIBROME: 'Fibrome',
          ADENOMYOMATOSIS: 'Adénomyose',
          BREAST_CANCER: 'Cancer du sein',
          UTERUS_CANCER: "Cancer du col de l'utérus",
          PMS: 'Syndrome pré-menstruel',
        },
      },

      expectations: {
        question: 'Que souhaitez-vous obtenir de Gynger ?',
        otherExpectations: 'Autre...',
        saveExpectations: 'Continuer',
        otherExpectationsPlaceholder: 'Expliquez-nous comment nous pouvons vous aider !',
        noExpectations: 'Je ne sais pas',
        options: {
          ADVICE: 'Des conseils pour aller mieux',
          ANSWERS: 'Des réponses à mes questions',
          SPEAK_TO_SPECIALIST: 'Parler à un spécialiste',
          UNDERSTAND_SYMPTOMS: 'Suivre et comprendre mes symptômes',
          SECOND_OPINION: 'Un second avis',
          RELIABLE_INFORMATION: 'Des informations fiables',
        },
      },

      questions: {
        question: 'Avez-vous des questions sur les sujets suivants ?',
        otherQuestions: 'Autre...',
        saveQuestions: 'Continuer',
        noQuestions: 'Aucun de ces sujets',
        otherQuestionsPlaceholder: 'Symptômes inhabituels, douleurs, ...',
        options: {
          CONTRACEPTION: 'Contraception',
          MENSTRUATION: 'Règles',
          FERTILITY: 'Fertilité',
          MENOPAUSE: 'Ménopause',
          PATHOLOGIES: 'Pathologies',
          TREATMENTS: 'Traitements',
          SEXUALITY: 'Sexualité',
        },
      },

      firstThankYou: 'Merci pour vos réponses',
      postProductSurveyDescription:
        "Notre équipe de soignants peut vous accompagner, il ne vous reste plus qu'à remplir votre dossier.",
      file: {
        title: 'Votre dossier',
        birthYear: 'Année de naissance',
        birthYearPlaceholder: 'Sélectionner',
        doYouTakeContraception: 'Prenez-vous une contraception ?',
        doYouHaveChildren: 'Avez-vous des enfants ?',
        areYouPregnant: 'Êtes-vous enceinte ?',
        areYouTryingToConceive: "Essayez-vous d'avoir des enfants ?",
        areYouTakingAnyMedication: 'Prenez-vous un traitement ?',
        requiredField: 'Veuillez sélectionner une option',
      },
      postFile: {
        description: 'Vous avez fait le premier pas vers une <bold>meilleure santé</bold>.',
        letsGo: 'Continuons ensemble !',
      },
    },
  },
  faq: {
    subtitle: "Gynger est l'application de suivi de santé des femmes pour toutes.",
    networks: 'Retrouvez-nous sur les réseaux !',
    questions: 'Vous avez une question ?',
    videoUrl: 'https://www.youtube.com/embed/dTqWv8YUrH8?si=tJuZJin_y8v24Ji0',
  },
  symptomTracking: {
    title: 'Mon suivi',
    description:
      "Comprenez mieux votre corps, la régularité des symptômes et des douleurs, l'impact de votre nutrition ou de vos traitements en suivant votre santé au quotidien.",
    graphs: {
      generalState: 'État général',
      menstruation: 'Menstruations',
      pain: 'Douleurs',
      symptoms: 'Symptômes',
      notes: 'Notes',
      otherSymptoms: 'Autres symptômes et précisions',
    },
    reminder: {
      active: 'Mon rappel',
      inactive: 'Aucun rappel configuré',
      setTimeQuestion: 'À quelle heure souhaitez-vous recevoir votre rappel quotidien ?',
      enableNotifications: 'Activez les notifications pour recevoir votre rappel',
      enable: 'Activer',
      disable: 'Désactiver',
      change: 'Modifier',
      callToAction: 'Activer mon rappel',
      mobileOnly:
        "Ce rappel est uniquement disponible sur notre application mobile, que vous pouvez télécharger depuis l'App Store ou le Play Store.",
    },
    notification: {
      dailyReminder: "Bonjour {{name}}, comment vous sentez-vous aujourd'hui ?",
    },
    actions: {
      fillDailySurvey: 'Remplir mon suivi quotidien',
      editDailySurvey: 'Modifier',
    },
    noDataForDay: 'Aucun suivi pour cette journée',
    survey: {
      greeting: 'Bonjour',
      howAreYouToday: "Quel est votre état général aujourd'hui ?",
      howWereYou: 'Quel était votre état général le {{date}} ?',
      loading: 'Chargement...',

      // États généraux
      generalState: {
        veryGood: 'Très bon',
        good: 'Bon',
        average: 'Moyen',
        bad: 'Mauvais',
        veryBad: 'Très mauvais',
      },

      menstruation: {
        periodQuestion: 'Êtes-vous en période de règles ?',
        yes: 'Oui',
        no: 'Non',
        noPeriod: "Je n'ai pas/plus de règles",
        skipPeriodWarning:
          'Nous ne vous poserons plus la question, mais vous pouvez toujours la réactiver dans les paramètres.',
        understood: "J'ai compris !",

        // Quantité de menstruations
        menstruationAmount: "Quel est l'intensité de vos règles ?",
        low: 'Faibles',
        medium: 'Moyennes',
        high: 'Abondantes',
        veryHigh: 'Très abondantes',
      },

      // Niveau de douleur
      pain: {
        painQuestion: 'Avez-vous ressenti des douleurs ?',
        none: 'Aucune',
        light: 'Légères',
        moderate: 'Modérées',
        strong: 'Fortes',
        intense: 'Intenses',
      },

      // Autres symptômes
      symptoms: {
        otherSymptoms: "Avez-vous d'autres symptômes ?",
        FATIGUE: 'Fatigue',
        DIGESTIVE: 'Troubles digestifs',
        URINARY: 'Troubles urinaires',
        SEXUAL: 'Troubles sexuels',
        BLEEDING: 'Saignements inhabituels',
        MOOD: "Troubles d'humeur",
        MENTAL: 'Troubles mentaux',
        DIZZINESS: 'Vertiges',
        other: 'Autre...',
        otherSymptomsDescription: "Renseignez ici d'autres symptômes ou des précisions sur vos symptômes",
        saveSymptoms: 'Enregistrer mes symptômes',
        noSymptoms: "Je n'ai ressenti aucun symptôme",
      },

      reminder: {
        question: 'Voulez-vous activer un rappel pour remplir votre suivi ?',
        explain: 'Un suivi régulier vous permettra de mieux comprendre votre corps et vos symptômes.',
        noThanks: 'Pas tout de suite',
        doNotAskAgain: 'Ne plus me le demander',
      },

      // Commentaires
      addComment: 'Voulez-vous ajouter des notes ou des commentaires ?',
      freeTextDescription:
        'Entrez ici vos notres libres sur votre nutrition, vos traitements, votre ressenti ou tout autre chose...',
      saveFollowUp: 'Enregistrer mon suivi',
      missingAnswers: 'Veuillez répondre à toutes les questions pour enregistrer votre suivi',
    },
  },
};

export default fr;
