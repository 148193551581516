import { Tabs } from 'antd';
import AppColors from 'config/AppColors';
import { locale } from 'locales/i18nManager';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DailyStatus } from 'services/api/models/SymptomTrackingDTO';

export type CalendarTabsProps = {
  dailyStatuses: DailyStatus[];
};

const useStyles = createUseStyles({
  dailyCommentTabs: {
    width: '100%',
    overflowX: 'scroll',

    '& .ant-tabs-tab': {
      backgroundColor: 'white !important',
      width: 50,
      marginRight: '8px !important',
      justifyContent: 'center',
      textAlign: 'center',
      border: 'none !important',
      borderRadius: '10px !important',
    },

    '& .ant-tabs-tab-active': {
      backgroundColor: `${AppColors.backgroundDarkGrey} !important`,
      '& .ant-tabs-tab-btn': {
        color: 'white !important',
      },
    },

    '& .ant-tabs-nav::before': {
      border: 'none',
    },
    '& .ant-tabs-content-holder': {
      backgroundColor: 'white',
      borderRadius: 10,
      padding: '0px 16px',
    },
  },
  dayTabHeader: {
    margin: '2px 0px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  dayLabel: {
    color: AppColors.text.veryLightGray,
  },
  longText: {
    whiteSpace: 'pre-line',
  },
});

const CalendarTabs: React.FC<CalendarTabsProps> = ({ dailyStatuses }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const tabItems = (dailyStatuses || [])
    .filter(status => status.comment || status.extra_symptoms)
    .map(status => {
      const statusDate = new Date(status.date as string);
      return {
        key: status.date as string,
        label: (
          <p className={styles.dayTabHeader}>
            <span className={styles.dayLabel}>
              {statusDate.toLocaleDateString(locale, { weekday: 'long' }).slice(0, 2)}
            </span>
            <br />
            {(status.date as string).slice(8, 10)}
          </p>
        ),
        children: (
          <>
            {status.comment && (
              <>
                <h3>{t('symptomTracking.graphs.notes')}</h3>
                <p className={styles.longText}>{status.comment}</p>
              </>
            )}
            {status.extra_symptoms && (
              <>
                <h3>{t('symptomTracking.graphs.otherSymptoms')}</h3>
                <p className={styles.longText}>{status.extra_symptoms}</p>
              </>
            )}
          </>
        ),
      };
    });
  return <Tabs type="card" className={styles.dailyCommentTabs} defaultActiveKey="1" items={tabItems} />;
};
export default CalendarTabs;
