import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/users/selectors';

export default function useCheckNotificationsPermissions() {
  const [appStateResumed, setAppStateResumed] = useState(true);
  const currentUser = useSelector(selectCurrentUser);
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  App.addListener('appStateChange', ({ isActive }) => {
    setAppStateResumed(isActive);
  });
  const { run: checkPermissions, data: pushNotificationsPermissions } = useRequest(() => {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
      return PushNotifications.checkPermissions();
    }
    return Promise.resolve({ receive: 'denied' });
  });
  const doesUserHaveNotifications =
    pushNotificationsPermissions?.receive !== 'denied' || currentUser?.consents_to_email_notifications;

  useEffect(() => {
    checkPermissions();
  }, [checkPermissions, appStateResumed]);

  return { doesUserHaveNotifications };
}
