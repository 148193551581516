const enum Keys {
  INFO_SHOULD_DISPLAY_HELPER = 'info.shouldDisplayHelper',
  CAREGIVERS_DIRECTORY_SHOULD_DISPLAY_HELPER = 'caregiversDirectory.shouldDisplayHelper',
  TRACKING_SURVEY_SHOULD_ASK_FOR_REMINDER = 'tracking.survey.shouldAskForReminder',
  TRACKING_SURVEY_SHOULD_ASK_FOR_PERIOD = 'tracking.survey.shouldAskForPeriod',
  TRACKING_SHOULD_DISPLAY_HELPER = 'tracking.shouldDisplayHelper',
  TRACKING_REMINDER_TIME = 'tracking.reminder.time',
}

export default Keys;
