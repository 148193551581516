import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useState } from 'react';

export default function useIsKeyboardVisible() {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  if (Capacitor.isPluginAvailable('Keyboard')) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Keyboard.addListener('keyboardDidShow', () => {
      setIsKeyboardVisible(true);
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Keyboard.addListener('keyboardDidHide', () => {
      setIsKeyboardVisible(false);
    });
  }
  return {
    isKeyboardVisible,
  };
}
