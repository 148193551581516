/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { WebSocketDataChatMessage } from 'services/WebSocketService';
import { ConversationDTO, ConversationWithMessagesDTO } from 'services/api/models/ConversationDTO';
import { UserDTO } from 'services/api/models/UserDTO';
import resetAll from 'store/shared/resetAll';
import createSlice from 'utils/@reduxjs/toolkit';
import { ConversationState, ConversationType, LoadingStatus } from './types';

export const initialState: ConversationState = {
  loadedConversationsOnce: false,
  conversationLoadingStatus: 'LOADING',
  websocketConnected: false,
  conversationsMetadata: [],
  messagesPerConversation: {},
  summaryPerConversation: {},
  summaryDraftPerConversation: {},
  // Define an empty object to store text input (undefined or string) for each conversation id
  conversationInputTexts: {},
};

const addMessageToConversation = (
  state: ConversationState,
  payload: { messageText: string; sender: UserDTO; conversationId: string; incrementUnreadMessages?: boolean },
) => {
  const { messageText, sender, conversationId, incrementUnreadMessages } = payload;

  // updating metadata
  const updatedConversations = state.conversationsMetadata.map(conversation => {
    if (conversation.conversation.id === conversationId) {
      return {
        ...conversation,
        conversation: {
          ...conversation.conversation,
          last_message: {
            message_text: messageText,
            sender,
            sent_at: new Date().toISOString(),
          },
          ...(incrementUnreadMessages && {
            number_of_unread_messages: (conversation.conversation.number_of_unread_messages ?? 0) + 1,
          }),
        },
      };
    }

    return conversation;
  });

  // updating messages
  const { [conversationId]: messages, ...messagesFromOtherConversations } = state.messagesPerConversation;
  const updatedMessages = {
    ...messagesFromOtherConversations,
    [conversationId]: (messages || []).concat([
      { message_text: messageText, sender, sent_at: new Date().toISOString() },
    ]),
  };

  return { newMetadata: updatedConversations, newMessages: updatedMessages };
};

const updateConversationReadStatus = (
  state: ConversationState,
  payload: { conversationId: string; reader: string; lastReadDatetime: string },
) => {
  const { conversationId, reader, lastReadDatetime } = payload;

  const updatedConversations = state.conversationsMetadata.map(conversation => {
    if (conversation.conversation.id === conversationId) {
      const updatedStatuses = (conversation.conversation.read_statuses || [])
        .filter(status => status.reader !== reader)
        .concat([
          {
            reader,
            last_read_datetime: lastReadDatetime,
          },
        ]);

      return {
        ...conversation,
        conversation: {
          ...conversation.conversation,
          read_statuses: updatedStatuses,
        },
      };
    }

    return conversation;
  });

  return updatedConversations;
};

const appendConversations = (
  state: ConversationState,
  payload: { conversations: ConversationDTO[]; type: ConversationType },
) => {
  return state.conversationsMetadata.concat(
    payload.conversations.map(conversation => ({
      conversation,
      type: payload.type,
    })),
  );
};

const insertConversations = (
  state: ConversationState,
  payload: { conversations: ConversationDTO[]; type: ConversationType },
) => {
  return payload.conversations
    .map(conversation => ({
      conversation,
      type: payload.type,
    }))
    .concat(state.conversationsMetadata);
};

const getConversationType = (conversation: ConversationDTO, currentUserId: string): ConversationType => {
  const { status, current_owner: owner } = conversation;
  // for patients; status is undefined for now
  if (!status) return 'assigned';

  // for caregivers, it's slightly more complex:
  if (status.value === 'ASSIGNED_TO_CAREGIVER') return 'assigned';
  if (status.value === 'WAITING_FOR_FIRST_RESPONSE') return 'unassigned';
  if (status.value === 'ASSIGNMENT_TO_GROUP_ASKED') {
    if (owner?.id === currentUserId) return 'waitingForReassignment';
    return 'unassigned';
  }
  if (status.value === 'ASSIGNMENT_TO_CAREGIVER_ASKED') {
    if (owner?.id === currentUserId) return 'waitingForReassignment';
    return 'myRequest';
  }

  if (status.value === 'CLOSED') return 'closed';
  if (status.value === 'ON_HOLD') return 'onHold';

  // TODO this ain't great; raise instead?
  return 'assigned';
};

const slice = createSlice({
  name: 'conversations',
  initialState,
  extraReducers: (builder: any) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setConversationLoadingStatus: (state, action: PayloadAction<LoadingStatus>) => {
      state.conversationLoadingStatus = action.payload;
      if (action.payload === 'SUCCESS') {
        state.loadedConversationsOnce = true;
      }
    },
    setWebsocketConnectionStatus: (state, action: PayloadAction<{ connected: boolean }>) => {
      state.websocketConnected = action.payload.connected;
    },
    removeConversation: (state, action: PayloadAction<string>) => {
      state.conversationsMetadata = state.conversationsMetadata.filter(
        conversation => conversation.conversation.id !== action.payload,
      );
    },
    setConversations: (state, action: PayloadAction<{ conversations: ConversationDTO[]; currentUserId: string }>) => {
      state.conversationsMetadata = action.payload.conversations.map(conversation => ({
        conversation,
        type: getConversationType(conversation, action.payload.currentUserId),
      }));
    },
    setConversationStatusValue: (
      state,
      action: PayloadAction<{ conversationId: string; statusValue: string; currentUserId: string }>,
    ) => {
      const toUpdate = state.conversationsMetadata.find(
        conversation => conversation.conversation.id === action.payload.conversationId,
      );
      if (toUpdate) {
        toUpdate.conversation.status.value = action.payload.statusValue;
        toUpdate.type = getConversationType(toUpdate.conversation, action.payload.currentUserId);
        state.conversationsMetadata = state.conversationsMetadata.map(conversation =>
          conversation.conversation.id === action.payload.conversationId ? toUpdate : conversation,
        );
      }
    },
    setConversationStatus: (state, action: PayloadAction<{ conversationId: string; newStatus: ConversationType }>) => {
      state.conversationsMetadata = state.conversationsMetadata.map(conversation =>
        conversation.conversation.id !== action.payload.conversationId
          ? conversation
          : { ...conversation, type: action.payload.newStatus },
      );
    },
    appendConversations: (
      state,
      action: PayloadAction<{ conversations: ConversationDTO[]; type: ConversationType }>,
    ) => {
      state.conversationsMetadata = appendConversations(state, action.payload);
    },
    setConversationMessages: (state, action: PayloadAction<ConversationWithMessagesDTO>) => {
      const conversationWithMessages = action.payload;
      state.messagesPerConversation[conversationWithMessages.id] = conversationWithMessages.messages || [];
    },
    setConversationSummary: (state, action: PayloadAction<{ conversationId: string; summary: string }>) => {
      state.summaryPerConversation[action.payload.conversationId] = action.payload.summary;
    },
    setConversationSummaryDraft: (state, action: PayloadAction<{ conversationId: string; summary: string }>) => {
      state.summaryDraftPerConversation[action.payload.conversationId] = action.payload.summary;
    },
    // Mark the current user as the owner of a conversation
    setOwnerForConversation: (state, action: PayloadAction<{ conversationId: string; currentOwner: UserDTO }>) => {
      const { conversationId, currentOwner } = action.payload;

      const conversationToUpdate = state.conversationsMetadata.find(
        conversation => conversation.conversation.id === conversationId,
      );

      const otherConversations = state.conversationsMetadata.filter(
        conversation => conversation.conversation.id !== conversationId,
      );

      const conversationToUpdateAsArray = conversationToUpdate
        ? [
            {
              conversation: {
                ...conversationToUpdate.conversation,
                current_owner: currentOwner,
              },
              type: 'assigned' as ConversationType,
            },
          ]
        : [];

      const updateConversations = conversationToUpdateAsArray.concat(Array.from(otherConversations) as any);

      state.conversationsMetadata = updateConversations;
    },
    addMessageToConversation: (
      state,
      action: PayloadAction<{
        messageText: string;
        sender: UserDTO;
        conversationId: string;
        incrementUnreadMessages?: boolean;
      }>,
    ) => {
      const { newMetadata, newMessages } = addMessageToConversation(state, action.payload);
      state.conversationsMetadata = newMetadata;
      state.messagesPerConversation = newMessages;
    },
    updateConversationReadStatus: (
      state,
      action: PayloadAction<{
        conversationId: string;
        reader: string;
        lastReadDatetime: string;
      }>,
    ) => {
      state.conversationsMetadata = updateConversationReadStatus(state, action.payload);
    },
    // Save a text input for a conversation id
    updateConversationInputText: (
      state,
      action: PayloadAction<{
        conversationId: string;
        inputText?: string;
      }>,
    ) => {
      const { inputText } = action.payload;

      state.conversationInputTexts = {
        ...state.conversationInputTexts,
        [action.payload.conversationId]: inputText,
      };
    },
    insertConversationOrAddMessage: (
      state,
      action: PayloadAction<{ webSocketPayload: ConversationDTO; conversationType: ConversationType }>,
    ) => {
      const { webSocketPayload: data, conversationType } = action.payload;

      const existingConversation = (state?.conversationsMetadata || initialState.conversationsMetadata).find(
        conversation => conversation.conversation.id === data.id,
      );

      if (!existingConversation) {
        // Add a new conversation to the list
        state.conversationsMetadata = insertConversations(state, {
          type: conversationType,
          conversations: [data],
        });
      }
    },
    handleNewMessageFromUnassignedConversation: (state, action: PayloadAction<WebSocketDataChatMessage>) => {
      const data = action.payload;

      const existingConversation = (state?.conversationsMetadata || initialState.conversationsMetadata).find(
        conversation => conversation.conversation.id === data.conversation,
      );

      // Add a new conversation to the list of unassigned conversations if it's not there yet
      if (!existingConversation) {
        state.conversationsMetadata = insertConversations(state, {
          type: 'unassigned',
          conversations: [
            {
              id: data.conversation,
              last_message: {
                message_text: data.message_text,
                sender: data.sender,
                sent_at: new Date().toISOString(),
              },
              patient: data.sender,
              number_of_unread_messages: 0,
              status: { value: 'WAITING_FOR_FIRST_RESPONSE' },
            },
          ],
        });
      }

      // Record message
      const { newMetadata, newMessages } = addMessageToConversation(state, {
        conversationId: data.conversation,
        sender: data.sender,
        messageText: data.message_text,
      });
      state.conversationsMetadata = newMetadata;
      state.messagesPerConversation = newMessages;
    },
    resetUnreadMessages: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload;

      state.conversationsMetadata = state.conversationsMetadata.map(conversationData => {
        if (conversationData.conversation.id === conversationId) {
          return {
            ...conversationData,
            conversation: {
              ...conversationData.conversation,
              number_of_unread_messages: 0,
            },
          };
        }

        return conversationData;
      });
    },
  },
});

/**
 * `actions` will be used to trigger change in the state from where ever you want
 */
export const { actions: conversationActions, reducer: conversationReducer } = slice;
