import CaregiverProfileModal from 'components/modals/CaregiverProfileModal';
import AppColors from 'config/AppColors';
import useIsKeyboardVisible from 'hooks/useIsKeyboardVisible';
import useSelectConversations from 'hooks/useSelectConversations';
import { useEffect, useRef, useState } from 'react';
import { MessageList, MessageType } from 'react-chat-elements';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { ConversationMessage } from 'services/api/models/ConversationDTO';
import { UserDTO } from 'services/api/models/UserDTO';
import { ConversationMetadata } from 'store/conversations/types';
import { selectCurrentUser } from 'store/users/selectors';
import MessageUtil, { ChatMessage } from '../../models/Message';
import ExampleQuestions from './ExampleQuestions';
import './MessagesList.css';

export type MessagesListProps = {
  messages: ConversationMessage[];
  showExampleQuestions?: boolean;
};

const useStyles = createUseStyles({
  container: {
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: AppColors.backgroundGrey,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  messagesList: {
    paddingTop: 20,
    paddingBottom: 20,

    '& .rce-mbox-right': {
      backgroundColor: 'rgb(217,246,218)',
      background: 'linear-gradient(130deg, rgba(219, 255, 239,1) 0%, rgba(191,233,216,1) 100%)',
    },

    '& .rce-mbox': {
      maxWidth: '80%',
      whiteSpace: 'pre-line',
      borderRadius: '13px !important',
      boxShadow: '2px 2px 4px 1px #ccc3',
      padding: '10px 12px 8px',
    },

    '& .rce-mbox-text': {
      fontSize: '18px !important',
      '-webkit-user-select': 'text',
      '-moz-user-select': 'text',
      '-ms-user-select': 'text',
      'user-select': 'text !important',
    },
  },
});

export default function MessagesList(props: MessagesListProps) {
  const { messages, showExampleQuestions } = props;

  // Hooks
  const ref = useRef();
  const styles = useStyles();
  const { isKeyboardVisible } = useIsKeyboardVisible();

  // Selectors
  const currentUser = useSelector(selectCurrentUser);
  const { conversationMetadata } = useSelectConversations();

  // State
  const [caregiverIdDisplayedInModal, setCaregiverIdDisplayedInModal] = useState(undefined as string | undefined);

  useEffect(() => {
    // Get all message boxes
    const messageBoxHtmlElements = document.getElementsByClassName('rce-container-mbox');
    if (messageBoxHtmlElements) {
      const nbOfMessageBoxHtmlElements = messageBoxHtmlElements.length;
      if (nbOfMessageBoxHtmlElements > 0) {
        // Scroll to last item
        messageBoxHtmlElements[nbOfMessageBoxHtmlElements - 1].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [messages, isKeyboardVisible]);

  function closeCaregiverProfileModal() {
    setCaregiverIdDisplayedInModal(undefined);
  }

  return (
    <div className={styles.container}>
      {caregiverIdDisplayedInModal && (
        <CaregiverProfileModal
          caregiverId={caregiverIdDisplayedInModal}
          open
          onClose={() => closeCaregiverProfileModal()}
        />
      )}
      <MessageList
        referance={ref}
        className={styles.messagesList}
        // Cast to MessageType[] because we override the default data type
        dataSource={
          MessageUtil.convertMessagesToComponentDataType(
            messages,
            currentUser as UserDTO,
            conversationMetadata as ConversationMetadata,
          ) as MessageType[]
        }
        lockable={false}
        toBottomHeight="100%"
        messageBoxStyles={{ color: 'rgba(46, 46, 46, 0.88)', lineHeight: '22px' }}
        onTitleClick={messageDetails => {
          // Only show the caregiver profile when current user is a patient and when the message has been sent by a caregiver
          if (
            (messageDetails as ChatMessage).sender?.is_caregiver &&
            (messageDetails as ChatMessage).sender?.id &&
            !currentUser?.is_caregiver
          ) {
            setCaregiverIdDisplayedInModal((messageDetails as ChatMessage).sender?.id);
          }
        }}
      />
      {showExampleQuestions && <ExampleQuestions />}
    </div>
  );
}
