import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import posthog from 'posthog-js';
import { useDispatch } from 'react-redux';
import WebSocketService from 'services/WebSocketService';
import { ApiManager } from 'services/api/ApiManager';
import resetAll from 'store/shared/resetAll';
import useSecretStorage from './useSecretStorage';

export default function useLogout() {
  // Hooks
  const dispatch = useDispatch();
  const { set: setLocalStorageTokens } = useSecretStorage('gynger-access-tokens');

  const logout = () => {
    // Clear the access token from memory
    ApiManager.setTokens({ accessToken: undefined, refreshToken: undefined });

    // Clear the access token from local storage
    setLocalStorageTokens({ access: undefined, refresh: undefined });

    posthog.reset();

    // Reset all stores to initial states
    dispatch(resetAll());

    if (Capacitor.isPluginAvailable('PushNotifications')) {
      // eslint-disable-next-line no-void
      void PushNotifications.unregister();
    }

    // Clear Web Socket connection
    WebSocketService.close();
  };

  return {
    logout,
  };
}
