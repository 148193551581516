import { Card, Carousel } from 'antd';
import AppColors from 'config/AppColors';
import { useTranslation } from 'react-i18next';
import { FaQuoteLeft } from 'react-icons/fa6';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  carouselContainer: {
    width: '100%',
    padding: 32,
    marginTop: 8,
  },
  exampleMessageIcon: {
    marginRight: 10,
    color: AppColors.main,
  },
  exampleMessage: {
    backgroundColor: 'white',
    marginBottom: 5,
    color: AppColors.text.discrete,
    boxShadow: 'none !important',
    fontWeight: 'bold',
  },
});

export default function ExampleQuestions() {
  // Hooks
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div className={styles.carouselContainer}>
      <Carousel autoplay>
        {t<string, { returnObjects: true }, string[]>('chats.new.exampleMessages', { returnObjects: true }).map(
          (message, index) => (
            <Card key={`card-${index}`} size="small" bordered={false} className={styles.exampleMessage}>
              <FaQuoteLeft className={styles.exampleMessageIcon} />
              {message}
            </Card>
          ),
        )}
      </Carousel>
    </div>
  );
}
