/**
 * Create the store with dynamic reducers
 */
import { configureStore } from '@reduxjs/toolkit';
import { contentReducer } from './content';
import { conversationReducer } from './conversations';
import { userReducer } from './users';

export const store = configureStore({
  reducer: {
    conversations: conversationReducer,
    users: userReducer,
    content: contentReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
