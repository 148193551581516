/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-danger */
import { EnvironmentOutlined, RightOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import ArticlesList from 'components/ArticlesList';
import GGButton from 'components/buttons/GGButton';
import PageTopBar from 'components/navigation/PageTopBar';
import AppColors from 'config/AppColors';
import useScrollRestoration from 'hooks/useScrollRestoration';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: AppColors.backgroundGrey,
    alignItems: 'center',
  },
  container: {
    padding: '8px 8px 0px',
    height: '100%',
    overflowY: 'scroll',
    maxWidth: 600,
    width: '100%',
  },
  caregiversButtonContainer: {
    marginBottom: 24,
    width: '100%',
    '& .ant-btn': {
      height: 64,
      justifyContent: 'space-between',
    },
  },
  caregiversButtonText: {
    display: 'inline-flex !important',
    gap: 8,
    '& .anticon': {
      color: AppColors.main,
      fontSize: 24,
    },
  },
});

export default function InfoPage() {
  // Hooks
  const { t } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();
  const scrollableRef = useRef<HTMLDivElement>(null);
  useScrollRestoration(scrollableRef);

  return (
    <div className={styles.pageContainer}>
      <PageTopBar title={t('menu.info')} />
      <div className={styles.container} ref={scrollableRef}>
        <Row>
          <div className={styles.caregiversButtonContainer}>
            <GGButton block size="large" onClick={() => navigate(routes.caregiversDirectory.route)}>
              <span className={styles.caregiversButtonText}>
                <EnvironmentOutlined />
                {t('infoPage.goToCaregivers')}
              </span>
              <RightOutlined />
            </GGButton>
          </div>
        </Row>
        <ArticlesList allowGridView />
      </div>
    </div>
  );
}
