import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import fr from './fr/translation';

export const DEFAULT_LANGUAGE_CODE = import.meta.env.VITE_DEFAULT_LANGUAGE_CODE || 'fr';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    resources: {
      // WARNING: before you add new languages here, make sure to add the corresponding
      // locale in the index.tsx file for the ant design ConfigProvider
      // en: { translation: en },
      fr: { translation: fr },
    },
    lng: window.navigator.language,
    // supportedLngs: ['fr', 'en'],
    supportedLngs: ['fr'],
    fallbackLng: DEFAULT_LANGUAGE_CODE,
    interpolation: {
      escapeValue: false,
    },
  });

export const { t, resolvedLanguage: locale } = i18n;

export default i18n;
