const AppColors = {
  main: '#7FBAA9',
  secondary: '#ff9958',
  status: {
    error: '#ff794e',
  },
  borders: {
    green: '#929497',
  },
  text: {
    header: '#53A78D',
    navigation: '#53A78D',
    navigationActive: '#5a898d',
    discrete: '#777',
    lightGray: '#888',
    veryLightGray: '#BBB',
  },
  offWhite: '#FDFDFD',
  card: {
    background: '#F7F7F4',
  },
  colorTextBase: '#2e2e2e',
  colorTextDisabled: '#2e2e2e',
  headerTable: '#E0E9FF',
  pageHeader: '#F4F7FF',

  messages: {
    recipient: {
      title: '#A1A1A1',
      background: '#f0f0f0',
    },
    me: {
      title: '#0A7CFF',
      background: '#A6E4BA',
    },
  },

  borderColor: '#e9ecef',
  lightDashedBorders: '#e4e7eb',
  background: 'white',
  backgroundLightGrey: '#F3F3F3',
  backgroundGrey: '#F7F7F4',
  backgroundDarkGrey: '#555555',
  backgroundLightGreen: '#e6f2ee',
  backgroundMidGreen: '#D3FFF2',
  backgroundDarkGreen: '#539B87',

  notification: {
    info: '#4B4BF9',
    success: '#D9F3E2',
    error: '#FFC9B857',
  },

  colorMap: {
    veryGood: '#7FBAA9',
    good: '#CBD993',
    average: '#EDC27E',
    bad: '#EDA67E',
    veryBad: '#B44359',
  },
};
export default AppColors;
