/* eslint-disable global-require */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { useRequest } from 'ahooks';
import { Form, Input, Spin, Switch, Typography } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import { AxiosError } from 'axios';
import GGButton from 'components/buttons/GGButton';
import PageTopBar from 'components/navigation/PageTopBar';
import AppColors from 'config/AppColors';
import Keys from 'preferences/preferences';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import ApiUsersManager from 'services/api/ApiUsersManager';
import { userActions } from 'store/users';
import { selectCurrentUser } from 'store/users/selectors';

const { Title } = Typography;

const useStyles = createUseStyles({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: AppColors.backgroundLightGrey,
    flex: 1,
    height: '100%',
    alignItems: 'center',
  },
  container: {
    padding: 8,
    overflowY: 'scroll',
    flexGrow: 1,
    maxWidth: 800,
  },
  title: {
    color: `${AppColors.main} !important`,
  },
  section: {
    backgroundColor: 'white',
    padding: '0px 8px',
    borderRadius: 8,
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 8px',
    gap: 8,
  },
  lineDescription: {
    fontSize: '0.7em',
    color: AppColors.text.discrete,
    display: 'block',
    marginTop: 8,
    lineHeight: '1.5em',

    '& .anticon': {
      marginRight: 4,
      color: AppColors.main,
    },
  },
  licenseCode: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  newLicenseForm: {
    width: '100%',
    padding: '8px 8px 16px',
    '& .ant-form-item-label': {
      padding: 0,
      '& label': {
        color: `${AppColors.text.discrete} !important`,
      },
    },
  },
  newLicenseFormButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
  },
});

export default function SettingsPage() {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [api, context] = useNotification();
  const currentUser = useSelector(selectCurrentUser);
  const [isChangingLicense, setIsChangingLicense] = useState(false);

  const {
    run: getShouldAskForPeriod,
    data: shouldAskForPeriodPreference,
    loading: loadingSurveyPreferences,
  } = useRequest(() => Preferences.get({ key: Keys.TRACKING_SURVEY_SHOULD_ASK_FOR_PERIOD }));
  const { run: storeShouldAskForPeriod } = useRequest(
    shouldAskForPeriod =>
      Preferences.set({
        key: Keys.TRACKING_SURVEY_SHOULD_ASK_FOR_PERIOD,
        value: shouldAskForPeriod ? 'true' : 'false',
      }),
    {
      manual: true,
    },
  );

  const { run: storeConsentsToEmailNotifications } = useRequest(
    consentsToEmailNotifications => ApiUsersManager.updateConsentsToEmailNotifications(consentsToEmailNotifications),
    {
      manual: true,
      onSuccess: data => {
        dispatch(userActions.setEmailConsents(data.consents_to_email_notifications));
      },
    },
  );

  const { run: updateLicenseCode } = useRequest(
    formValues => ApiUsersManager.updateLicenseCode(formValues.licenseCode),
    {
      manual: true,
      onSuccess: data => {
        dispatch(userActions.setLicenseCode(data.license_code));
        setIsChangingLicense(false);
        api.success({
          message: t('settings.license.codeChanged'),
        });
      },
      onError: error => {
        if (error instanceof AxiosError) {
          if (error.response?.status === 403) {
            switch (error.response?.data?.code) {
              case 'invalid_code':
                api.error({
                  message: t('settings.license.errors.invalidLicenseCode'),
                });
                return;
              case 'contract_full':
                api.error({
                  message: t('settings.license.errors.contractFull'),
                });
                return;
              default:
            }
          }
        }
        api.error({
          message: t('settings.license.errors.default'),
        });
      },
    },
  );

  const shouldAskForPeriod = shouldAskForPeriodPreference?.value !== 'false';

  const togglePeriodAsk = (toggleValue: boolean) => {
    storeShouldAskForPeriod(toggleValue);
    getShouldAskForPeriod();
  };

  return (
    <div className={styles.pageContainer}>
      <PageTopBar title={t('menu.settings')} />
      {context}
      <div className={styles.container}>
        <Title level={4} className={styles.title}>
          {t('settings.chatsSection')}
        </Title>
        <div className={styles.section}>
          <div className={styles.line} data-testid="settings.email">
            <p>
              {t('settings.chats.emailNotifications')}
              {Capacitor.getPlatform() === 'web' && (
                <>
                  <br />
                  <span className={styles.lineDescription}>
                    <InfoCircleOutlined />
                    {t('settings.chats.emailNotificationsDescription')}
                  </span>
                </>
              )}
            </p>
            {loadingSurveyPreferences ? (
              <Spin />
            ) : (
              <Switch
                checked={currentUser?.consents_to_email_notifications}
                loading={loadingSurveyPreferences}
                onChange={storeConsentsToEmailNotifications}
              />
            )}
          </div>
        </div>
        <Title level={4} className={styles.title}>
          {t('menu.tracking')}
        </Title>
        <div className={styles.section}>
          <div className={styles.line} data-testid="settings.period">
            <p>{t('settings.periodQuestions')}</p>
            {loadingSurveyPreferences ? (
              <Spin />
            ) : (
              <Switch
                defaultChecked={shouldAskForPeriod}
                loading={loadingSurveyPreferences}
                onChange={togglePeriodAsk}
              />
            )}
          </div>
        </div>
        <Title level={4} className={styles.title}>
          {t('settings.accountSection')}
        </Title>
        <div className={styles.section}>
          {!isChangingLicense ? (
            <div className={styles.line}>
              <p>
                {t('settings.license.currentLicense')}:{' '}
                <span className={styles.licenseCode}>{currentUser?.license_code}</span>
              </p>
              <GGButton type="link" style={{ paddingRight: 0 }} onClick={() => setIsChangingLicense(true)}>
                {t('settings.license.changeLicense')}
              </GGButton>
            </div>
          ) : (
            <div>
              <Form
                onFinish={updateLicenseCode}
                className={styles.newLicenseForm}
                initialValues={{ licenseCode: currentUser?.license_code.toUpperCase() }}
              >
                <Form.Item name="licenseCode" label={`${t('settings.license.newLicense')} :`}>
                  <Input type="text" placeholder={t('settings.license.placeholder')} />
                </Form.Item>
                <div className={styles.newLicenseFormButtons}>
                  <GGButton color="secondary" type="default" onClick={() => setIsChangingLicense(false)}>
                    {t('settings.cancel')}
                  </GGButton>
                  <GGButton type="primary" htmlType="submit">
                    {t('settings.license.saveLicense')}
                  </GGButton>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
