import { RootState } from 'types';
import { initialState } from '.';

export const selectSuggestedPosts = (state: RootState) => {
  return state?.content?.suggestedPosts || initialState.suggestedPosts;
};

export default {
  selectSuggestedPosts,
};
