import Empty from 'antd/lib/empty';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { PostMetadataDTO } from 'services/api/models/PostDTO';
import { routes } from 'services/RouteService';
import onArticleSelected, { PosthogOnArticleOpenExtras } from 'utils/ContentUtils';
import GGButton from './buttons/GGButton';

const useStyles = createUseStyles({
  postsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    gap: 12,
  },
  post: {
    width: '100%',
    height: 64,
    backgroundColor: 'white',
    borderRadius: 8,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    paddingRight: 16,
    boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.05)',
  },
  coverImageContainer: {
    width: 64,
    height: 64,
    flexShrink: 0,
    overflow: 'hidden',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.7,
  },
  postTitle: {
    margin: 0,
    flex: 1,
    fontWeight: 'normal',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default function PostsList({
  posts,
  limitPosts,
  posthogOnArticleOpenExtras,
}: {
  posts: PostMetadataDTO[];
  limitPosts?: number;
  posthogOnArticleOpenExtras?: PosthogOnArticleOpenExtras;
}) {
  const styles = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const displayedPosts = limitPosts ? posts.slice(0, limitPosts) : posts;

  const displayShowMore = limitPosts && posts.length > limitPosts;

  return (
    <div className={styles.postsContainer}>
      {posts.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('infoPage.noPosts')} />
      ) : (
        displayedPosts?.map((post, index) => (
          <div
            className={styles.post}
            key={post.id}
            data-testid={`postinlist-${index}`}
            onClick={() => onArticleSelected(post, index, navigate, posthogOnArticleOpenExtras)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onArticleSelected(post, index, navigate, posthogOnArticleOpenExtras);
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className={styles.coverImageContainer}>
              {post.cover_image_url ? (
                <img src={post.cover_image_url} alt={post.title} className={styles.coverImage} />
              ) : (
                <img src={require('assets/logo-square-512.png')} alt={post.title} className={styles.coverImage} />
              )}
            </div>
            <h4 className={styles.postTitle}>{post.title}</h4>
          </div>
        ))
      )}
      {displayShowMore && (
        <GGButton block type="primary" size="large" onClick={() => navigate(routes.info.route)}>
          {t('home.info.showMore')}
        </GGButton>
      )}
    </div>
  );
}
