/* eslint-disable no-param-reassign */
import * as amplitude from '@amplitude/analytics-browser';
import { Capacitor } from '@capacitor/core';
import { PayloadAction } from '@reduxjs/toolkit';
import posthog from 'posthog-js';
import { CurrentUserDTO } from 'services/api/models/UserDTO';
import resetAll from 'store/shared/resetAll';
import createSlice from 'utils/@reduxjs/toolkit';
import { UserState } from './types';

export const initialState: UserState = {
  currentUser: undefined,
};

const slice = createSlice({
  name: 'users',
  initialState,
  extraReducers: (builder: any) => builder.addCase(resetAll, () => initialState),
  reducers: {
    setCurrentUser: (state, action: PayloadAction<CurrentUserDTO>) => {
      state.currentUser = action.payload;
      const identifyEvent = new amplitude.Identify();
      identifyEvent.setOnce('gynger_is_caregiver', action.payload.is_caregiver);
      if (action.payload.license_code) {
        identifyEvent.setOnce('gynger_license_code', action.payload.license_code);
      }
      identifyEvent.setOnce('gynger_platform', Capacitor.getPlatform());
      amplitude.identify(identifyEvent);
      amplitude.setUserId(action.payload.id);

      const userProperties: Record<string, any> = {
        gynger_is_caregiver: action.payload.is_caregiver,
      };
      if (action.payload.license_code) {
        userProperties.gynger_license_code = action.payload.license_code;
        posthog.group('license_code', action.payload.license_code);
      }

      posthog.identify(action.payload.id, userProperties);
    },
    setEmailConsents: (state, action: PayloadAction<boolean>) => {
      if (state.currentUser)
        state.currentUser = { ...state.currentUser, consents_to_email_notifications: action.payload };
    },
    setLicenseCode: (state, action: PayloadAction<string>) => {
      if (state.currentUser) {
        state.currentUser = { ...state.currentUser, license_code: action.payload };
        posthog.identify(state.currentUser.id, {
          gynger_license_code: action.payload,
        });
        posthog.group('license_code', action.payload);
      }
    },
    disableNPSSurvey: state => {
      if (state.currentUser) state.currentUser = { ...state.currentUser, should_ask_for_nps: false };
    },
  },
});

/**
 * `actions` will be used to trigger change in the state from where ever you want
 */
export const { actions: userActions, reducer: userReducer } = slice;
