import { useRequest } from 'ahooks';
import { List, Skeleton } from 'antd';
import CaregiverAvatar from 'avatar/CaregiverAvatar';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ApiRegisteredCaregiversManager from 'services/api/ApiRegisteredCaregiversManager';

export type CaregiverAvatarListProps = {
  direction?: 'horizontal' | 'vertical';
};

const useStyles = createUseStyles({
  listContainer: (props: CaregiverAvatarListProps) => ({
    scrollbarWidth: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    overflowX: 'auto',
    ...(props.direction === 'horizontal' ? { height: 83, overflowY: 'hidden' } : {}),
  }),
  horizontalListItem: {
    width: 80,
    flex: '0 0 auto',
  },
  skeletonContainer: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    width: '100%',
  },
});

const CaregiverAvatarList: React.FC<CaregiverAvatarListProps> = ({ direction }) => {
  // Hooks
  const { t } = useTranslation();
  const styles = useStyles({ direction });

  const { loading, data } = useRequest(() => ApiRegisteredCaregiversManager.getRegisteredCaregiversList(), {
    cacheKey: 'registered-caregivers.list',
  });

  const initialLoading = data === undefined && loading;

  return direction === 'horizontal' ? (
    <div className={styles.listContainer}>
      {initialLoading && !data && (
        <div className={styles.skeletonContainer}>
          <Skeleton.Avatar active size={48} shape="circle" />
          <Skeleton.Avatar active size={48} shape="circle" />
          <Skeleton.Avatar active size={48} shape="circle" />
          <Skeleton.Avatar active size={48} shape="circle" />
        </div>
      )}
      {(data || []).map((item, index) => {
        const firstGroupName =
          item.caregiver.caregiver_groups.length > 0
            ? t(`caregiverGroups.${item.caregiver.caregiver_groups[0].name}`)
            : '';

        return (
          <div key={`avatar-${index}`} className={styles.horizontalListItem}>
            <CaregiverAvatar
              key={`avatar-${index}`}
              avatar={item.user.photo_url}
              name={item.caregiver.first_name}
              caption={firstGroupName}
            />
          </div>
        );
      })}
    </div>
  ) : (
    <div className={styles.listContainer}>
      <List
        itemLayout="horizontal"
        grid={{ gutter: 4, column: 3 }}
        dataSource={data}
        loading={initialLoading}
        renderItem={(item, index) => {
          const firstGroupName =
            item.caregiver.caregiver_groups.length > 0
              ? t(`caregiverGroups.${item.caregiver.caregiver_groups[0].name}`)
              : '';

          return (
            <List.Item>
              <CaregiverAvatar
                key={`avatar-${index}`}
                avatar={item.user.photo_url}
                name={item.caregiver.first_name}
                caption={firstGroupName}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};
export default CaregiverAvatarList;
