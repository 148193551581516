import { Capacitor } from '@capacitor/core';

export default class Platform {
  static getPlatform() {
    return Capacitor.getPlatform();
  }

  static isNative() {
    return this.getPlatform() !== 'web';
  }
}
