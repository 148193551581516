/* eslint-disable no-nested-ternary */
import { useRequest } from 'ahooks';
import { Col } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import SkeletonMessages from 'components/skeleton/SkeletonMessages';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ApiDoctorConversationsManager from 'services/api/ApiDoctorConversationsManager';
import ApiPatientConversationsManager from 'services/api/ApiPatientConversationsManager';
import { ConversationMessage } from 'services/api/models/ConversationDTO';
import { selectWebsocketConnected } from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';
import ConversationTopBar from '../components/chats/ConversationTopBar';
import MessagesList from '../components/chats/MessagesList';

const useStyles = createUseStyles({
  conversationsContainer: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
  },
});

export default function PatientHistoricConversation() {
  // Hooks
  const styles = useStyles();
  const [api, context] = useNotification();
  const { t } = useTranslation();
  const urlParams = useParams();
  const isWebsocketConnected = useSelector(selectWebsocketConnected);
  const navigate = useNavigate();

  const historicConversationId = urlParams.historicConversation;
  const [messages, setMessages] = useState<ConversationMessage[]>([]);

  // Selectors
  const currentUser = useSelector(selectCurrentUser);

  const { run, loading } = useRequest(
    (conversationId: string) => {
      if (currentUser?.is_caregiver) {
        return ApiDoctorConversationsManager.getDoctorConversationMessageList({ conversationId });
      }
      return ApiPatientConversationsManager.getPatientConversationsMessageList({ conversationId });
    },
    {
      manual: true,
      onSuccess: result => {
        setMessages(result.messages || []);
      },
      onError: () => {
        api.error({
          message: t('chats.list.errors.fetch'),
        });
      },
    },
  );

  useEffect(() => {
    if (historicConversationId) {
      run(historicConversationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historicConversationId, isWebsocketConnected]);

  return (
    <>
      {context}
      <Col className={styles.conversationsContainer} xs={{ span: 24 }} sm={{ span: 16 }} lg={{ span: 18 }}>
        <>
          <ConversationTopBar
            showBackButton
            showSummaryButton
            avatar={null}
            name={t('chats.subtitle.history')}
            caregiverId={undefined}
            onBackButtonClick={() => {
              navigate(-1);
            }}
          />
          {loading ? <SkeletonMessages numberOfTupleMessages={3} /> : <MessagesList messages={messages} />}
        </>
      </Col>
    </>
  );
}
