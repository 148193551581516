import * as amplitude from '@amplitude/analytics-browser';
import posthog from 'posthog-js';
import { NavigateFunction } from 'react-router-dom';
import { PostMetadataDTO } from 'services/api/models/PostDTO';
import { routes } from 'services/RouteService';

export type PosthogOnArticleOpenExtras = {
  opened_on_page: string;
  referring_article_id?: string;
  found_using_tag?: string;
  found_using_search?: string;
};

export default function onArticleSelected(
  post: PostMetadataDTO,
  articleRank: number,
  navigate: NavigateFunction,
  posthogOnArticleOpenExtras?: PosthogOnArticleOpenExtras,
) {
  amplitude.track('info.post_opened');
  posthog.capture('info.post_opened', {
    post_id: post.id,
    post_title: post.title,
    rank_in_list: articleRank,
    ...posthogOnArticleOpenExtras,
  });
  navigate(routes.article.route.replace(':index', post.id.toString()));
}
